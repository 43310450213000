import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import BigNumber from 'bignumber.js';
import { compose } from 'recompose';
import { Progress } from 'antd';
import Button from '@material-ui/core/Button';
import NumberFormat from 'react-number-format';
import { bindActionCreators } from 'redux';
import { connectAccount, accountActionCreators } from 'core';
import { methods } from 'utilities/ContractService';
import commaNumber from 'comma-number';
import { sendSupply } from 'utilities/BnbContract';
import coinImg from 'assets/img/fts.svg';
import arrowRightImg from 'assets/img/arrow-right.png';
import { TabSection, Tabs, TabContent } from 'components/Basic/SupplyModal';
import LoadingSpinner from 'components/Basic/LoadingSpinner';
import { getBigNumber, percentFormatter } from 'utilities/common';
import { useWeb3React } from '@web3-react/core';
import { useFbep, useToken } from '../../../hooks/useContract';
import useWeb3 from '../../../hooks/useWeb3';
import { useMarketsUser } from '../../../hooks/useMarketsUser';
import useCheckAdmin from '../../../hooks/useCheckAdmin';

const format = commaNumber.bindWith(',', '.');
const abortController = new AbortController();

function SupplyTab({ asset, settings, changeTab, onCancel, setSetting }) {
  const [isLoading, setIsLoading] = useState(false);
  const [isEnabled, setIsEnabled] = useState(false);
  const [amount, setAmount] = useState(new BigNumber(0));
  const [borrowLimit, setBorrowLimit] = useState(new BigNumber(0));
  const [borrowPercent, setBorrowPercent] = useState(new BigNumber(0));
  const [newBorrowLimit, setNewBorrowLimit] = useState(new BigNumber(0));
  const [newBorrowPercent, setNewBorrowPercent] = useState(new BigNumber(0));
  const tokenContract = useToken(asset.id);
  const fbepContract = useFbep(asset.id);
  const web3 = useWeb3();
  const { account } = useWeb3React();
  const { userTotalBorrowBalance, userTotalBorrowLimit } = useMarketsUser();
  const isAdmin = useCheckAdmin();

  const updateInfo = useCallback(async () => {
    const underlyingPriceUSD = getBigNumber(asset.underlyingPriceUSD);
    const collateralFactor = getBigNumber(asset.collateralFactor);

    if (underlyingPriceUSD && !amount.isZero() && !amount.isNaN()) {
      const temp = userTotalBorrowLimit.plus(
        amount.times(underlyingPriceUSD).times(collateralFactor)
      );
      setNewBorrowLimit(BigNumber.maximum(temp, 0));
      setNewBorrowPercent(userTotalBorrowBalance.div(temp).times(100));
      if (userTotalBorrowLimit.isZero()) {
        setBorrowLimit(new BigNumber(0));
        setBorrowPercent(new BigNumber(0));
      } else {
        setBorrowLimit(userTotalBorrowLimit);
        setBorrowPercent(
          userTotalBorrowBalance.div(userTotalBorrowLimit).times(100)
        );
      }
    } else if (BigNumber.isBigNumber(userTotalBorrowLimit)) {
      setBorrowLimit(userTotalBorrowLimit);
      setNewBorrowLimit(userTotalBorrowLimit);
      if (userTotalBorrowLimit.isZero()) {
        setBorrowPercent(new BigNumber(0));
        setNewBorrowPercent(new BigNumber(0));
      } else {
        setBorrowPercent(
          userTotalBorrowBalance.div(userTotalBorrowLimit).times(100)
        );
        setNewBorrowPercent(
          userTotalBorrowBalance.div(userTotalBorrowLimit).times(100)
        );
      }
    }
  }, [account, amount, userTotalBorrowBalance, userTotalBorrowLimit]);

  useEffect(() => {
    setIsEnabled(asset.isEnabled);
  }, [asset.isEnabled]);

  /**
   * Get Allowed amount
   */
  useEffect(() => {
    if (asset.ftokenAddress && account) {
      updateInfo();
    }
    return function cleanup() {
      abortController.abort();
    };
  }, [account, updateInfo]);
  /**
   * Approve underlying token
   */
  const onApprove = async () => {
    if (asset.id && account && asset.id !== 'bnb') {
      setIsLoading(true);
      methods
        .send(
          tokenContract.methods.approve,
          [
            asset.ftokenAddress,
            new BigNumber(2)
              .pow(256)
              .minus(1)
              .toString(10)
          ],
          account
        )
        .then(res => {
          setIsEnabled(true);
          setIsLoading(false);
        })
        .catch(err => {
          setIsLoading(false);
        });
    }
  };

  /**
   * Supply
   */
  const handleSupply = () => {
    if (asset.id && account) {
      setIsLoading(true);
      setSetting({
        pendingInfo: {
          type: 'Supply',
          status: true,
          amount: amount.dp(8, 1).toString(10),
          symbol: asset.symbol
        }
      });
      if (asset.id !== 'bnb') {
        methods
          .send(
            fbepContract.methods.mint,
            [amount.times(new BigNumber(10).pow(asset.decimals)).toString(10)],
            account
          )
          .then(res => {
            setAmount(new BigNumber(0));
            setIsLoading(false);
            setSetting({
              pendingInfo: {
                type: '',
                status: false,
                amount: 0,
                symbol: ''
              }
            });
            onCancel();
          })
          .catch(() => {
            setIsLoading(false);
            setSetting({
              pendingInfo: {
                type: '',
                status: false,
                amount: 0,
                symbol: ''
              }
            });
          });
      } else {
        console.log('address', account);
        console.log('amount', amount.toString(10));
        sendSupply(
          web3,
          account,
          amount.times(new BigNumber(10).pow(asset.decimals)).toString(10),
          () => {
            setAmount(new BigNumber(0));
            setIsLoading(false);
            setSetting({
              pendingInfo: {
                type: '',
                status: false,
                amount: 0,
                symbol: ''
              }
            });
            onCancel();
          }
        );
      }
    }
  };
  /**
   * Max amount
   */
  const handleMaxAmount = () => {
    setAmount(asset.walletBalance);
  };

  return (
    <TabSection>
      <Tabs className="flex align-center">
        <div
          className="flex align-center just-center tab-item pointer tab-active"
          onClick={() => {
            changeTab('supply');
          }}
        >
          Supply
        </div>
        <div
          className="flex align-center just-center tab-item pointer"
          onClick={() => {
            changeTab('withdraw');
          }}
        >
          Withdraw
        </div>
      </Tabs>
      <div className="flex flex-column align-center just-center body-content">
        {asset.id === 'bnb' || isEnabled ? (
          <div className="flex align-center input-wrapper">
            <NumberFormat
              autoFocus
              value={amount.isZero() ? '0' : amount.toString(10)}
              onValueChange={({ value }) => {
                setAmount(new BigNumber(value));
              }}
              isAllowed={({ value }) => {
                return new BigNumber(value || 0).isLessThanOrEqualTo(
                  asset.walletBalance
                );
              }}
              thousandSeparator
              allowNegative={false}
              placeholder="0"
            />
            <span className="pointer max" onClick={() => handleMaxAmount()}>
              MAX
            </span>
          </div>
        ) : (
          <p className="center warning-label">
            To Supply {asset.name} to the Fortress Protocol, you need to approve
            it first.
          </p>
        )}
      </div>
      <TabContent className="flex flex-column align-center just-center">
        <div className="flex flex-column just-center align-center apy-content">
          <div className="description">
            <div className="flex align-center">
              <img className="asset-img" src={asset.img} alt="asset" />
              <span>Supply APY</span>
            </div>
            <span>{asset.supplyApy.dp(2, 1).toString(10)}%</span>
          </div>
          <div className="description">
            <div className="flex align-center">
              <img
                style={{
                  width: 25,
                  height: 25,
                  marginLeft: 2,
                  marginRight: 16
                }}
                src={coinImg}
                alt="asset"
              />
              <span>Distribution APY</span>
            </div>
            <span>{percentFormatter(asset.ftsSupplyApy)}%</span>
          </div>
        </div>
        {isEnabled && (
          <div className="flex flex-column just-center align-center apy-content">
            <div className="borrow-limit">
              <span>Borrow Limit</span>
              {amount.isZero() || amount.isNaN() ? (
                <span>${format(borrowLimit.dp(2, 1).toString(10))}</span>
              ) : (
                <div className="flex align-center just-between">
                  <span>${format(borrowLimit.dp(2, 1).toString(10))}</span>
                  <img
                    className="arrow-right-img"
                    src={arrowRightImg}
                    alt="arrow"
                  />
                  <span>${format(newBorrowLimit.dp(2, 1).toString(10))}</span>
                </div>
              )}
            </div>
            <div className="flex align-center just-between borrow-limit-used">
              <span>Borrow Limit Used</span>
              {amount.isZero() || amount.isNaN() ? (
                <span>{borrowPercent.dp(2, 1).toString(10)}%</span>
              ) : (
                <div className="flex align-center just-between">
                  <span>{borrowPercent.dp(2, 1).toString(10)}%</span>
                  <img
                    className="arrow-right-img"
                    src={arrowRightImg}
                    alt="arrow"
                  />
                  <span>{newBorrowPercent.dp(2, 1).toString(10)}%</span>
                </div>
              )}
            </div>
            <Progress
              percent={newBorrowPercent.toString(10)}
              strokeColor="#d99d43"
              strokeWidth={7}
              showInfo={false}
            />
          </div>
        )}
        {!isEnabled && asset.id !== 'bnb' ? (
          <Button
            className="button"
            disabled={!isAdmin || isLoading}
            onClick={() => {
              onApprove();
            }}
          >
            {isLoading ? <LoadingSpinner size={5} color="#C4C4C4" /> : 'Enable'}
          </Button>
        ) : (
          <Button
            className="button"
            disabled={
              !isAdmin ||
              isLoading ||
              amount.isNaN() ||
              amount.isZero() ||
              amount.isGreaterThan(asset.walletBalance)
            }
            onClick={handleSupply}
          >
            {isLoading ? <LoadingSpinner size={5} color="#C4C4C4" /> : 'Supply'}
          </Button>
        )}
        <div className="description">
          <span>Wallet Balance</span>
          <span>
            {format(asset.walletBalance.dp(2, 1).toString(10))} {asset.symbol}
          </span>
        </div>
      </TabContent>
    </TabSection>
  );
}

SupplyTab.propTypes = {
  asset: PropTypes.object,
  settings: PropTypes.object,
  changeTab: PropTypes.func,
  onCancel: PropTypes.func,
  setSetting: PropTypes.func.isRequired
};

SupplyTab.defaultProps = {
  asset: {},
  settings: {},
  changeTab: () => {},
  onCancel: () => {}
};

const mapStateToProps = ({ account }) => ({
  settings: account.setting
});

const mapDispatchToProps = dispatch => {
  const { setSetting } = accountActionCreators;

  return bindActionCreators(
    {
      setSetting
    },
    dispatch
  );
};

export default compose(connectAccount(mapStateToProps, mapDispatchToProps))(
  SupplyTab
);
