import { getWeb3NoAccount } from './web3';
import comptrollerAbi from './abis/comptroller.json';
import interestModelAbi from './abis/interestModel.json';
import oracleAbi from './abis/oracle.json';
import fbepAbi from './abis/fbep.json';
import fbnbAbi from './abis/fbnb.json';
import voteAbi from './abis/vote.json';
import ftsAbi from './abis/fts.json';
import faiComptrollerAbi from './abis/faiComptroller.json';
import faiVaultAbi from './abis/faiVault.json';
import rewardPoolAbi from './abis/rewardPool.json';
import faiTokenAbi from './abis/fai.json';
import {
  getComptrollerAddress,
  getFaiTokenAddress,
  getFaiUnitrollerAddress,
  getFaiVaultAddress,
  getFtsRewardPoolAddress,
  getOracleAddress,
  getRewardPoolAddress,
  getVoteAddress
} from './addressHelpers';
import * as constants from './constants';

const getContract = (abi, address, web3) => {
  const _web3 = web3 ?? getWeb3NoAccount();
  return new _web3.eth.Contract(abi, address);
};

export const getFaiTokenContract = web3 => {
  return getContract(faiTokenAbi, getFaiTokenAddress(), web3);
};

export const getTokenContract = (web3, name) => {
  return getContract(
    ftsAbi,
    name == 'fts'
      ? constants.CONTRACT_FTS_TOKEN_ADDRESS
      : constants.CONTRACT_TOKEN_ADDRESS[name].address,
    web3
  );
};

export const getFbepContract = (web3, name) => {
  return getContract(
    name === 'bnb' ? fbnbAbi : fbepAbi,
    constants.CONTRACT_FBEP_ADDRESS[name].address,
    web3
  );
};

export const getComptrollerContract = web3 => {
  return getContract(comptrollerAbi, getComptrollerAddress(), web3);
};

export const getPriceOracleContract = web3 => {
  return getContract(oracleAbi, getOracleAddress(), web3);
};

export const getVoteContract = web3 => {
  return getContract(voteAbi, getVoteAddress(), web3);
};

export const getInterestModelContract = (web3, address) => {
  return getContract(interestModelAbi, address, web3);
};

export const getFaiUnitrollerContract = web3 => {
  return getContract(faiComptrollerAbi, getFaiUnitrollerAddress(), web3);
};

export const getFaiVaultContract = web3 => {
  return getContract(faiVaultAbi, getFaiVaultAddress(), web3);
};

export const getRewardPoolContract = web3 => {
  return getContract(rewardPoolAbi, getRewardPoolAddress(), web3);
};

export const getFTSRewardPoolContract = web3 => {
  return getContract(rewardPoolAbi, getFtsRewardPoolAddress(), web3);
};
