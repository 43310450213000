import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Input, Form, Select, Dropdown, Menu } from 'antd';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { bindActionCreators } from 'redux';
import { connectAccount, accountActionCreators } from 'core';
import MainLayout from 'containers/Layout/MainLayout';
import { promisify } from 'utilities';
import Button from '@material-ui/core/Button';
import { NotificationManager } from 'react-notifications';
import * as constants from 'utilities/constants';
import { BASE_SCAN_URL } from '../../config';
import { getFaiTokenAddress } from '../../utilities/addressHelpers';

const FaucetWrapper = styled.div`
  width: 100%;
  max-width: 700px;
  height: 100%;
  flex: 1;
  padding: 20px;
  input {
    width: 100%;
    height: 42px;
  }

  .header {
    max-width: 450px;
    font-size: 36px;
    font-weight: 400;
    color: var(--color-text-main);
    margin-top: 150px;
    margin-bottom: 30px;
    text-align: center;

    @media only screen and (max-width: 768px) {
      font-size: 28px;
      margin-top: 50px;
    }
  }

  .forgot-pwd-form {
    display: flex;
    margin-top: 100px;

    @media only screen and (max-width: 768px) {
      flex-direction: column;
    }

    .ant-form-item {
      margin: 0;
      width: 500px;
      height: 50px;

      @media only screen and (max-width: 768px) {
        width: 350px;
      }
    }

    .token-menu {
      display: flex;
      align-items: flex-start;
      justify-content: center;
      padding-left: 10px;
      margin-top: 0;

      @media only screen and (max-width: 768px) {
        margin-top: 20px;
        padding-left: 0;
      }

      .ant-select-selection--single {
        height: 42px;
        padding: 5px;
      }
    }
  }

  .bottom {
    color: var(--color-text-main);
    padding: 30px 0;
    display: none;

    .title {
      font-size: 24px;
      font-weight: 400;
    }

    .description {
      margin-top: 10px;
      font-size: 16px;
      font-weight: normal;
      text-align: center;
    }
  }

  .button-section {
    margin: 20px 0;
  }

  .empty-menu {
    opacity: 0;

    @media only screen and (max-width: 768px) {
      display: none;
    }
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;

  .button {
    width: 150px;
    height: 40px;
    border-radius: 20px;
    box-shadow: 0 0 0 2px #323232;
    background-color: transparent;

    .MuiButton-label {
      font-size: 15px;
      font-weight: 500;
      color: var(--color-text-main);
      text-transform: capitalize;

      @media only screen and (max-width: 1440px) {
        font-size: 12px;
      }
    }
  }
`;

const { Option } = Select;

const tokenList = [
  {
    key: 'usdc',
    name: 'USDC',
    values: [1000, 2000, 5000]
  },
  {
    key: 'usdt',
    name: 'USDT',
    values: [1000, 2000, 5000]
  },
  {
    key: 'busd',
    name: 'BUSD',
    values: [1000, 2000, 5000]
  },
  {
    key: 'bnb',
    name: 'BNB',
    values: [1, 2.5, 5]
  }
];

function Faucet({ form, getFromFaucet }) {
  const { getFieldDecorator } = form;
  const [selectedAsset, setSelectedAsset] = useState(tokenList[0]);
  const [isLoading, setIsLoading] = useState(false);

  const handleMenuClick = (e, symbol) => {
    form.validateFields((err, values) => {
      if (!err) {
        setIsLoading(true);
        promisify(getFromFaucet, {
          address: values.address,
          asset: symbol,
          amountType: e.key
        })
          .then(res => {
            setIsLoading(false);
            // let fromAddress;
            // if (symbol === 'fts') {
            //   fromAddress = constants.CONTRACT_FTS_TOKEN_ADDRESS;
            // } else if (symbol === 'bnb') {
            //   fromAddress =
            //     process.env.REACT_APP_CHAIN_ID === '97'
            //       ? process.env.REACT_APP_TEST_BNB_TOKEN_ADDRESS
            //       : process.env.REACT_APP_MAIN_BNB_TOKEN_ADDRESS;
            // } else {
            //   fromAddress = constants.CONTRACT_TOKEN_ADDRESS[symbol].address;
            // }
            NotificationManager.success(`Funding request success`);
          })
          .catch(error => {
            if (error.data && error.data.message) {
              NotificationManager.error(`${error.data.message}`);
            }
            setIsLoading(false);
          });
      }
    });
  };

  const tokenMenu = useCallback(() => {
    return (
      <Menu onClick={e => handleMenuClick(e, selectedAsset.key)}>
        <Menu.Item key="low">
          {selectedAsset.values[0]} {selectedAsset.name}s
        </Menu.Item>
        <Menu.Item key="medium">
          {selectedAsset.values[1]} {selectedAsset.name}s
        </Menu.Item>
        <Menu.Item key="high">
          {selectedAsset.values[2]} {selectedAsset.name}s
        </Menu.Item>
      </Menu>
    );
  }, [selectedAsset]);

  const onTokenChange = value => {
    setSelectedAsset(tokenList.find(item => item.key === value));
  };

  return (
    <MainLayout isHeader={false}>
      <div className="flex just-center align-center">
        <FaucetWrapper className="flex flex-column align-center just-center">
          <p className="header">Fortress Binance Smart Chain Faucet</p>
          <Form className="forgot-pwd-form">
            <Form.Item>
              {getFieldDecorator('address', {
                rules: [
                  {
                    required: true,
                    message: 'This field cannot be empty.'
                  }
                ]
              })(
                <Input placeholder="Input your Binance Smart Chain address..." />
              )}
            </Form.Item>
            <div id="token-menu" className="token-menu">
              <Select
                defaultValue={selectedAsset.key}
                style={{ width: 130, marginRight: 10 }}
                getPopupContainer={() => document.getElementById('token-menu')}
                dropdownMenuStyle={{
                  backgroundColor: '#1B1B1B'
                }}
                onChange={onTokenChange}
              >
                {tokenList.map(item => (
                  <Option
                    className="flex align-center just-between"
                    value={item.key}
                    key={item.key}
                  >
                    <img
                      className="asset-img"
                      src={constants.CONTRACT_TOKEN_ADDRESS[item.key].asset}
                      width={28}
                      height={28}
                      alt="asset"
                    />{' '}
                    <span>{item.name}</span>
                  </Option>
                  // <Option className="menu-item" value={item.key} key={item.key}>
                  //   {item.name}
                  // </Option>
                ))}
              </Select>
              <ButtonWrapper>
                <Dropdown overlay={tokenMenu} placement="bottomCenter">
                  <Button className="fill-btn next-btn button">
                    Give Me {selectedAsset.name}
                  </Button>
                </Dropdown>
              </ButtonWrapper>
            </div>
          </Form>
          <div className="flex flex-column align-center just-center bottom">
            <p className="title">How does this work?</p>
            <p className="description">
              <a
                href={`${BASE_SCAN_URL}/address/${constants.CONTRACT_FTS_TOKEN_ADDRESS}`}
                target="_blank"
                rel="noreferrer"
              >
                FTS
              </a>
              {`, `}
              <a
                href={`${BASE_SCAN_URL}/address/${constants.CONTRACT_TOKEN_ADDRESS.busd.address}`}
                target="_blank"
                rel="noreferrer"
              >
                BUSD
              </a>
              {`, `}
              <a
                href={`${BASE_SCAN_URL}/address/${constants.CONTRACT_TOKEN_ADDRESS.usdc.address}`}
                target="_blank"
                rel="noreferrer"
              >
                USDC
              </a>
              {`, `}
              <a
                href={`${BASE_SCAN_URL}/address/${constants.CONTRACT_TOKEN_ADDRESS.usdt.address}`}
                target="_blank"
                rel="noreferrer"
              >
                USDT
              </a>
              {`, `}
              <a
                href={`${BASE_SCAN_URL}/address/${getFaiTokenAddress()}`}
                target="_blank"
                rel="noreferrer"
              >
                FAI
              </a>
              {` are issued as BEP20 token.`}
            </p>
            <p className="description">
              Click to get detail about{' '}
              <a
                href="https://github.com/binance-chain/BEPs/blob/master/BEP20.md"
                target="_blank"
                rel="noreferrer"
              >
                BEP20
              </a>
            </p>
          </div>
        </FaucetWrapper>
      </div>
    </MainLayout>
  );
}

Faucet.propTypes = {
  form: PropTypes.object.isRequired,
  getFromFaucet: PropTypes.func.isRequired
};

const mapDispatchToProps = dispatch => {
  const { getFromFaucet } = accountActionCreators;

  return bindActionCreators(
    {
      getFromFaucet
    },
    dispatch
  );
};

export default compose(
  withRouter,
  Form.create({ name: 'faucet-form' }),
  connectAccount(undefined, mapDispatchToProps)
)(Faucet);
