import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
// import { Input } from 'antd';
import arrowRightImg from 'assets/img/arrow-right.png';

const HeaderWrapper = styled.div`
  height: 50px;
  margin: 20px 15px 0;
  position: relative;
  .title-wrapper {
    position: absolute;
    left: 0;
    img {
      transform: rotate(180deg);
      margin-right: 18px;
    }
    p {
      font-size: 20px;
      font-weight: 500;
      color: var(--color-text-main);
    }
    @media only screen and (max-width: 768px) {
      /* display: none; */
      p {
        font-size: 18px;
      }
    }
  }

  .alert {
    font-size: 32px;
    font-weight: 500;
    color: var(--color-text-main);

    @media only screen and (max-width: 768px) {
      font-size: 20px;
    }
  }
`;
const timestamp = new Date('Mon Apr 26 2021 03:00:00 GMT+1000').getTime();
function Header({ title, history }) {
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const [poolActive, setPoolActive] = useState(false);

  useEffect(() => {
    const timer = setInterval(() => {
      if (timestamp - Date.now() < 0) {
        setPoolActive(false);
      } else {
        setPoolActive(true);
        const timee = timestamp - Date.now();
        const time = Math.abs(timee);
        const hourss = Math.floor((time / (1000 * 60 * 60)) % 24);
        const minutess = Math.floor((time / 1000 / 60) % 60);
        const secondss = Math.floor((time / 1000) % 60);
        setHours(hourss);
        setMinutes(minutess);
        setSeconds(secondss);
      }
    }, 1000);
    return () => clearInterval(timer);
  }, []);

  const handleRoute = () => {
    if (title === 'Overview' || title === 'Details') {
      history.go(-1);
    }
    if (title === 'Market') {
      history.push('/market');
    }
  };

  return (
    <HeaderWrapper className="flex align-center just-center">
      <div
        className="flex align-center pointer title-wrapper"
        onClick={handleRoute}
      >
        {(title === 'Overview' ||
          title === 'Details' ||
          title === 'Market') && <img src={arrowRightImg} alt="arrow-left" />}
        <p
          className={`${
            title === 'Overview' || title === 'Details' ? 'highlight' : ''
          }`}
        >
          {title}
        </p>
      </div>
      {poolActive && (
        <span className="alert">
          FTS REWARDS COMING SOON({`${hours} hr ${minutes} min ${seconds} sec`})
        </span>
      )}
    </HeaderWrapper>
  );
}

Header.propTypes = {
  title: PropTypes.string,
  history: PropTypes.object
};

Header.defaultProps = {
  title: '',
  history: {}
};
export default compose(withRouter)(Header);
