/* eslint-disable no-useless-escape */
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import BigNumber from 'bignumber.js';
import MainLayout from 'containers/Layout/MainLayout';
import TotalInfo from 'components/Vault/TotalInfo';
import UserInfo from 'components/Vault/UserInfo';
import Staking from 'components/Vault/Staking';
import { methods } from 'utilities/ContractService';
import LoadingSpinner from 'components/Basic/LoadingSpinner';
import { Row, Column } from 'components/Basic/Style';
import {
  useComptroller,
  useFaiToken,
  useFaiVault,
  useToken
} from '../../hooks/useContract';
import { getFaiVaultAddress } from '../../utilities/addressHelpers';
import useRefresh from '../../hooks/useRefresh';
import { useWeb3React } from '@web3-react/core';

const MarketWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const VaultWrapper = styled.div`
  padding-top: 30px;
  height: 100%;
  position: relative;
  width: 100%;
  max-width: 1200px;
`;

const SpinnerWrapper = styled.div`
  height: 80vh;
  width: 100%;

  @media only screen and (max-width: 1440px) {
    height: 70vh;
  }
`;

function Vault() {
  const [emission, setEmission] = useState('0');
  const [totalFaiStaked, setTotalFaiStaked] = useState('0');
  const [pendingRewards, setPendingRewards] = useState('0');
  const [availableFai, setAvailableFai] = useState(new BigNumber(0));
  const [faiStaked, setFaiStaked] = useState(new BigNumber(0));
  const [faiReward, setFaiReward] = useState('0');
  const [isEnabled, setIsEnabled] = useState('0');
  const ftsTokenContract = useToken('fts');
  const vaultContract = useFaiVault();
  const compContract = useComptroller();
  const faiContract = useFaiToken();
  const { fastRefresh } = useRefresh();
  const { account } = useWeb3React();

  const updateTotalInfo = async () => {
    // total info
    let fortressFAIVaultRate = await methods.call(
      compContract.methods.fortressFAIVaultRate,
      []
    );
    fortressFAIVaultRate = new BigNumber(fortressFAIVaultRate)
      .div(1e18)
      .times(20 * 60 * 24);
    setEmission(fortressFAIVaultRate.dp(2, 1).toString(10));

    let faiBalance = await methods.call(faiContract.methods.balanceOf, [
      getFaiVaultAddress()
    ]);
    faiBalance = new BigNumber(faiBalance)
      .div(1e18)
      .dp(4, 1)
      .toString(10);
    setTotalFaiStaked(faiBalance);

    // let amount = await methods.call(vaultContract.methods.pendingRewards, []);
    // amount = new BigNumber(amount).div(1e18).dp(4, 1).toString(10);
    let amount = await methods.call(ftsTokenContract.methods.balanceOf, [
      getFaiVaultAddress()
    ]);
    amount = new BigNumber(amount)
      .div(1e18)
      .dp(4, 1)
      .toString(10);
    setPendingRewards(amount);

    // user info
    let availableAmount = await methods.call(faiContract.methods.balanceOf, [
      account
    ]);
    availableAmount = new BigNumber(availableAmount).div(1e18);
    setAvailableFai(availableAmount);

    const { 0: staked } = await methods.call(vaultContract.methods.userInfo, [
      account
    ]);
    amount = new BigNumber(staked).div(1e18);
    setFaiStaked(amount);

    amount = await methods.call(vaultContract.methods.pendingFTS, [account]);
    amount = new BigNumber(amount)
      .div(1e18)
      .dp(4, 1)
      .toString(10);
    setFaiReward(amount);

    // isEnabled
    let allowBalance = await methods.call(ftsTokenContract.methods.allowance, [
      account,
      getFaiVaultAddress()
    ]);
    allowBalance = new BigNumber(allowBalance).div(1e18);
    setIsEnabled(allowBalance.gt(availableAmount));
  };

  useEffect(() => {
    updateTotalInfo();
  }, [fastRefresh]);

  return (
    <MainLayout title="Vault">
      <MarketWrapper>
        <VaultWrapper className="flex">
          {!account ? (
            <SpinnerWrapper>
              <LoadingSpinner />
            </SpinnerWrapper>
          ) : (
            <Row>
              <Column xs="12">
                <TotalInfo
                  emission={emission}
                  totalFaiStaked={totalFaiStaked}
                  pendingRewards={pendingRewards}
                />
              </Column>
              <Column xs="12">
                <Row>
                  <Column xs="12" sm="12" md="5">
                    <UserInfo
                      availableFai={availableFai}
                      faiStaked={faiStaked}
                      faiReward={faiReward}
                    />
                  </Column>
                  <Column xs="12" sm="12" md="7">
                    <Staking
                      isEnabled={isEnabled}
                      availableFai={availableFai}
                      faiStaked={faiStaked}
                      updateTotalInfo={updateTotalInfo}
                    />
                  </Column>
                </Row>
              </Column>
            </Row>
          )}
        </VaultWrapper>
      </MarketWrapper>
    </MainLayout>
  );
}

export default Vault;
