import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { compose } from 'recompose';
import { connectAccount } from 'core';
import BigNumber from 'bignumber.js';
import commaNumber from 'comma-number';
import { methods } from 'utilities/ContractService';
import { Card } from 'components/Basic/Card';
import NumberFormat from 'react-number-format';
import Button from '@material-ui/core/Button';
import LoadingSpinner from 'components/Basic/LoadingSpinner';
import { useFaiToken, useFaiVault } from '../../hooks/useContract';
import { getFaiVaultAddress } from '../../utilities/addressHelpers';
import { useWeb3React } from '@web3-react/core';

const StakingWrapper = styled.div`
  width: 100%;
  height: 520px;
  border-radius: 5px;
  background-color: var(--color-bg-primary);
  padding: 20px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  .stake-section {
    margin: 15px 0;
    padding: 30px 0;
    border-radius: 20px;
    background-color: var(--color-bg-main);
    height: 200px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;

    .stake-info {
      font-weight: 500;
      font-size: 18px;
      color: var(--color-white);
    }

    .stake-warning {
      font-size: 15px;
      color: var(--color-text-secondary);
    }

    .stake-input {
      position: relative;
      margin: 10px 25px;
      background-color: var(--color-bg-primary);
      border-radius: 20px;
      padding: 10px 15px;
      display: flex;
      align-items: center;

      input {
        width: 85%;
        border: none;
        height: 100%;
        font-size: 24px;
        color: var(--color-text-main);
        text-align: left;
        background: transparent;
        &:focus {
          outline: none;
        }
      }

      span {
        position: absolute;
        right: 25px;
        width: 12%;
        text-align: center;
        font-weight: 400;
        font-size: 14px;
        color: var(--color-yellow);
        cursor: pointer;
      }
    }

    .button {
      width: 248px;
      height: 41px;
      border-radius: 5px;
      background-color: var(--color-yellow);

      &:hover {
        background-color: var(--color-yellow-hover);
      }

      .MuiButton-label {
        font-size: 16px;
        font-weight: 500;
        color: var(--color-btn-color);
        text-transform: capitalize;
      }
    }
  }
`;

const format = commaNumber.bindWith(',', '.');

function Staking({
  settings,
  isEnabled,
  availableFai,
  faiStaked,
  updateTotalInfo
}) {
  const [isStakeLoading, setIsStakeLoading] = useState(false);
  const [isWithdrawLoading, setIsWithdrawLoading] = useState(false);
  const [stakeAmount, setStakeAmount] = useState(new BigNumber(0));
  const [withdrawAmount, setWithdrawAmount] = useState(new BigNumber(0));
  const faiTokenContract = useFaiToken();
  const faiVaultContract = useFaiVault();
  const { account } = useWeb3React();

  /**
   * Stake FAI
   */
  const handleStakeFAI = () => {
    setIsStakeLoading(true);
    methods
      .send(
        faiVaultContract.methods.deposit,
        [
          stakeAmount
            .times(1e18)
            .integerValue()
            .toString(10)
        ],
        account
      )
      .then(res => {
        updateTotalInfo();
        setStakeAmount(new BigNumber(0));
        setIsStakeLoading(false);
      })
      .catch(err => {
        setIsStakeLoading(false);
      });
  };

  /**
   * Withdraw FAI
   */
  const handleWithdrawFAI = () => {
    setIsWithdrawLoading(true);
    methods
      .send(
        faiVaultContract.methods.withdraw,
        [
          withdrawAmount
            .times(1e18)
            .integerValue()
            .toString(10)
        ],
        account
      )
      .then(res => {
        updateTotalInfo();
        setWithdrawAmount(new BigNumber(0));
        setIsWithdrawLoading(false);
      })
      .catch(err => {
        setIsWithdrawLoading(false);
      });
  };

  const onApprove = async () => {
    setIsStakeLoading(true);
    methods
      .send(
        faiTokenContract.methods.approve,
        [
          getFaiVaultAddress(),
          new BigNumber(2)
            .pow(256)
            .minus(1)
            .toString(10)
        ],
        account
      )
      .then(res => {
        updateTotalInfo();
        setIsStakeLoading(false);
      })
      .catch(err => {
        setIsStakeLoading(false);
      });
  };

  return (
    <Card>
      <StakingWrapper>
        <div className="stake-section">
          <div className="stake-info">
            Available FAI to stake: {format(availableFai.dp(4, 1).toString(10))}{' '}
            FAI
          </div>
          {!isEnabled ? (
            <p className="stake-warning">
              To stake FAI, you need to approve it first.
            </p>
          ) : (
            <div className="stake-input">
              <NumberFormat
                autoFocus
                value={stakeAmount.isZero() ? '' : stakeAmount.toString(10)}
                onValueChange={({ value }) => {
                  setStakeAmount(new BigNumber(value));
                }}
                isAllowed={({ value }) => {
                  return new BigNumber(value || 0).lte(availableFai);
                }}
                thousandSeparator
                allowNegative={false}
                placeholder="0"
              />
              <span onClick={() => setStakeAmount(availableFai)}>MAX</span>
            </div>
          )}
          <Button className="button" disabled={true} onClick={handleStakeFAI}>
            Stake
          </Button>
        </div>
        <div className="stake-section">
          <div className="stake-info">
            FAI staked: {format(faiStaked.dp(4, 1).toString(10))} FAI
          </div>
          <div className="stake-input">
            <NumberFormat
              autoFocus
              value={withdrawAmount.isZero() ? '' : withdrawAmount.toString(10)}
              onValueChange={({ value }) => {
                setWithdrawAmount(new BigNumber(value));
              }}
              isAllowed={({ value }) => {
                return new BigNumber(value || 0).lte(faiStaked);
              }}
              thousandSeparator
              allowNegative={false}
              placeholder="0"
            />
            <span onClick={() => setWithdrawAmount(faiStaked)}>MAX</span>
          </div>
          <Button
            className="button"
            onClick={() => handleWithdrawFAI()}
            disabled={
              isWithdrawLoading ||
              withdrawAmount.isZero() ||
              withdrawAmount.isNaN() ||
              withdrawAmount.isGreaterThan(faiStaked)
            }
          >
            {isWithdrawLoading ? (
              <LoadingSpinner size={5} color="#C4C4C4" />
            ) : (
              'Withdraw'
            )}
          </Button>
        </div>
      </StakingWrapper>
    </Card>
  );
}

Staking.propTypes = {
  settings: PropTypes.object,
  isEnabled: PropTypes.bool.isRequired,
  availableFai: PropTypes.object.isRequired,
  faiStaked: PropTypes.object.isRequired,
  updateTotalInfo: PropTypes.func.isRequired
};

Staking.defaultProps = {
  settings: {}
};

const mapStateToProps = ({ account }) => ({
  settings: account.setting
});

export default compose(connectAccount(mapStateToProps, undefined))(Staking);
