import * as constants from 'utilities/constants';
import fbnbAbi from './abis/fbnb.json';
import maximillionAbi from './abis/maximillion.json';
import { getMaximillionAddress } from './addressHelpers';

export const sendSupply = async (web3, from, amount, callback) => {
  try {
    const contract = new web3.eth.Contract(
      fbnbAbi,
      constants.CONTRACT_FBEP_ADDRESS.bnb.address
    );
    const contractData = contract.methods.mint().encodeABI();

    const tx = {
      from,
      to: constants.CONTRACT_FBEP_ADDRESS.bnb.address,
      value: amount,
      data: contractData
    };
    // // Send transaction
    await web3.eth.sendTransaction(tx, (err, transactionHash) => {
      if (!err) {
        callback(true);
      }
      callback(false);
    });
  } catch (err) {
    // eslint-disable-next-line no-console
    callback(false);
  }
};

export const sendRepay = async (web3, from, amount, callback) => {
  try {
    const contract = new web3.eth.Contract(
      fbnbAbi,
      constants.CONTRACT_FBEP_ADDRESS.bnb.address
    );
    const contractData = contract.methods.repayBorrow().encodeABI();

    const tx = {
      from,
      to: constants.CONTRACT_FBEP_ADDRESS.bnb.address,
      value: amount,
      data: contractData
    };
    // Send transaction
    await web3.eth.sendTransaction(tx, (err, transactionHash) => {
      if (!err) {
        callback(true);
      }
      callback(false);
    });
  } catch (err) {
    // eslint-disable-next-line no-console
    callback(false);
  }
};

export const sendRepayMax = async (web3, from, amount, callback) => {
  const contract = new web3.eth.Contract(
    maximillionAbi,
    getMaximillionAddress()
  );
  try {
    const contractData = contract.methods.repayBehalf(from).encodeABI();

    const tx = {
      from,
      to: getMaximillionAddress(),
      value: amount,
      data: contractData
    };
    // Send transaction
    await web3.eth.sendTransaction(tx, (err, transactionHash) => {
      if (!err) {
        callback(true);
      }
      callback(false);
    });
  } catch (err) {
    console.log(err);
    // eslint-disable-next-line no-console
    callback(false);
  }
};
