import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { compose } from 'recompose';
import { connectAccount } from 'core';
import Button from '@material-ui/core/Button';
import commaNumber from 'comma-number';
import DelegationTypeModal from 'components/Basic/DelegationTypeModal';
import { Card } from 'components/Basic/Card';
import coinImg from 'assets/img/fts.svg';
import { BASE_SCAN_URL } from '../../config';
import { useWeb3React } from '@web3-react/core';

const VotingWalletWrapper = styled.div`
  width: 100%;
  border-radius: 5px;
  background-color: var(--color-bg-primary);
  padding: 36px 22px 48px 15px;

  .header {
    padding-left: 35px;
    margin-bottom: 20px;
    .title {
      font-size: 17px;
      font-weight: 500;
      color: var(--color-text-main);
    }
  }

  .content {
    padding: 20px 0px;
    border-bottom: var(--border-style);

    img {
      width: 25px;
      height: 25px;
      border-radius: 50%;
      margin-right: 9px;
    }

    a,
    p {
      font-size: 16px;
      font-weight: bold;
      color: var(--color-orange);
    }

    .content-label {
      padding-left: 35px;
      font-size: 16px;
      color: var(--color-text-secondary);
    }

    .content-value {
      font-size: 28.5px;
      font-weight: 500;
      color: var(--color-yellow);
    }
  }
  .delegate-change {
    padding-left: 35px;
    .content-label {
      padding-left: 0px;
    }
    .change {
      font-size: 16px;
      font-weight: bold;
      color: var(--color-orange);
    }
  }
  .setup {
    padding: 30px 35px 0px;

    .setup-header {
      font-size: 18px;
      font-weight: 500;
      color: var(--color-text-main);
    }

    .setup-content {
      margin-top: 32px;
      font-size: 16px;
      color: var(--color-text-secondary);

      a {
        color: var(--color-orange);
      }
    }
  }

  .started-btn {
    margin-top: 30px;
    width: 50%;
    height: 46px;
    border-radius: 5px;
    background-color: var(--color-yellow);

    &:hover {
      background-color: var(--color-yellow-hover);
    }

    .MuiButton-label {
      font-size: 13px;
      font-weight: 500;
      color: var(--color-black);
      text-transform: capitalize;
    }
  }

  span {
    color: var(--color-orange);
  }

  .mint-content-label {
    margin-top: 20px;
    padding-left: 35px;
    font-weight: 500;
    font-size: 16px;
    color: var(--color-text-secondary);
  }

  .collect-btn {
    width: 100px;
    text-align: center;
    padding: 5px 10px;
    border-radius: 5px;
    border: 1px solid var(--color-yellow);
    margin-left: 35px;
    margin-top: 20px;
  }
`;

const format = commaNumber.bindWith(',', '.');

function VotingWallet({ balance, settings, delegateAddress, delegateStatus }) {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const { account } = useWeb3React();

  return (
    <Card>
      <VotingWalletWrapper>
        <div className="flex align-center header">
          <p className="title">Voting Wallet</p>
        </div>
        <div className="flex flex-column content">
          <p className="content-label">Fortress Balance</p>
          <div className="flex align-center just-between">
            <div className="flex align-center">
              <img src={coinImg} alt="coin" />
              <p className="content-value">{format(balance)}</p>
            </div>
          </div>
        </div>
        {delegateStatus && (
          <div className="flex flex-column content delegate-change">
            <p className="content-label">Delegating To</p>
            <div className="flex align-center just-between">
              <div className="flex align-center">
                <a
                  className="content-value"
                  href={`${BASE_SCAN_URL}/address/${delegateAddress}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  {delegateStatus === 'self'
                    ? 'Self'
                    : `${delegateAddress.substr(
                        0,
                        4
                      )}...${delegateAddress.substr(
                        delegateAddress.length - 4,
                        4
                      )}`}
                </a>
              </div>
              <div className="flex align-center">
                <p
                  className="change pointer"
                  onClick={() => setIsOpenModal(true)}
                >
                  Change
                </p>
              </div>
            </div>
          </div>
        )}
        {account && !delegateStatus && (
          <div className="flex flex-column setup">
            <p className="setup-header">Setup Voting</p>
            <p className="setup-content">
              You can either vote on each proposal yourself or delegate your
              votes to a third party. Fortress Governance puts you in charge of
              the future of Fortress.
            </p>
          </div>
        )}
        {account && !delegateStatus && (
          <div className="center footer">
            <Button
              className="started-btn"
              onClick={() => setIsOpenModal(true)}
            >
              Get Started
            </Button>
          </div>
        )}
        <DelegationTypeModal
          visible={isOpenModal}
          balance={balance}
          delegateStatus={delegateStatus}
          address={account ? account : ''}
          onCancel={() => setIsOpenModal(false)}
        />
      </VotingWalletWrapper>
    </Card>
  );
}

VotingWallet.propTypes = {
  balance: PropTypes.string.isRequired,
  settings: PropTypes.object.isRequired,
  delegateAddress: PropTypes.string.isRequired,
  delegateStatus: PropTypes.string.isRequired
};

const mapStateToProps = ({ account }) => ({
  settings: account.setting
});

export default compose(connectAccount(mapStateToProps, undefined))(
  VotingWallet
);
