import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import commaNumber from 'comma-number';
import { Card } from 'components/Basic/Card';

const EarnedInfoWrapper = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 5px;
  background-color: var(--color-bg-primary);
  display: flex;
  justify-content: space-between;
  padding: 20px 40px;

  @media (max-width: 992px) {
    padding: 20px;
    flex-direction: column;
  }

  .total-item {
    @media (max-width: 992px) {
      margin: 10px;
    }

    .prop {
      font-weight: 400;
      font-size: 20px;
      color: var(--color-text-secondary);
    }

    .value {
      font-weight: 400;
      font-size: 24px;
      color: var(--color-white);
      margin-top: 10px;
    }
  }
`;

const format = commaNumber.bindWith(',', '.');

function EarnedInfo({ pendingRewards }) {
  return (
    <Card>
      <EarnedInfoWrapper>
        <div className="total-item">
          <div className="prop">BTC Earned</div>
          <div className="value">{format(pendingRewards.btc)} BTC</div>
        </div>
        <div className="total-item">
          <div className="prop">WBNB Earned</div>
          <div className="value">{format(pendingRewards.bnb)} BNB</div>
        </div>
        <div className="total-item">
          <div className="prop">BUSD Earned</div>
          <div className="value">{format(pendingRewards.busd)} BUSD</div>
        </div>
      </EarnedInfoWrapper>
    </Card>
  );
}

EarnedInfo.propTypes = {
  pendingRewards: PropTypes.string.isRequired
};

EarnedInfo.defaultProps = {};

export default EarnedInfo;
