import addresses from '../config/contracts';

const chainId = process.env.REACT_APP_CHAIN_ID;

export const getFaiTokenAddress = () => {
  return addresses.faiToken[chainId];
};

export const getComptrollerAddress = () => {
  return addresses.comptroller[chainId];
};

export const getFaiUnitrollerAddress = () => {
  return addresses.faiUnitroller[chainId];
};

export const getFaiVaultAddress = () => {
  return addresses.faiVault[chainId];
};

export const getRewardPoolAddress = () => {
  return addresses.rewardPool[chainId];
};

export const getFtsRewardPoolAddress = () => {
  return addresses.ftsRewardPool[chainId];
};

export const getVoteAddress = () => {
  return addresses.vote[chainId];
};

export const getMaximillionAddress = () => {
  return addresses.maximillion[chainId];
};

export const getOracleAddress = () => {
  return addresses.oracle[chainId];
};
