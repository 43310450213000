import { useMemo } from 'react';
import {
  getComptrollerContract,
  getInterestModelContract,
  getPriceOracleContract,
  getTokenContract,
  getVoteContract,
  getFbepContract,
  getFaiUnitrollerContract,
  getFaiVaultContract,
  getRewardPoolContract,
  getFTSRewardPoolContract,
  getFaiTokenContract
} from '../utilities/contractHelpers';
import useWeb3 from './useWeb3';

export const useToken = name => {
  const web3 = useWeb3();
  return useMemo(() => getTokenContract(web3, name), [web3, name]);
};

export const useFbep = name => {
  const web3 = useWeb3();
  return useMemo(() => getFbepContract(web3, name), [web3, name]);
};

export const useFaiToken = () => {
  const web3 = useWeb3();
  return useMemo(() => getFaiTokenContract(web3), [web3]);
};

export const useComptroller = () => {
  const web3 = useWeb3();
  return useMemo(() => getComptrollerContract(web3), [web3]);
};

export const usePriceOracle = () => {
  const web3 = useWeb3();
  return useMemo(() => getPriceOracleContract(web3), [web3]);
};

export const useVote = () => {
  const web3 = useWeb3();
  return useMemo(() => getVoteContract(web3), [web3]);
};

export const useInterestModel = () => {
  const web3 = useWeb3();
  return useMemo(() => getInterestModelContract(web3), [web3]);
};

export const useFaiUnitroller = () => {
  const web3 = useWeb3();
  return useMemo(() => getFaiUnitrollerContract(web3), [web3]);
};

export const useFaiVault = () => {
  const web3 = useWeb3();
  return useMemo(() => getFaiVaultContract(web3), [web3]);
};

export const useRewardPool = () => {
  const web3 = useWeb3();
  return useMemo(() => getRewardPoolContract(web3), [web3]);
};

export const useFtsRewardPool = () => {
  const web3 = useWeb3();
  return useMemo(() => getFTSRewardPoolContract(web3), [web3]);
};
