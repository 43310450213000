import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { compose } from 'recompose';
import BigNumber from 'bignumber.js';
import { bindActionCreators } from 'redux';
import { Select, Icon } from 'antd';
import { connectAccount, accountActionCreators } from 'core';
import OverviewChart from 'components/Basic/OverviewChart';
import { promisify } from 'utilities';
import * as constants from 'utilities/constants';
import commaNumber from 'comma-number';
import { addToken, getBigNumber } from 'utilities/common';
import { Card } from 'components/Basic/Card';
import plusImg from 'assets/img/plus.svg';
import { percentFormatter } from 'utilities/common';
import { useMarkets } from '../../hooks/useMarkets';
import { useMarketsUser } from '../../hooks/useMarketsUser';
import { ftokenDecimals } from '../../config';

const CardWrapper = styled.div`
  width: 100%;
  border-radius: 5px;
  background-color: var(--color-bg-primary);
  padding: 28px 25px 27px 25px;

  .asset-img {
    width: 28px;
    height: 28px;
    margin-right: 13px;
  }

  .label {
    font-size: 16px;
    font-weight: normal;
    color: var(--color-text-secondary);
  }

  .historic-label {
    font-size: 16px;
    font-weight: normal;
    color: var(--color-text-secondary);
  }

  .value {
    font-size: 17px;
    font-weight: 500;
    color: var(--color-text-main);
    text-align: right;
  }

  .apy-label {
    text-align: right;
    font-size: 16px;
    font-weight: normal;
    color: var(--color-text-secondary);
  }

  .apy-value {
    text-align: right;
    font-size: 19px;
    font-weight: 500;

    &.green {
      color: var(--color-text-green);
    }

    &.red {
      color: var(--color-text-red);
    }
  }

  .description {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media only screen and (max-width: 768px) {
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      margin-bottom: 10px;
    }
  }

  .overview {
    font-size: 20px;
    font-weight: 400;
    color: var(--color-text-main);
    margin-bottom: 15px;
  }

  .asset-select-wrapper {
    width: 100%;

    @media only screen and (max-width: 1496px) {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  .add-token-wrapper {
    margin: 15px 0;
    color: var(--color-white);

    .add-token {
      font-size: 18px;
      color: var(--color-green);
      margin-left: 10px;
      cursor: pointer;
    }

    .underlying-asset,
    .vtoken-asset {
      margin-right: 20px;
    }

    .destination {
      color: var(--color-text-secondary);
    }
  }
`;

const AssetSelectWrapper = styled.div`
  position: relative;
`;

const { Option } = Select;
const abortController = new AbortController();
const format = commaNumber.bindWith(',', '.');

function Overview({ settings, getMarketHistory }) {
  const [currentAsset, setCurrentAsset] = useState('fts');
  const [data, setData] = useState([]);
  const [marketInfo, setMarketInfo] = useState({});
  const [currentAPY, setCurrentAPY] = useState(new BigNumber(0));
  const [decimals, setDecimals] = useState(18);
  const { markets } = useMarkets();
  const { userMarketInfo } = useMarketsUser();

  const getGraphData = async (address, type) => {
    let tempData = [];
    const res = await promisify(getMarketHistory, {
      address,
      type
    });

    tempData = res.data
      .map(m => {
        return {
          dateTime: m.dateTime,
          supplyApy: +new BigNumber(m.supplyApy || 0).dp(8, 1).toString(10),
          borrowApy: +new BigNumber(m.borrowApy || 0).dp(8, 1).toString(10)
        };
      })
      .reverse();
    setData([...tempData]);
  };

  const getGovernanceData = useCallback(async () => {
    if (!currentAsset) return;
    if (markets && markets.length > 0) {
      const info = markets.find(
        item => item.underlyingSymbol.toLowerCase() === currentAsset
      );
      setMarketInfo(info || {});
    }
  }, [markets, currentAsset]);

  useEffect(() => {
    getGovernanceData();
  }, [getGovernanceData]);

  useEffect(() => {
    if (currentAsset) {
      getGraphData(
        constants.CONTRACT_FBEP_ADDRESS[currentAsset].address,
        '1hr'
      );
    }
    return function cleanup() {
      abortController.abort();
    };
  }, [currentAsset]);

  useEffect(() => {
    handleChangeAsset('fts');
  }, []);

  useEffect(() => {
    if (userMarketInfo && userMarketInfo.length > 0) {
      const currentMarketInfo =
        userMarketInfo.filter(s => s && s.id === currentAsset).length !== 0
          ? userMarketInfo.filter(s => s && s.id === currentAsset)[0]
          : {};
      const supplyApy = getBigNumber(currentMarketInfo.supplyApy);
      const borrowApy = getBigNumber(currentMarketInfo.borrowApy);
      const supplyApyWithFTS = settings.withFTS
        ? supplyApy.plus(currentMarketInfo.ftsSupplyApy)
        : supplyApy;
      const borrowApyWithFTS = settings.withFTS
        ? getBigNumber(currentMarketInfo.ftsBorrowApy).minus(borrowApy)
        : new BigNumber(0).minus(borrowApy);
      setDecimals(currentMarketInfo.decimals);
      setCurrentAPY(
        (settings.marketType || 'supply') === 'supply'
          ? supplyApyWithFTS
          : borrowApyWithFTS
      );
    }
  }, [currentAsset, settings.marketType, userMarketInfo, settings.withFTS]);

  const handleChangeAsset = value => {
    setCurrentAsset(value);
  };

  return (
    <Card>
      <CardWrapper>
        <div className="flex flex-column">
          <div className="overview">Overview</div>
          <div className="flex align-center just-between asset-select-wrapper">
            <AssetSelectWrapper
              className="flex align-center just-end"
              id="asset"
            >
              <Select
                defaultValue="fts"
                style={{ width: 270, marginRight: 10 }}
                getPopupContainer={() => document.getElementById('asset')}
                dropdownMenuStyle={{
                  backgroundColor: '#141414'
                }}
                dropdownClassName="asset-select"
                onChange={handleChangeAsset}
              >
                {Object.keys(constants.CONTRACT_FBEP_ADDRESS).map(
                  (key, index) => (
                    <Option
                      className="flex align-center just-between"
                      value={constants.CONTRACT_FBEP_ADDRESS[key].id}
                      key={index}
                    >
                      <img
                        className="asset-img"
                        src={constants.CONTRACT_TOKEN_ADDRESS[key].asset}
                        alt="asset"
                      />{' '}
                      <span>
                        {constants.CONTRACT_TOKEN_ADDRESS[key].symbol}
                      </span>
                    </Option>
                  )
                )}
              </Select>
            </AssetSelectWrapper>
          </div>
          {/* <p className="value">
            {`$${
              (settings.marketType || 'supply') === 'supply'
                ? new BigNumber(marketInfo.totalSupply || 0)
                    .div(new BigNumber(10).pow(ftokenDecimals))
                    .dp(2, 1)
                    .toString(10)
                : new BigNumber(marketInfo.totalBorrows || 0)
                    .div(
                      new BigNumber(10).pow(
                        constants.CONTRACT_TOKEN_ADDRESS[currentAsset].decimals
                      )
                    )
                    .dp(2, 1)
                    .toString(10)
            }`}
          </p> */}
        </div>
        {window.ethereum && window.ethereum.networkVersion && (
          <div className="flex align-center add-token-wrapper">
            {currentAsset !== 'bnb' && (
              <div className="flex align-center underlying-asset">
                {currentAsset.toUpperCase()}
                <img
                  src={plusImg}
                  className="add-token"
                  onClick={() => addToken(currentAsset, decimals, 'token')}
                />
              </div>
            )}
            <div className="flex align-center vtoken-asset">
              {`f${
                currentAsset === 'btcb' ? 'BTC' : currentAsset.toUpperCase()
              }`}
              <img
                src={plusImg}
                className="add-token"
                onClick={() => addToken(currentAsset, ftokenDecimals, 'ftoken')}
              />
            </div>
            <p className="destination">To MetaMask</p>
          </div>
        )}

        <div className="flex just-between">
          <div className="historic-label">Historical rates</div>
          <div className="flex flex-column flex-end">
            <p className={`apy-value ${currentAPY.lt(0) ? 'red' : 'green'}`}>
              {percentFormatter(currentAPY.abs())}%
            </p>
            <p className="apy-label">
              {(settings.marketType || 'supply') === 'supply'
                ? 'Supply APY'
                : 'Borrow APY'}
            </p>
          </div>
        </div>
        <OverviewChart
          marketType={settings.marketType || 'supply'}
          data={data}
        />
        <div className="description">
          <p className="label">Price</p>
          <p className="value">{`$${new BigNumber(
            marketInfo.underlyingPriceUSD || 0
          )
            .dp(8, 1)
            .toString(10)}`}</p>
        </div>
        <div className="description">
          <p className="label">Market Liquidity</p>
          <p className="value">
            {`${format(
              new BigNumber(marketInfo.cash || 0)
                .div(new BigNumber(10).pow(decimals))
                .dp(8, 1)
                .toString(10)
            )} ${marketInfo.underlyingSymbol || ''}`}
          </p>
        </div>
        <div className="description">
          <p className="label"># of Suppliers</p>
          <p className="value">{format(marketInfo.supplierCount)}</p>
        </div>
        <div className="description">
          <p className="label"># of Borrowers</p>
          <p className="value">{format(marketInfo.borrowerCount)}</p>
        </div>
        <div className="description">
          <p className="label">Reserves</p>
          <p className="value">{`${new BigNumber(marketInfo.totalReserves || 0)
            .div(new BigNumber(10).pow(decimals))
            .dp(8, 1)
            .toString(10)} ${marketInfo.underlyingSymbol || ''}`}</p>
        </div>
        <div className="description">
          <p className="label">Reserve Factor</p>
          <p className="value">{`${new BigNumber(marketInfo.reserveFactor || 0)
            .div(new BigNumber(10).pow(18))
            .multipliedBy(100)
            .dp(8, 1)
            .toString(10)}%`}</p>
        </div>
        <div className="description">
          <p className="label">Collateral Factor</p>
          <p className="value">{`${new BigNumber(
            marketInfo.collateralFactor || 0
          )
            .div(new BigNumber(10).pow(18))
            .times(100)
            .dp(2, 1)
            .toString(10)}%`}</p>
        </div>
        <div className="description">
          <p className="label">Total Supply</p>
          <p className="value">
            {`$${format(
              new BigNumber(marketInfo.totalSupplyUsd || 0)
                .dp(2, 1)
                .toString(10)
            )}`}
          </p>
        </div>
        <div className="description">
          <p className="label">Total Borrow</p>
          <p className="value">
            {`$${format(
              new BigNumber(marketInfo.totalBorrowsUsd || 0)
                .dp(2, 1)
                .toString(10)
            )}`}
          </p>
        </div>
        <div className="description">
          <p className="label">Exchange Rate</p>
          <p className="value">
            {`1 ${marketInfo.underlyingSymbol || ''} = ${Number(
              new BigNumber(1)
                .div(
                  new BigNumber(marketInfo.exchangeRate).div(
                    new BigNumber(10).pow(18 + decimals - ftokenDecimals)
                  )
                )
                .toString(10)
            ).toFixed(6)} ${marketInfo.symbol || ''}`}
          </p>
        </div>
      </CardWrapper>
    </Card>
  );
}

Overview.propTypes = {
  settings: PropTypes.object.isRequired,
  getMarketHistory: PropTypes.func.isRequired
};

const mapStateToProps = ({ account }) => ({
  settings: account.setting
});

const mapDispatchToProps = dispatch => {
  const { getMarketHistory } = accountActionCreators;

  return bindActionCreators(
    {
      getMarketHistory
    },
    dispatch
  );
};

export default compose(connectAccount(mapStateToProps, mapDispatchToProps))(
  Overview
);
