import React, { useState } from 'react';
import styled from 'styled-components';
import LoadingSpinner from 'components/Basic/LoadingSpinner';
import commaNumber from 'comma-number';
import coinImg from 'assets/img/fts.svg';
import { methods } from 'utilities/ContractService';
import { useComptroller } from '../../hooks/useContract';
import { useWeb3React } from '@web3-react/core';

const ClaimWrapper = styled.div`
  height: 100%;
  border-radius: 5px;
  background-color: var(--color-bg-primary);
  padding: 37px 16px;
  margin: 10px 15px;
  display: flex;
  justify-content: space-between;
  flex-direction: row;

  .content-label {
    padding-left: 35px;
    font-size: 16px;
    color: var(--color-text-main);
  }
  .collect-btn {
    width: 100px;
    text-align: center;
    padding: 5px 10px;
    border-radius: 5px;
    border: 1px solid var(--color-yellow);
    margin-left: 35px;
    margin-top: 20px;
    color: var(--color-yellow);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .content-value {
    font-size: 20px;
    font-weight: 500;
    color: var(--color-yellow);
  }
  img {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    margin-right: 9px;
  }
`;

const format = commaNumber.bindWith(',', '.');

function Claim({ earnedBalance }) {
  const [isLoading, setIsLoading] = useState(false);
  const comptrollerContract = useComptroller();
  const { account } = useWeb3React();

  const handleCollect = () => {
    if (+earnedBalance !== 0) {
      setIsLoading(true);
      methods
        .send(comptrollerContract.methods.claimFortress, [account], account)
        .then(res => {
          setIsLoading(false);
        })
        .catch(() => {
          setIsLoading(false);
        });
    }
  };
  return (
    <ClaimWrapper>
      <div className="flex flex-column">
        <p className="content-label">Fortress Earned</p>
        <div className="flex align-center">
          <img src={coinImg} alt="coin" />
          <p className="content-value">{format(earnedBalance)}</p>
        </div>
      </div>
      <div className="collect-btn">
        <p className="pointer" onClick={handleCollect}>
          {isLoading ? (
            <LoadingSpinner size={5} color="#C4C4C4" />
          ) : (
            'Collect FTS'
          )}
        </p>
      </div>
    </ClaimWrapper>
  );
}

export default Claim;
