const auth = {
  user: null
};

const account = {
  setting: {
    marketType: 'supply',
    pendingInfo: {
      type: '',
      status: false,
      amount: 0,
      symbol: ''
    },
    withFTS: true
  }
};
export const initialState = {
  auth,
  account
};
