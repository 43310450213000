import { hot } from 'react-hot-loader/root';
import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter, Route, Redirect, Switch } from 'react-router-dom';
import { IntlProvider, addLocaleData } from 'react-intl';
import en from 'react-intl/locale-data/en';
import enMessages from 'lang/en';
import { store } from 'core';
import Dashboard from 'containers/Main/Dashboard';
import Faucet from 'containers/Main/Faucet';
import Vote from 'containers/Main/Vote';
import FTS from 'containers/Main/FTS';
import Market from 'containers/Main/Market';
import Vault from 'containers/Main/Vault';
import FftsVault from 'containers/Main/FftsVault';
import FtsVault from 'containers/Main/FtsVault';
import MarketDetail from 'containers/Main/MarketDetail';
import VoteOverview from 'containers/Main/VoteOverview';
import ProposerDetail from 'containers/Main/ProposerDetail';
import VoterLeaderboard from 'containers/Main/VoterLeaderboard';
import Liquidator from 'containers/Main/Liquidator';
import Faq from 'containers/Main/Faq';
import { NotificationContainer } from 'react-notifications';
import Theme from './Theme';
import { RefreshContextProvider } from '../context/RefreshContext';
import { MarketContextProvider } from '../context/MarketContext';

import 'assets/styles/App.scss';
import 'react-notifications/lib/notifications.css';

addLocaleData([...en]);
const initialLang = 'en';

const messages = {
  en: enMessages
};

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      lang: initialLang
    };
  }

  render() {
    const { lang } = this.state;
    const message = messages[lang];
    return (
      <Theme>
        <IntlProvider locale={lang} messages={message}>
          <Provider store={store}>
            <RefreshContextProvider>
              <MarketContextProvider>
                <BrowserRouter>
                  <Switch
                    atEnter={{ opacity: 0 }}
                    atLeave={{ opacity: 0.5 }}
                    atActive={{ opacity: 1 }}
                    className="switch-wrapper"
                  >
                    <Route exact path="/dashboard" component={Dashboard} />
                    <Route exact path="/vote" component={Vote} />
                    <Route exact path="/fts" component={FTS} />
                    <Route exact path="/market" component={Market} />
                    <Route exact path="/vault" component={Vault} />
                    <Route exact path="/ffts-vault" component={FftsVault} />
                    <Route exact path="/fts-vault" component={FtsVault} />
                    <Route
                      exact
                      path="/market/:asset"
                      component={MarketDetail}
                    />
                    <Route
                      exact
                      path="/vote/leaderboard"
                      component={VoterLeaderboard}
                    />
                    <Route
                      exact
                      path="/vote/proposal/:id"
                      component={VoteOverview}
                    />
                    <Route
                      exact
                      path="/vote/address/:address"
                      component={ProposerDetail}
                    />
                    <Route exact path="/faq" component={Faq} />
                    <Route exact path="/liquidator" component={Liquidator} />
                    {process.env.REACT_APP_CHAIN_ID === '97' && (
                      <Route exact path="/faucet" component={Faucet} />
                    )}
                    <Redirect from="/" to="/dashboard" />
                  </Switch>
                  <NotificationContainer />
                </BrowserRouter>
              </MarketContextProvider>
            </RefreshContextProvider>
          </Provider>
        </IntlProvider>
      </Theme>
    );
  }
}

export default hot(App);
