import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import commaNumber from 'comma-number';
import { Card } from 'components/Basic/Card';
import { Row, Column } from 'components/Basic/Style';

const VotingPowerWrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: 14px 56px 14px 56px;
  border-radius: 5px;
  background-color: var(--color-bg-primary);
  border: 1px solid var(--color-yellow);

  .title {
    font-size: 20px;
    color: var(--color-text-main);
    font-weight: bold;
  }

  .content {
    color: var(--color-yellow);
    font-size: 28.5px;
    font-weight: 500;
  }

  span {
    color: var(--color-bg-main);
  }
`;

const format = commaNumber.bindWith(',', '.');

function VotingPower({ power }) {
  return (
    <Card>
      <VotingPowerWrapper className="flex align-center just-between">
        <p className="title">Voting Weight</p>
        <p className="content">{format(power)}</p>
      </VotingPowerWrapper>
    </Card>
  );
}

VotingPower.propTypes = {
  power: PropTypes.string
};

VotingPower.defaultProps = {
  power: '0.00000000'
};

export default VotingPower;
