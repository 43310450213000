import React, { useEffect, useState } from 'react';
import { Collapse } from 'antd';
import styled from 'styled-components';
import highlightWords from 'highlight-words';
import JsxParser from 'react-jsx-parser';
import MainLayout from 'containers/Layout/MainLayout';

import plusImg from 'assets/img/plus.png';
import minusImg from 'assets/img/minus.png';

import FAQ_CONTENT from './FaqContent';

const FaqWrapper = styled.div`
  width: 100%;
  max-width: 1200px;
  margin: auto;
  padding: 20px 0 20px 0;
  @media only screen and (max-width: 768px) {
    padding: 29px 15px 10px 15px;
  }
`;

const SearchBar = styled.input`
  background-color: #313131;
  border: 1px solid #3D3D3D;
  box-sizing: border-box;
  border-radius: 6px;
  height: 44px;
  width: 100%;
  padding-left: 25px;
  color: var(--color-text-main);
  :focus-visible {
    outline: none;
  }
`;

const { Panel } = Collapse;

const StyledWrapper = styled.div`
  position: relative;
  margin-top: 18px;
  .ant-collapse, .ant-collapse-item {
    background-color: transparent;
    border: none;
  }
  .ant-collapse-header {
    background-color: var(--color-bg-primary);
    border-radius: 20px 20px 20px 20px !important;
    border-color:  var(--color-bg-primary);
    height: 87px;
    color: var(--color-text-main) !important;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 148.5%;
    display: flex;
    align-items: center;
    padding-left: 25px !important;
  }
  .ant-collapse-item-active .ant-collapse-header {
    border-radius: 20px 20px 0 0 !important;
    color: var(--color-yellow) !important;
  }
  .ant-collapse .ant-collapse-item {
    border-bottom: none;
  }
  .ant-collapse-content {
    background: transparent;
    border: none;
  }
  .ant-collapse-content-box {
    background: #222222;
    border-radius: 0px 0px 20px 20px;
    border-color:  var(--color-bg-primary);
    color: var(--color-text-main);
    min-height: 105px;
    display: flex;
    align-items: center;
    padding-left: 25px !important;
    font-family: Roboto;
    font-style: normal;
    font-weight: 200;
    font-size: 18px;
    line-height: 148.5%;
    .jsx-parser p {
      margin-bottom: 30px;
    }
    .jsx-parser p:last-child {
      margin-bottom: 0;
    }
  }
  @media only screen and (max-width: 768px) {
    margin-top: 15px;
    .ant-collapse-header {
      border-radius: 19px 19px 19px 19px !important;
      border: 1px solid #242424;
      height: 51px;
      font-size: 16px;
      padding-left: 15px !important;
      padding-right: 32px !important;
    }
    .ant-collapse-content-box {
      border-radius: 0px 0px 19px 19px;
      min-height: 105px;
      padding-left: 15px !important;
      font-size: 16px;
      .jsx-parser p {
        margin-bottom: 20px;
      }
    }
  }
`;

const Minus = styled.img`
  position: absolute;
  right: 35px;
  top: 46px;
  @media only screen and (max-width: 768px) {
    right: 15px;
    top: 26px;
  }
`;
const Plus = styled.img`
  position: absolute;
  right: 35px;
  top: 40px;
  @media only screen and (max-width: 768px) {
    right: 15px;
    top: 20px;
  }
`;

const StyledCollapse = ({opened, title, content, search = ""}) => {
  const [open, setOpen] = useState(opened);

  useEffect(() => {
    setOpen(opened)
  }, [opened])

  return (
    <StyledWrapper onClick={() => {setOpen(!open)}}>
      <Collapse activeKey={open ? 1 : 0}>
        <Panel
          header={<JsxParser jsx={getHighlightedText(title, search)} />}
          key="1"
          showArrow={false}
        >
          <JsxParser
            jsx={getHighlightedText(content, search)}
          />
        </Panel>
      </Collapse>
      {
        open ?
        <Minus
          src={minusImg}
          alt="asset"
        /> :
        <Plus
          src={plusImg}
          alt="asset"
        />
      }
    </StyledWrapper>
  );
}

// const getHighlightedText = (text, highlight) => {
//   // Split text on highlight term, include term itself into parts, ignore case
//   if (highlight.length < 2) return text;
//   try {
//     const parts = text.split(new RegExp(`(${highlight})`, 'gi'));
//     return parts.map(part => part.toLowerCase() === highlight.toLowerCase() ? `<span style={{color: "red"}}>${part}</span>` : part).join("");
//   } catch (error) {
//     console.log(error);
//   }

//   return text;
// }

const isSearchValid = (search) => {
  const tags = ["<a>", "<span>", "<p>", "<div>", "</a>", "</span>", "</p>", "</div>"];
  for (let i = 0; i < tags.length; i++) {
    const tag = tags[i];
    if (tag.includes(search)) {
      return false;
    }
  }
  if (search.length < 2) {
    return false;
  }

  return true;
}

const getHighlightedText = (text, search) => {
  if (!isSearchValid(search)) {
    return text;
  }

  const chunks = highlightWords({
    text: text,
    query: search
  });

  return chunks.map(part => part.match ? `<span style={{color: "red"}}>${part.text}</span>` : part.text).join("");
}


function Faq() {
  const [search, setSearch] = useState("");
  const [content, setContent] = useState([]);

  useEffect(() => {
    if (!isSearchValid(search)) {
      setContent(FAQ_CONTENT);
    } else {
      let temp = [...FAQ_CONTENT].filter((e, index) => (e.title.toLowerCase().includes(search.toLowerCase()) || e.content.toLowerCase().includes(search.toLowerCase())));
      temp = temp.map(e => ({
        title: getHighlightedText(e.title, search),
        content: getHighlightedText(e.content, search)
      }));
      setContent(temp);
    }
  }, [search])

  return (
    <MainLayout title="A Frequently Asked Questions">
      <FaqWrapper>
        <SearchBar placeholder="Type Keywords to find answers" value={search} onChange={(e) => { setSearch(e.target.value) }} />
        {
          content.map((e, index) => {
            return (
              <StyledCollapse opened={(index == 0 || isSearchValid(search)) ? true : false} title={e.title} content={e.content} search="" />
            )
          })
        }
      </FaqWrapper>
    </MainLayout>
  );
}

export default Faq;
