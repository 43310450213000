const BASE_SCAN_URLS = {
  56: 'https://bscscan.com',
  97: 'https://testnet.bscscan.com'
};

const API_ENDPOINT_URLS = {
  56: 'https://apibsc.fortress.loans/api',
  97: 'https://apibsctestnet.fortress.loans/api'
};

export const BASE_SCAN_URL = BASE_SCAN_URLS[process.env.REACT_APP_CHAIN_ID];

export const API_ENDPOINT_URL =
  API_ENDPOINT_URLS[process.env.REACT_APP_CHAIN_ID];

export const connectorLocalStorageKey = 'fortress-local-key';

export const ftokenDecimals = 8;

export const FTS_VAULT_REWARD = 5000;
