import * as constants from 'utilities/constants';
import BigNumber from 'bignumber.js';
import { getFaiTokenAddress } from './addressHelpers';

const ethers = require('ethers');
const commaNumber = require('comma-number');

const format = commaNumber.bindWith(',', '.');

export const encodeParameters = (types, values) => {
  const abi = new ethers.utils.AbiCoder();
  return abi.encode(types, values);
};

export const getArgs = func => {
  // First match everything inside the function argument parens.
  const args = func.toString().match(/.*?\(([^)]*)\)/)
    ? func.toString().match(/.*?\(([^)]*)\)/)[1]
    : '';
  // Split the arguments string into an array comma delimited.
  return args
    .split(',')
    .map(arg => {
      // Ensure no inline comments are parsed and trim the whitespace.
      return arg.replace(/\/\*.*\*\//, '').trim();
    })
    .filter(arg => {
      // Ensure no undefined values are added.
      return arg;
    });
};

export const addToken = async (asset = 'fai', decimal, type) => {
  let tokenAddress = '';
  let tokenSymbol = '';
  let tokenDecimals = 18;
  let tokenImage = '';
  if (asset === 'fai') {
    tokenAddress = getFaiTokenAddress();
    tokenSymbol = 'FAI';
    tokenDecimals = 18;
    tokenImage = `${window.location.origin}/coins/fai.svg`;
  } else if (asset === 'fts') {
    if (type === "token") {
      tokenAddress = constants.CONTRACT_FTS_TOKEN_ADDRESS;
      tokenSymbol = 'FTS';
      tokenDecimals = 18;
      tokenImage = `${window.location.origin}/coins/fts.svg`;
    } else {
      tokenAddress = constants.CONTRACT_FBEP_ADDRESS['fts'].address;
      tokenSymbol = 'fFTS';
      tokenDecimals = 18;
      tokenImage = `${window.location.origin}/coins/ffts.svg`;      
    }
  } else {
    tokenAddress =
      type === 'token'
        ? constants.CONTRACT_TOKEN_ADDRESS[asset].address
        : constants.CONTRACT_FBEP_ADDRESS[asset].address;
    if (asset === 'cake-lp-fts-bnb-v2' || asset === 'cake-lp') {
      tokenSymbol = type === 'token' ? 'FTS-BNB-LP' : 'fFTS-BNB-LP';
    } else {
      tokenSymbol =
        type === 'token'
          ? asset.toUpperCase()
          : `f${(asset === 'btcb' ? 'btc' : asset).toUpperCase()}`;
    }
    tokenDecimals = decimal || (type === 'token' ? 18 : 8);
    tokenImage = `${window.location.origin}/coins/${
      type === 'token' ? asset : `f${asset === 'btcb' ? 'btc' : asset}`
    }.${type === 'token' ? 'png' : 'svg'}`;
  }

  try {
    // wasAdded is a boolean. Like any RPC method, an error may be thrown.
    const wasAdded = await window.ethereum.request({
      method: 'wallet_watchAsset',
      params: {
        type: 'ERC20', // Initially only supports ERC20, but eventually more!
        options: {
          address: tokenAddress, // The address that the token is at.
          symbol: tokenSymbol, // A ticker symbol or shorthand, up to 5 chars.
          decimals: tokenDecimals, // The number of decimals in the token
          image: tokenImage // A string url of the token logo
        }
      }
    });

    if (wasAdded) {
      console.log('Thanks for your interest!');
    } else {
      console.log('Your loss!');
    }
  } catch (error) {
    console.log(error);
  }
};

export const getBigNumber = value => {
  if (!value) {
    return new BigNumber(0);
  }
  if (BigNumber.isBigNumber(value)) {
    return value;
  }
  return new BigNumber(value);
};

export const currencyFormatter = labelValue => {
  // Nine Zeroes for Billions
  return Math.abs(Number(labelValue)) >= 1.0e9
    ? `$${format(
        new BigNumber(`${Math.abs(Number(labelValue)) / 1.0e9}`).dp(2, 1)
      )}B`
    : // Six Zeroes for Millions
    Math.abs(Number(labelValue)) >= 1.0e6
    ? `$${format(
        new BigNumber(`${Math.abs(Number(labelValue)) / 1.0e6}`).dp(2, 1)
      )}M`
    : // Three Zeroes for Thousands
    Math.abs(Number(labelValue)) >= 1.0e3
    ? `$${format(
        new BigNumber(`${Math.abs(Number(labelValue)) / 1.0e3}`).dp(2, 1)
      )}K`
    : `$${format(new BigNumber(`${Math.abs(Number(labelValue))}`).dp(2, 1))}`;
};

export const percentFormatter = value => {
  const val = getBigNumber(value);
  if (val.isZero()) {
    return 0;
  } else if (val.abs().isLessThan(0.01)) {
    return 0.01;
  } else if (val.gt(1e8)) {
    const length = val
      .dp(0)
      .toString(10)
      .split('.')[0].length;
    return `${val
      .div(Math.pow(10, length - 1))
      .toNumber()
      .toFixed(2)}e+${length - 1}`;
  }
  return val.dp(2, 1).toNumber();
};
