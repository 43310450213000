import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import styled from 'styled-components';
import { bindActionCreators } from 'redux';
import { connectAccount, accountActionCreators } from 'core';
import SupplyMarket from 'components/Dashboard/Market/SupplyMarket';
import BorrowMarket from 'components/Dashboard/Market/BorrowMarket';
import { Card } from 'components/Basic/Card';
import MintTab from 'components/Basic/FaiTabs/MintTab';
import RepayFaiTab from 'components/Basic/FaiTabs/RepayFaiTab';
import { getBigNumber } from 'utilities/common';
import { useMarketsUser } from '../../hooks/useMarketsUser';

const CardWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 5px;
  background-color: var(--color-bg-primary);
`;

const Tabs = styled.div`
  display: flex;
  align-items: center;
  .tab-item {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    width: 199px;
    color: var(--color-text-inactive);
    font-size: 16px;
    padding: 20px 0;
    border-bottom: var(--border-style);

    @media only screen and (max-width: 480px) {
      font-size: 14px;
    }
  }
  .tab-active {
    border-radius: 3px;
    font-weight: 400;
    color: var(--color-yellow);
    border-bottom: 0.5px solid var(--color-yellow);
  }
`;

const TabContent = styled.div`
  width: 100%;
  height: calc(100% - 75px);
  display: flex;
  justify-content: center;
  padding: 15px 14px;
`;

const MintRepayFai = styled.div`
  margin-top: 30px;
  @media only screen and (max-width: 1300px) {
    flex-direction: column;
    justify-content: flex-start;
  }
`;

const Market = ({ setSetting }) => {
  const [currentTab, setCurrentTab] = useState('supply');
  const [suppliedAssets, setSuppliedAssets] = useState([]);
  const [nonSuppliedAssets, setNonSuppliedAssets] = useState([]);
  const [borrowedAssets, setBorrowedAssets] = useState([]);
  const [nonBorrowedAssets, setNonBorrowedAssets] = useState([]);
  const { userMarketInfo } = useMarketsUser();

  const updateMarketTable = async () => {
    const tempArr = [];
    userMarketInfo.forEach(item => {
      const temp = {
        ...item,
        supplyApy: getBigNumber(item.supplyApy),
        borrowApy: getBigNumber(item.borrowApy),
        walletBalance: getBigNumber(item.walletBalance),
        supplyBalance: getBigNumber(item.supplyBalance),
        vTokenBalance: getBigNumber(item.vTokenBalance),
        borrowBalance: getBigNumber(item.borrowBalance),
        collateralFactor: getBigNumber(item.collateralFactor),
        underlyingPriceUSD: getBigNumber(item.underlyingPriceUSD),
        liquidity: getBigNumber(item.liquidity)
      };
      tempArr.push(temp);
    });

    const tempSuppliedData = [];
    const tempNonSuppliableData = [];
    const tempBorrowedData = [];
    const tempNonBorrowedData = [];
    tempArr.forEach(element => {
      if (element.supplyBalance.isZero()) {
        tempNonSuppliableData.push(element);
      } else {
        tempSuppliedData.push(element);
      }

      if (['fts', 'cake-lp-fts-bnb-v2', 'cake-lp'].includes(element.id)) {
        return;
      }
      if (element.borrowBalance.isZero()) {
        tempNonBorrowedData.push(element);
      } else {
        tempBorrowedData.push(element);
      }
    });
    setSuppliedAssets([...tempSuppliedData]);
    setNonSuppliedAssets([...tempNonSuppliableData]);
    setBorrowedAssets([...tempBorrowedData]);
    setNonBorrowedAssets([...tempNonBorrowedData]);
  };

  useEffect(() => {
    if (
      userMarketInfo &&
      userMarketInfo.length > 0 &&
      userMarketInfo.filter(s => !s).length === 0
    ) {
      updateMarketTable();
    }
  }, [userMarketInfo]);

  useEffect(() => {
    if (currentTab !== 'fai') {
      setSetting({ marketType: currentTab });
    }
  }, [currentTab, setSetting]);

  return (
    <Card>
      <CardWrapper>
        <Tabs>
          <div
            className={`tab-item center ${
              currentTab === 'supply' ? 'tab-active' : ''
            }`}
            onClick={() => {
              setCurrentTab('supply');
            }}
          >
            Supply Market
          </div>
          <div
            className={`tab-item center ${
              currentTab === 'borrow' ? 'tab-active' : ''
            }`}
            onClick={() => {
              setCurrentTab('borrow');
            }}
          >
            Borrow Market
          </div>
          <div
            className={`tab-item center ${
              currentTab === 'fai' ? 'tab-active' : ''
            }`}
            onClick={() => {
              setCurrentTab('fai');
            }}
          >
            Mint / Repay FAI
          </div>
        </Tabs>
        <TabContent>
          {currentTab === 'supply' && (
            <SupplyMarket
              suppliedAssets={suppliedAssets}
              remainAssets={nonSuppliedAssets}
            />
          )}
          {currentTab === 'borrow' && (
            <BorrowMarket
              borrowedAssets={borrowedAssets}
              remainAssets={nonBorrowedAssets}
            />
          )}
          {currentTab === 'fai' && (
            <MintRepayFai className="flex align-center">
              <MintTab />
              <RepayFaiTab />
            </MintRepayFai>
          )}
        </TabContent>
      </CardWrapper>
    </Card>
  );
};

Market.propTypes = {
  setSetting: PropTypes.func.isRequired
};

const mapDispatchToProps = dispatch => {
  const { setSetting } = accountActionCreators;

  return bindActionCreators(
    {
      setSetting
    },
    dispatch
  );
};

export default compose(connectAccount(undefined, mapDispatchToProps))(Market);
