/* eslint-disable no-useless-escape */
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import MainLayout from 'containers/Layout/MainLayout';
import CoinInfo from 'components/Dashboard/CoinInfo';
import BorrowLimit from 'components/Dashboard/BorrowLimit';
import Overview from 'components/Dashboard/Overview';
import WalletBalance from 'components/Dashboard/WalletBalance';
import Market from 'components/Dashboard/Market';
import LoadingSpinner from 'components/Basic/LoadingSpinner';
import { Row, Column } from 'components/Basic/Style';
import { methods } from 'utilities/ContractService';
import BigNumber from 'bignumber.js';
import * as constants from 'utilities/constants';
import Claim from 'components/Dashboard/Claim';
import { useComptroller } from '../../hooks/useContract';
import { getFbepContract } from '../../utilities/contractHelpers';
import useWeb3 from '../../hooks/useWeb3';
import { useWeb3React } from '@web3-react/core';

const DashboardWrapper = styled.div`
  height: 100%;

  .wallet-balance {
    height: 246.59px;
    margin-bottom: 20px;
    @media only screen and (max-width: 768px) {
      height: auto;
    }
  }
`;

const SpinnerWrapper = styled.div`
  height: 85vh;
  width: 100%;

  @media only screen and (max-width: 1440px) {
    height: 70vh;
  }
`;

function Dashboard() {
  const [earnedBalance, setEarnedBalance] = useState('0.00000000');
  const comptrollerContract = useComptroller();
  const web3 = useWeb3();
  const { account } = useWeb3React();

  const getVoteInfo = async () => {
    if (!account) return;
    try {
      const fortressInitialIndex = await methods.call(
        comptrollerContract.methods.fortressInitialIndex,
        []
      );
      let fortressEarned = new BigNumber(0);
      for (
        let index = 0;
        index < Object.values(constants.CONTRACT_FBEP_ADDRESS).length;
        index += 1
      ) {
        const item = Object.values(constants.CONTRACT_FBEP_ADDRESS)[index];

        const fbepContract = getFbepContract(web3, item.id);
        const supplyState = await methods.call(
          comptrollerContract.methods.fortressSupplyState,
          [item.address]
        );
        const supplyIndex = supplyState.index;
        let supplierIndex = await methods.call(
          comptrollerContract.methods.fortressSupplierIndex,
          [item.address, account]
        );
        if (+supplierIndex === 0 && +supplyIndex > 0) {
          supplierIndex = fortressInitialIndex;
        }
        let deltaIndex = new BigNumber(supplyIndex).minus(supplierIndex);

        const supplierTokens = await methods.call(
          fbepContract.methods.balanceOf,
          [account]
        );
        const supplierDelta = new BigNumber(supplierTokens)
          .multipliedBy(deltaIndex)
          .dividedBy(1e36);

        fortressEarned = fortressEarned.plus(supplierDelta);

        const borrowState = await methods.call(
          comptrollerContract.methods.fortressBorrowState,
          [item.address]
        );
        let borrowIndex = borrowState.index;
        const borrowerIndex = await methods.call(
          comptrollerContract.methods.fortressBorrowerIndex,
          [item.address, account]
        );
        if (+borrowerIndex > 0) {
          deltaIndex = new BigNumber(borrowIndex).minus(borrowerIndex);
          const borrowBalanceStored = await methods.call(
            fbepContract.methods.borrowBalanceStored,
            [account]
          );
          borrowIndex = await methods.call(
            fbepContract.methods.borrowIndex,
            []
          );
          const borrowerAmount = new BigNumber(borrowBalanceStored)
            .multipliedBy(1e18)
            .dividedBy(borrowIndex);
          const borrowerDelta = borrowerAmount
            .times(deltaIndex)
            .dividedBy(1e36);
          fortressEarned = fortressEarned.plus(borrowerDelta);
        }
      }

      const fortressAccrued = await methods.call(
        comptrollerContract.methods.fortressAccrued,
        [account]
      );
      fortressEarned = fortressEarned
        .plus(fortressAccrued)
        .dividedBy(1e18)
        .dp(8, 1)
        .toString(10);
      setEarnedBalance(
        fortressEarned && fortressEarned !== '0'
          ? `${fortressEarned}`
          : '0.00000000'
      );
    } catch (error) {
      console.log('error :>> ', error);
    }
  };

  useEffect(() => {
    getVoteInfo();
  }, [account]);

  return (
    <MainLayout title="Dashboard">
      <DashboardWrapper className="flex">
        {!account && (
          <SpinnerWrapper>
            <LoadingSpinner />
          </SpinnerWrapper>
        )}
        {account && (
          <Row>
            <Column xs="12" sm="12" md="5">
              <Row>
                <Column xs="12">
                  <CoinInfo />
                </Column>
                <Column xs="12">
                  <Claim earnedBalance={earnedBalance} />
                </Column>
                <Column xs="12">
                  <BorrowLimit />
                </Column>
                <Column xs="12">
                  <Overview />
                </Column>
              </Row>
            </Column>
            <Column xs="12" sm="12" md="7">
              <Row>
                <Column className="wallet-balance" xs="12">
                  <WalletBalance />
                </Column>
                <Column xs="12">
                  <Market />
                </Column>
              </Row>
            </Column>
          </Row>
        )}
      </DashboardWrapper>
    </MainLayout>
  );
}

export default Dashboard;
