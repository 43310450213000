import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import BigNumber from 'bignumber.js';
import { compose } from 'recompose';
import Button from '@material-ui/core/Button';
import NumberFormat from 'react-number-format';
import { connectAccount } from 'core';
import { methods } from 'utilities/ContractService';
import commaNumber from 'comma-number';
import faiImg from 'assets/img/coins/fai.svg';
import { TabSection, TabContent } from 'components/Basic/SupplyModal';
import { getBigNumber } from 'utilities/common';
import { useFaiUnitroller } from '../../../hooks/useContract';
import { useMarketsUser } from '../../../hooks/useMarketsUser';
import { useWeb3React } from '@web3-react/core';

const format = commaNumber.bindWith(',', '.');

function MintTab() {
  const [isLoading, setIsLoading] = useState(false);
  const [amount, setAmount] = useState(new BigNumber(0));
  const [mintableFai, setMintableFai] = useState(new BigNumber(0));
  const appContract = useFaiUnitroller();
  const { userFaiBalance } = useMarketsUser();
  const { account } = useWeb3React();
  const faiControllerContract = useFaiUnitroller();
  const { userTotalBorrowBalance, userTotalBorrowLimit } = useMarketsUser();

  const getMintableFai = async () => {
    // mintable fai amount
    const {
      1: mintableAmount
    } = await faiControllerContract.methods.getMintableFAI(account).call();
    setMintableFai(new BigNumber(mintableAmount).div(1e18));
  };

  useEffect(() => {
    if (account) {
      getMintableFai();
    }
  }, [account]);

  /**
   * Max amount
   */
  const handleMaxAmount = () => {
    const safeMax = BigNumber.maximum(
      userTotalBorrowLimit
        .times(40)
        .div(100)
        .minus(userTotalBorrowBalance),
      new BigNumber(0)
    );
    setAmount(BigNumber.minimum(mintableFai, safeMax));
  };
  /**
   * Mint
   */
  const handleMintFAI = () => {
    if (account) {
      setIsLoading(true);
      methods
        .send(
          appContract.methods.mintFAI,
          [
            amount
              .times(1e18)
              .integerValue()
              .toString(10)
          ],
          account
        )
        .then(res => {
          setAmount(new BigNumber(0));
          setIsLoading(false);
        })
        .catch(err => {
          setIsLoading(false);
        });
    }
  };

  return (
    <TabSection>
      <div className="flex flex-column align-center just-center body-content mint-fai-content">
        <div className="flex align-center input-wrapper">
          <NumberFormat
            autoFocus
            value={amount.isZero() ? '0' : amount.toString(10)}
            onValueChange={({ value }) => {
              setAmount(new BigNumber(value));
            }}
            isAllowed={({ value }) => {
              return new BigNumber(value || 0).isLessThanOrEqualTo(mintableFai);
            }}
            thousandSeparator
            allowNegative={false}
            placeholder="0"
          />
          <span className="pointer max" onClick={() => handleMaxAmount()}>
            SAFE MAX
          </span>
        </div>
      </div>
      <TabContent className="flex flex-column align-center just-content fai-content-section">
        <div className="flex flex-column just-center align-center apy-content">
          <div className="description">
            <div className="flex align-center">
              <img className="asset-img" src={faiImg} alt="asset" />
              <div className="fai-balance">
                <span>Available</span>
                <span>FAI Limit</span>
              </div>
            </div>
            <span>{format(mintableFai.dp(2, 1).toString(10))} FAI</span>
          </div>
        </div>
        <Button className="button fai-auto" disabled={true}>
          Mint FAI
        </Button>
        <div className="description">
          <span>FAI Balance</span>
          <span>{format(userFaiBalance.dp(2, 1).toString(10))} FAI</span>
        </div>
      </TabContent>
    </TabSection>
  );
}

export default MintTab;
