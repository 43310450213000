import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import commaNumber from 'comma-number';
import { Card } from 'components/Basic/Card';
import ffts from 'assets/img/coins/ffts.svg';
import fts from 'assets/img/fts.svg';
import BigNumber from 'bignumber.js';

const UserInfoWrapper = styled.div`
  width: 100%;
  height: 520px;
  border-radius: 5px;
  background-color: var(--color-bg-primary);
  display: flex;
  flex-direction: column;
  padding: 20px 40px;

  @media (max-width: 992px) {
    padding: 20px;
    height: 100%;
  }

  .total-item {
    margin: 20px 0;
    @media (max-width: 992px) {
      margin: 10px;
    }

    .prop {
      font-weight: 400;
      font-size: 20px;
      color: var(--color-text-secondary);
    }

    .value {
      font-weight: 400;
      font-size: 24px;
      color: var(--color-white);
      margin-top: 10px;

      img {
        width: 24px;
        margin-right: 10px;
      }

      .claim-btn {
        font-size: 18px;
        font-weight: bold;
        color: var(--color-orange);
        margin-left: 10px;
      }
      .disable-btn {
        color: var(--color-text-secondary);
      }
    }
  }
`;

const format = commaNumber.bindWith(',', '.');

function UserInfo(props) {
  const { stakedAmount, apr, totalAmount, periodFinish, isFFts = true } = props;
  const decimals = isFFts ? 8 : 18;

  return (
    <Card>
      <UserInfoWrapper>
        <div className="total-item">
          <div className="prop">Vault TVL</div>
          <div className="flex align-center just-between value">
            <p>
              <img src={isFFts ? ffts : fts} alt="fts" />
              {format(
                totalAmount
                  .div(new BigNumber(10).pow(decimals))
                  .dp(4, 1)
                  .toString(10)
              )}
              &nbsp;{isFFts ? 'fFTS' : 'FTS'}
            </p>
          </div>
        </div>
        <div className="total-item">
          <div className="prop">APR</div>
          <div className="flex align-center value">
            {format(parseFloat(apr).toFixed(2))} %
          </div>
        </div>
        <div className="total-item">
          <div className="prop">Your stake</div>
          <div className="flex align-center value">
            <img src={isFFts ? ffts : fts} alt="fts" />
            {format(
              stakedAmount
                .div(new BigNumber(10).pow(decimals))
                .dp(4, 1)
                .toString(10)
            )}
            &nbsp;{isFFts ? 'fFTS' : 'FTS'}
          </div>
        </div>
        <div className="total-item">
          <div className="prop">Vault End Timestamp</div>
          <div className="flex align-center just-between value">
            <p>
              {periodFinish && (
                <a
                  href={`https://timestamp.online/countdown/${periodFinish}`}
                  target="_blank"
                  rel="noreferrer"
                  style={{ color: 'white' }}
                >
                  {periodFinish}
                </a>
              )}
            </p>
          </div>
        </div>
      </UserInfoWrapper>
    </Card>
  );
}

UserInfo.propTypes = {};

UserInfo.defaultProps = {};

UserInfo.propTypes = {
  stakedAmount: PropTypes.object.isRequired,
  apr: PropTypes.object.isRequired,
  totalAmount: PropTypes.string.isRequired,
  periodFinish: PropTypes.string.isRequired,
  isFFts: PropTypes.bool.isRequired
};

export default UserInfo;
