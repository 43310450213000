import React, { useEffect, useState, useCallback } from 'react';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';
import MainLayout from 'containers/Layout/MainLayout';
import { Select } from 'antd';
import * as constants from 'utilities/constants';
import { compose } from 'recompose';
import { connectAccount, accountActionCreators } from 'core';
import { promisify } from 'utilities';
import BigNumber from 'bignumber.js';
import NumberFormat from 'react-number-format';
import Button from '@material-ui/core/Button';
import LoadingSpinner from 'components/Basic/LoadingSpinner';
import { methods } from 'utilities/ContractService';
import useWeb3 from '../../hooks/useWeb3';
import {
  getFbepContract,
  getTokenContract
} from '../../utilities/contractHelpers';
import { useWeb3React } from '@web3-react/core';

const LiquidatorWrapper = styled.div`
  width: 100%;
  max-width: 1200px;
  margin: auto;
  padding: 20px 0 20px 0;
  @media only screen and (max-width: 768px) {
    padding: 29px 15px 10px 15px;
  }

  .text-yellow {
    color: #f7c408;
  }

  .text-grey {
    color: #ffffff80;
  }

  .text-green {
    color: #42cf50;
  }

  .text-red {
    color: #d41c1c;
  }

  .header-section {
    background: #1b1b1b;
    border-radius: 20px;
    padding: 24px 32px;
    font-size: 18px;

    .search-title {
      color: #7e7e7e;
      font-size: 18px;
      margin-bottom: 12px;
    }

    .search-input {
      background-color: #313131;
      border: 1px solid #f7c408;
      box-sizing: border-box;
      border-radius: 20px;
      width: 100%;
      padding: 20px 24px;
      display: flex;
      justify-content: space-between;

      input {
        background-color: #313131;
        color: var(--color-text-main);
        border: 0px;
        width: 100%;
        &:focus-visible {
          outline: none;
        }
      }

      span {
        color: #f7c408;
        cursor: pointer;
      }
    }

    .search-footer {
      display: flex;
      justify-content: space-between;
      flex-direction: row-reverse;
      margin-top: 16px;
      font-size: 16px;

      .search-footer-right {
        color: white;
        cursor: pointer;
      }
    }
  }

  .body-section {
    margin-top: 24px;
    display: flex;

    @media only screen and (max-width: 768px) {
      flex-direction: column;
    }

    .body-left {
      width: 30%;
      background: #1b1b1b;
      border-radius: 20px;
      padding: 25px;

      @media only screen and (max-width: 768px) {
        width: 100%;
      }

      .body-health {
        display: flex;
        justify-content: space-between;
        padding-bottom: 20px;
        border-bottom: 1px solid #3d3d3d;

        .health-label {
          color: white;
        }
      }

      .body-asset {
        padding: 10px 0;
        border-bottom: 1px solid #3d3d3d;

        .body-asset-part {
          display: flex;
          justify-content: space-between;
          padding: 10px 0;

          .asset-label {
            color: white;
          }

          .asset-select-section {
            position: relative;

            .asset-img {
              width: 20px;
            }
          }
        }
      }

      .body-max {
        padding-top: 10px;

        .body-max-part {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 10px 0;

          .max-label {
            color: white;
          }

          .max-value {
            color: #f7c408;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;

            .max-value-asset {
              color: #f7c408;
              text-align: right;
            }

            .max-value-usd {
              color: #7e7e7e;
              text-align: right;
            }
          }
        }
      }
    }

    .body-right {
      flex: 1;
      margin-left: 24px;
      background: #1b1b1b;
      border-radius: 20px;
      padding: 16px;

      @media only screen and (max-width: 768px) {
        margin-left: 0px;
        margin-top: 15px;
      }

      .connect-wallet {
        height: 100%;
        border: 1px solid #303030;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 16px;

        p {
          text-align: center;
          color: white;
          max-width: 180px;
        }
      }

      .connected-container {
        padding: 16px;
        display: flex;
        flex-direction: column;
        height: 100%;

        .action-header {
          color: #ffffff;
        }

        .input-wrapper {
          position: relative;
          background: rgba(81, 81, 81, 0.2);
          border-radius: 20px;
          padding: 15px;
          width: 100%;
          margin-top: 16px;

          input {
            width: 85%;
            border: none;
            height: 100%;
            font-size: 18px;
            color: var(--color-text-main);
            text-align: left;
            background: transparent;
            &:focus {
              outline: none;
            }
          }
          .max {
            position: absolute;
            right: 25px;
            width: 12%;
            text-align: center;
            font-weight: 400;
            font-size: 18px;
            color: var(--color-yellow);
          }
        }

        .input-footer {
          margin-top: 16px;
          color: rgba(255, 255, 255, 0.5);

          span {
            margin-left: 16px;
          }
        }

        .liquidate-desc {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-top: 50px;
          color: white;

          @media only screen and (max-width: 768px) {
            flex-direction: column;
          }

          .liquidate-btn {
            width: 145px;
            height: 50px;
            border-radius: 10px;
            background-color: var(--color-yellow);

            @media only screen and (max-width: 768px) {
              margin-top: 15px;
            }
            .MuiButton-label {
              font-size: 16px;
              font-weight: bold;
              color: var(--color-black);
              text-transform: capitalize;

              @media only screen and (max-width: 768px) {
                font-size: 12px;
                color: #c4c4c4;
              }
            }
          }
        }

        .footer-desc {
          margin-top: auto;

          @media only screen and (max-width: 768px) {
            margin-top: 30px;
          }
        }
      }
    }
  }

  .table-section {
    margin-top: 24px;
    background: #1b1b1b;
    border-radius: 20px;
    padding: 32px;
    color: white;
    width: 100%;
    overflow-x: auto;

    table {
      width: 100%;
    }

    .table-header {
      color: rgba(255, 255, 255, 0.5);
      display: flex;
      padding: 10px 0;
    }

    .table-body {
      .table-row {
        display: flex;
        align-items: center;
        padding: 10px 0;
        cursor: pointer;
      }
    }

    th {
      display: flex;
      align-items: center;
    }

    .health-cell,
    .account-cell,
    .repay-cell,
    .maxrepay-cell,
    .seize-cell,
    .maxseize-cell {
      padding: 5px 10px;
    }

    .health-cell {
      flex: 1;
      display: flex;
      justify-content: center;
    }

    .account-cell {
      flex: 3;
      display: flex;
      justify-content: flex-start;
    }
    .repay-cell {
      flex: 2;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        margin-right: 8px;
        width: 25px;
      }
    }
    .maxrepay-cell {
      flex: 2;
      display: flex;
      justify-content: flex-end;
    }
    .seize-cell {
      flex: 2;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        margin-right: 8px;
        width: 25px;
      }
    }
    .maxseize-cell {
      flex: 2;
      display: flex;
      justify-content: flex-end;
    }
  }
`;

const { Option } = Select;

function Liquidator({
  settings,
  getLiquidatorList,
  getLiquidator,
  getLiquidatorDetail,
  getLiquidatorRefresh
}) {
  const [search, setSearch] = useState('');
  const [liquidators, setLiquidators] = useState([]);
  const [amount, setAmount] = useState(new BigNumber(0));
  const [isLoading, setIsLoading] = useState(false);
  const [balance, setBalance] = useState(new BigNumber(0));
  const [decimals, setDecimals] = useState(18);
  const [liquidationInfo, setLiquidationInfo] = useState({});
  const [repayAssets, setRepayAssets] = useState([]);
  const [seizeAssets, setSeizeAssets] = useState([]);
  const [assetToRepay, setAssetToRepay] = useState();
  const [assetToSeize, setAssetToSeize] = useState();
  const [liquidationDetail, setLiquidationDetail] = useState({});
  const [isApproved, setIsApproved] = useState(false);
  const web3 = useWeb3();
  const { account } = useWeb3React();

  const getLiquidatorApi = async address => {
    const res = await promisify(getLiquidator, {
      account: address
    });
    if (!res.status) {
      return;
    }
    setRepayAssets(res.data.repayAssets);
    setSeizeAssets(res.data.seizeAssets);
  };

  const getLiquidatorDetailApi = useCallback(async () => {
    if (!assetToRepay || !assetToSeize || !liquidationInfo.account) {
      setLiquidationDetail({});
      return;
    }
    const res = await promisify(getLiquidatorDetail, {
      account: liquidationInfo.account,
      repayAsset: assetToRepay,
      seizeAsset: assetToSeize
    });
    if (!res.status) {
      return;
    }
    setLiquidationDetail(res.data);
  }, [assetToRepay, assetToSeize]);

  const getLiquidatorListApi = async () => {
    const res = await promisify(getLiquidatorList, {});
    if (!res.status) {
      return;
    }
    setLiquidators(res.data.result);
  };

  useEffect(() => {
    getLiquidatorListApi();
  }, []);

  useEffect(() => {
    if (liquidationInfo && liquidationInfo.account) {
      getLiquidatorApi(liquidationInfo.account);
      setAssetToRepay(liquidationInfo.assetToRepay);
      setAssetToSeize(liquidationInfo.assetToSeize);
    } else {
      setLiquidationDetail({});
      setAssetToRepay(null);
      setAssetToSeize(null);
    }
  }, [liquidationInfo]);

  useEffect(() => {
    getLiquidatorDetailApi();
  }, [getLiquidatorDetailApi]);

  const getWalletBalance = async name => {
    const tokenContract = getTokenContract(web3, name);
    if (name === 'bnb') {
      const bal = await web3.eth.getBalance(account);
      setBalance(new BigNumber(bal).div(1e18));
      setDecimals(18);
      setIsApproved(true);
    } else {
      const [bal, dec, allow] = await Promise.all([
        methods.call(tokenContract.methods.balanceOf, [account]),
        methods.call(tokenContract.methods.decimals, []),
        methods.call(tokenContract.methods.allowance, [
          account,
          constants.CONTRACT_FBEP_ADDRESS[name].address
        ])
      ]);
      setBalance(new BigNumber(bal).div(new BigNumber(10).pow(dec)));
      setDecimals(dec);
      setIsApproved(new BigNumber(allow).gt(0));
    }
  };

  useEffect(() => {
    if (assetToRepay && account) {
      getWalletBalance(assetToRepay.toLowerCase());
    }
  }, [assetToRepay, account]);

  /**
   * Borrow
   */
  const handleLiquidate = async () => {
    setIsLoading(true);
    const fbepContract = getFbepContract(web3, assetToRepay.toLowerCase());
    try {
      if (assetToRepay === 'BNB') {
        await fbepContract.methods
          .liquidateBorrow(
            liquidationInfo.account,
            constants.CONTRACT_FBEP_ADDRESS[assetToSeize.toLowerCase()].address
          )
          .send({
            from: account,
            value: amount
              .times(new BigNumber(10).pow(decimals))
              .dp(0)
              .toString(10)
          });
      } else {
        await methods.send(
          fbepContract.methods.liquidateBorrow,
          [
            liquidationInfo.account,
            amount
              .times(new BigNumber(10).pow(decimals))
              .dp(0)
              .toString(10),
            constants.CONTRACT_FBEP_ADDRESS[assetToSeize.toLowerCase()].address
          ],
          account
        );
      }
      setAmount(new BigNumber(0));
      setIsLoading(false);

      const res = await promisify(getLiquidatorRefresh, {
        account: liquidationInfo.account
      });
      getLiquidatorListApi();
      setLiquidationInfo(null);
    } catch (error) {
      console.log('liquidate error :>> ', error);
      setIsLoading(false);
    }
  };

  const handleApprove = () => {
    setIsLoading(true);
    const tokenContract = getTokenContract(web3, assetToRepay.toLowerCase());
    methods
      .send(
        tokenContract.methods.approve,
        [
          constants.CONTRACT_FBEP_ADDRESS[assetToRepay.toLowerCase()].address,
          new BigNumber(2)
            .pow(256)
            .minus(1)
            .toString(10)
        ],
        account
      )
      .then(res => {
        setAmount(new BigNumber(0));
        setIsLoading(false);
      })
      .catch(e => {
        console.log('approve error :>> ', e);
        setIsLoading(false);
      });
  };

  const handleSearch = () => {
    const ele = liquidators.find(
      item => item.account.toLowerCase() === search.toLowerCase()
    );
    if (ele) {
      setLiquidationInfo(ele);
    } else {
      setLiquidationInfo(null);
    }
  };

  return (
    <MainLayout title="Liquidator">
      <LiquidatorWrapper>
        <div className="header-section">
          <div className="search-title">Search address to liquidate</div>
          <div className="search-input">
            <input
              value={search}
              onChange={e => {
                setSearch(e.target.value);
              }}
            />
            <span onClick={() => handleSearch()}>Search</span>
          </div>
          <div className="search-footer">
            <div
              className="search-footer-right"
              onClick={() => getLiquidatorDetailApi()}
            >
              Refresh
            </div>
            {liquidationDetail.healthFactor && (
              <div
                className={
                  Number(liquidationDetail.healthFactor) < 1
                    ? 'text-green'
                    : 'text-red'
                }
              >
                {Number(liquidationDetail.healthFactor) < 1
                  ? 'This account can be liquidated'
                  : 'This account is healthy and can not be liquidated'}
              </div>
            )}
          </div>
        </div>
        <div className="body-section">
          <div className="body-left">
            <div className="body-health">
              <div className="health-label">Account Health</div>
              <div
                className={
                  Number(liquidationDetail.healthFactor) < 1
                    ? 'text-red'
                    : 'text-green'
                }
              >
                {liquidationDetail.healthFactor &&
                  new BigNumber(liquidationDetail.healthFactor).toFormat(4)}
              </div>
            </div>
            <div className="body-asset">
              <div className="body-asset-part">
                <div className="asset-label">Asset to Repay</div>
                {repayAssets && repayAssets.length > 0 && assetToRepay && (
                  <div className="asset-select-section" id="asset-to-repay">
                    <Select
                      defaultValue={assetToRepay}
                      value={assetToRepay}
                      style={{ width: 120 }}
                      getPopupContainer={() =>
                        document.getElementById('asset-to-repay')
                      }
                      dropdownMenuStyle={{
                        backgroundColor: '#141414'
                      }}
                      dropdownClassName="asset-select"
                      onChange={val => {
                        setAssetToRepay(val);
                      }}
                    >
                      {repayAssets.map((key, index) => (
                        <Option
                          className="flex align-center just-between"
                          value={key}
                          key={index}
                        >
                          <img
                            className="asset-img"
                            src={
                              constants.CONTRACT_TOKEN_ADDRESS[
                                key.toLowerCase()
                              ].asset
                            }
                            alt="asset"
                          />{' '}
                          <span>
                            {
                              constants.CONTRACT_TOKEN_ADDRESS[
                                key.toLowerCase()
                              ].symbol
                            }
                          </span>
                        </Option>
                      ))}
                    </Select>
                  </div>
                )}
              </div>
              <div className="body-asset-part">
                <div className="asset-label">Asset to Seize</div>
                {seizeAssets && seizeAssets.length > 0 && assetToSeize && (
                  <div className="asset-select-section" id="asset-to-seize">
                    <Select
                      defaultValue={assetToSeize}
                      value={assetToSeize}
                      style={{ width: 120 }}
                      getPopupContainer={() =>
                        document.getElementById('asset-to-seize')
                      }
                      dropdownMenuStyle={{
                        backgroundColor: '#141414'
                      }}
                      dropdownClassName="asset-select"
                      onChange={val => {
                        setAssetToSeize(val);
                      }}
                    >
                      {seizeAssets.map((key, index) => (
                        <Option
                          className="flex align-center just-between"
                          value={key}
                          key={index}
                        >
                          <img
                            className="asset-img"
                            src={
                              constants.CONTRACT_TOKEN_ADDRESS[
                                key.toLowerCase()
                              ].asset
                            }
                            alt="asset"
                          />{' '}
                          <span>
                            {
                              constants.CONTRACT_TOKEN_ADDRESS[
                                key.toLowerCase()
                              ].symbol
                            }
                          </span>
                        </Option>
                      ))}
                    </Select>
                  </div>
                )}
              </div>
            </div>
            <div className="body-max">
              <div className="body-max-part">
                <div className="max-label">Max Repay Amount</div>
                {liquidationDetail.repayAmount && (
                  <div className="max-value">
                    <div className="max-value-asset">
                      {new BigNumber(liquidationDetail.repayAmount).toFormat(2)}{' '}
                      {liquidationDetail.repayAsset}
                    </div>
                    <div className="max-value-usd">
                      $
                      {new BigNumber(liquidationDetail.repayAmount)
                        .times(liquidationDetail.repayAssetPrice)
                        .toFormat(2)}
                    </div>
                  </div>
                )}
              </div>
              <div className="body-max-part">
                <div className="max-label">Max Seize Amount</div>
                {liquidationDetail.seizeAmount && (
                  <div className="max-value">
                    <div className="max-value-asset">
                      {new BigNumber(liquidationDetail.seizeAmount).toFormat(2)}{' '}
                      {liquidationDetail.seizeAsset}
                    </div>
                    <div className="max-value-usd">
                      $
                      {new BigNumber(liquidationDetail.seizeAmount)
                        .times(liquidationDetail.seizeAssetPrice)
                        .toFormat(2)}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="body-right">
            {account ? (
              liquidationDetail.repayAsset ? (
                <div className="connected-container">
                  <div className="action-header">
                    Amount you want to repay in{' '}
                    <img
                      src={
                        constants.CONTRACT_TOKEN_ADDRESS[
                          liquidationDetail.repayAsset.toLowerCase()
                        ].asset
                      }
                      width={20}
                    />{' '}
                    {liquidationDetail.repayAsset}
                  </div>
                  <div className="flex align-center input-wrapper">
                    <NumberFormat
                      autoFocus
                      value={amount.isZero() ? '0' : amount.toString(10)}
                      onValueChange={values => {
                        const { value } = values;
                        setAmount(new BigNumber(value));
                      }}
                      thousandSeparator
                      allowNegative={false}
                      placeholder="0"
                    />
                    <span
                      className="pointer max"
                      onClick={() =>
                        setAmount(
                          BigNumber.minimum(
                            balance,
                            liquidationDetail.repayAmount
                          )
                        )
                      }
                    >
                      MAX
                    </span>
                  </div>
                  <div className="input-footer">
                    Wallet Balance:{' '}
                    <span className="text-yellow">
                      {balance.toFormat(2)} {liquidationDetail.repayAsset}
                    </span>
                  </div>
                  <div className="liquidate-desc">
                    <div className="liquidate-desc-left">
                      You will repay{' '}
                      <span className="text-yellow">
                        {amount.toFormat(2)} {assetToRepay}
                      </span>{' '}
                      <span className="text-grey">
                        $
                        {amount
                          .times(liquidationDetail.repayAssetPrice)
                          .toFormat(2)}
                      </span>{' '}
                      and seize{' '}
                      <span className="text-yellow">
                        {new BigNumber(liquidationDetail.seizeAmount)
                          .times(amount)
                          .div(liquidationDetail.repayAmount)
                          .toFormat(2)}{' '}
                        {liquidationDetail.seizeAsset}
                      </span>{' '}
                      <span className="text-grey">
                        $
                        {new BigNumber(liquidationDetail.seizeAmount)
                          .times(amount)
                          .div(liquidationDetail.repayAmount)
                          .times(liquidationDetail.seizeAssetPrice)
                          .toFormat(2)}
                      </span>
                    </div>
                    <div className="liquidate-desc-right">
                      {!isApproved ? (
                        <Button
                          className="liquidate-btn"
                          disabled={isLoading}
                          onClick={handleApprove}
                        >
                          {isLoading ? (
                            <LoadingSpinner size={5} color="#C4C4C4" />
                          ) : (
                            'APPROVE'
                          )}
                        </Button>
                      ) : (
                        <Button
                          className="liquidate-btn"
                          disabled={
                            isLoading ||
                            Number(liquidationDetail.healthFactor) > 1 ||
                            amount.isZero() ||
                            amount.isNaN() ||
                            amount.gt(
                              BigNumber.minimum(
                                balance,
                                liquidationDetail.repayAmount
                              )
                            )
                          }
                          onClick={handleLiquidate}
                        >
                          {isLoading ? (
                            <LoadingSpinner size={5} color="#C4C4C4" />
                          ) : (
                            'LIQUIDATE'
                          )}
                        </Button>
                      )}
                    </div>
                  </div>
                  <div className="footer-desc text-grey">
                    Account health is updated every 30 seconds
                  </div>
                </div>
              ) : (
                <div className="connect-wallet">
                  <p>Select or search an account you want to view</p>
                </div>
              )
            ) : (
              <div className="connect-wallet">
                <p>You Need To Connect Your Wallet</p>
              </div>
            )}
          </div>
        </div>
        <div className="table-section">
          <table>
            <thead className="table-header">
              <th className="health-cell">Health</th>
              <th className="account-cell">Account</th>
              <th className="repay-cell">Asset To Repay</th>
              <th className="maxrepay-cell">Max Repay Amount</th>
              <th className="seize-cell">Asset To Seize</th>
              <th className="maxseize-cell">Max Seize Amount</th>
            </thead>
            <tbody className="table-body">
              {liquidators.map((liquidator, index) => {
                return (
                  <tr
                    className="table-row"
                    key={`liquidator-${index}`}
                    onClick={() => {
                      setLiquidationInfo(liquidator);
                      setSearch(liquidator.account);
                    }}
                  >
                    <td
                      className={`health-cell ${
                        new BigNumber(liquidator.healthFactor).lt(1)
                          ? 'text-red'
                          : 'text-green'
                      }`}
                    >
                      {new BigNumber(liquidator.healthFactor).toFormat(4)}
                    </td>
                    <td className="account-cell">
                      {liquidator.account.slice(0, 6)}...
                      {liquidator.account.slice(-4)}
                    </td>
                    <td className="repay-cell">
                      <img
                        src={
                          constants.CONTRACT_TOKEN_ADDRESS[
                            liquidator.assetToRepay.toLowerCase()
                          ].asset
                        }
                      />
                      {liquidator.assetToRepay}
                    </td>
                    <td className="maxrepay-cell">
                      {new BigNumber(liquidator.maxRepayAmount).toFormat(2)}{' '}
                      {liquidator.assetToRepay}
                    </td>
                    <td className="seize-cell">
                      <img
                        src={
                          constants.CONTRACT_TOKEN_ADDRESS[
                            liquidator.assetToSeize.toLowerCase()
                          ].asset
                        }
                      />
                      {liquidator.assetToSeize}
                    </td>
                    <td className="maxseize-cell">
                      {new BigNumber(liquidator.maxSeizeAmount).toFormat(2)}{' '}
                      {liquidator.assetToSeize}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </LiquidatorWrapper>
    </MainLayout>
  );
}

const mapStateToProps = ({ account }) => ({
  settings: account.setting
});

const mapDispatchToProps = dispatch => {
  const {
    getLiquidatorList,
    getLiquidator,
    getLiquidatorDetail,
    getLiquidatorRefresh
  } = accountActionCreators;

  return bindActionCreators(
    {
      getLiquidatorList,
      getLiquidator,
      getLiquidatorDetail,
      getLiquidatorRefresh
    },
    dispatch
  );
};

export default compose(connectAccount(mapStateToProps, mapDispatchToProps))(
  Liquidator
);
