import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import BigNumber from 'bignumber.js';
import { compose } from 'recompose';
import { bindActionCreators } from 'redux';
import commaNumber from 'comma-number';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Row, Col, Icon, Progress } from 'antd';
import styled from 'styled-components';
import { connectAccount, accountActionCreators } from 'core';
import { methods } from 'utilities/ContractService';
import MainLayout from 'containers/Layout/MainLayout';
import LoadingSpinner from 'components/Basic/LoadingSpinner';
import * as constants from 'utilities/constants';
import coinImg from 'assets/img/fts.svg';
import { getComptrollerAddress } from '../../utilities/addressHelpers';
import { BASE_SCAN_URL } from '../../config';
import { useComptroller, useToken } from '../../hooks/useContract';
import { useMarkets } from '../../hooks/useMarkets';
import { useWeb3React } from '@web3-react/core';

const FTSLayout = styled.div`
  .main-content {
    align-items: center;
  }
`;
const FTSWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const FTSInfoWrapper = styled.div`
  width: 100%;
  padding: 20px 20px 20px 0px;
  max-width: 1200px;

  @media (max-width: 992px) {
    flex-direction: column;
    align-items: flex-start !important;
  }

  @media (max-width: 768px) {
    padding: 0 20px;
  }

  .fts-info {
    img {
      width: 24px;
      height: 24px;
    }

    a {
      margin: 0 10px;
      color: var(--color-white);
      @media (max-width: 768px) {
        font-size: 11px;
        line-height: 11px;
      }
    }

    i {
      color: var(--color-white);
    }
  }

  .distribution-wrapper {
    @media (max-width: 992px) {
      width: 100%;
      align-items: flex-end;
      margin-top: 20px;
    }
    @media (max-width: 768px) {
      align-items: center;
    }
    .info-wrapper {
      @media (max-width: 992px) {
        max-width: 320px;
      }
      .info-item {
        .title {
          color: var(--color-text-secondary);
          font-size: 12px;
        }
        .value {
          color: var(--color-white);
          font-weight: bold;
          font-size: 20px;
        }
        &:not(:last-child) {
          margin-right: 30px;
        }
      }
    }
    .ant-progress {
      @media (max-width: 992px) {
        max-width: 320px;
      }
    }
  }
`;

const TableWrapper = styled.div`
  position: relative;
  width: 100%;
  background: var(--color-bg-primary);
  border: 1px solid var(--color-bg-primary);
  box-sizing: content-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.03);
  border-radius: 8px;
  margin: 20px 0;
  max-width: 1200px;

  @media (max-width: 768px) {
    width: 90%;
  }

  .header-title {
    padding: 20px;
    font-weight: 400;
    font-size: 20px;
    color: var(--color-white);
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  }

  .table_header {
    padding: 20px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    > div {
      color: var(--color-white);
      font-weight: bold;
      cursor: pointer;
      img {
        width: 16px;
        height: 16px;
        margin: 0 10px;
      }
    }
    @media (max-width: 992px) {
      .borrow-apy,
      .per-day,
      .supply-apy,
      .total-distributed {
        display: none;
      }
    }
  }
  .table_content {
    .table_item {
      padding: 20px;
      border-left: 2px solid transparent;
      &:hover {
        background-color: var(--color-bg-active);
        border-left: 2px solid var(--color-orange);
      }
      div {
        color: var(--color-white);
        max-width: 100%;
      }
      .mobile-label {
        display: none;
        @media (max-width: 992px) {
          font-weight: bold;
          display: block;
        }
      }
      .market {
        .highlight {
          word-break: break-all;
          white-space: break-spaces;
        }
        .asset-img {
          width: 30px;
          height: 30px;
          margin-right: 10px;
        }
        .fai-img {
          width: 26px;
          height: 26px;
          margin-right: 14px;
        }
      }
    }
  }
`;

const SpinnerWrapper = styled.div`
  height: 85vh;
  width: 100%;

  @media only screen and (max-width: 1440px) {
    height: 70vh;
  }
`;

const format = commaNumber.bindWith(',', '.');

function FTS({ settings }) {
  const [marketInfos, setMarketInfos] = useState([]);
  const [dailyDistribution, setDailyDistribution] = useState('0');
  const [distributedFts, setDistributedFTS] = useState('0');
  const [remainAmount, setRemainAmount] = useState('0');
  const [sortInfo, setSortInfo] = useState({ field: '', sort: 'desc' });
  const tokenContract = useToken('fts');
  const compContract = useComptroller();
  const { markets, ftsDaily } = useMarkets();
  const { account } = useWeb3React();

  const mintedAmount = '23700000';

  const getFTSInfo = async () => {
    const tempMarkets = [];
    const sum = (markets || []).reduce((accumulator, market) => {
      return new BigNumber(accumulator).plus(
        new BigNumber(market.distributedFts)
      );
    }, 0);

    // total info
    let fortressFAIVaultRate = await methods.call(
      compContract.methods.fortressFAIVaultRate,
      []
    );
    fortressFAIVaultRate = new BigNumber(fortressFAIVaultRate)
      .div(1e18)
      .times(20 * 60 * 24);
    const remainedAmount = await methods.call(tokenContract.methods.balanceOf, [
      getComptrollerAddress()
    ]);
    setDailyDistribution(
      new BigNumber(ftsDaily)
        .div(new BigNumber(10).pow(18))
        .plus(fortressFAIVaultRate)
        .dp(2, 1)
        .toString(10)
    );
    setDistributedFTS(sum.toString(10));
    setRemainAmount(
      new BigNumber(remainedAmount)
        .div(new BigNumber(10).pow(18))
        .dp(2, 1)
        .toString(10)
    );
    Object.values(constants.CONTRACT_TOKEN_ADDRESS).forEach(ele => {
      const item = markets.find(
        market =>
          market.underlyingSymbol.toLowerCase() === ele.symbol.toLowerCase()
      );
      if (item) {
        tempMarkets.push({
          asset: ele.asset,
          underlyingSymbol: item.underlyingSymbol,
          perDay: +new BigNumber(item.dailySupplierFts)
            .plus(new BigNumber(item.dailyBorrowerFts))
            .div(new BigNumber(10).pow(18))
            .dp(2, 1)
            .toString(10),
          supplyAPY: +(new BigNumber(item.ftsSupplyApy).isZero()
            ? '0.00'
            : new BigNumber(item.ftsSupplyApy).isLessThan(0.01)
            ? '0.01'
            : new BigNumber(item.ftsSupplyApy).dp(2, 1).toString(10)),
          borrowAPY: +(new BigNumber(item.ftsBorrowApy).isZero()
            ? '0.00'
            : new BigNumber(item.ftsBorrowApy).isLessThan(0.01)
            ? '0.01'
            : new BigNumber(item.ftsBorrowApy).dp(2, 1).toString(10))
        });
      }
    });
    // for (let i = 0; i < markets.length; i += 1) {
    //   tempMarkets.push({
    //     underlyingSymbol: markets[i].underlyingSymbol,
    //     perDay: +(new BigNumber(markets[i].dailySupplierFts).plus(new BigNumber(markets[i].dailyBorrowerFts)).div(new BigNumber(10).pow(18)).dp(2, 1).toString(10)),
    //     supplyAPY: +(new BigNumber(markets[i].ftsSupplyApy ).isZero() ? '0.00' : new BigNumber(markets[i].ftsSupplyApy ).isLessThan(0.01) ? '0.01' : new BigNumber(markets[i].ftsSupplyApy ).dp(2, 1).toString(10)),
    //     borrowAPY: +(new BigNumber(markets[i].ftsBorrowApy ).isZero() ? '0.00' : new BigNumber(markets[i].ftsBorrowApy ).isLessThan(0.01) ? '0.01' : new BigNumber(markets[i].ftsBorrowApy ).dp(2, 1).toString(10))
    //   });
    // }
    setMarketInfos(tempMarkets);
  };

  useEffect(() => {
    if (markets && ftsDaily) {
      getFTSInfo();
    }
  }, [markets]);

  const handleSort = field => {
    setSortInfo({
      field,
      sort:
        sortInfo.field === field && sortInfo.sort === 'desc' ? 'asc' : 'desc'
    });
  };

  return (
    <FTSLayout>
      <MainLayout title="FTS Distribution">
        <FTSWrapper>
          {!account && (
            <SpinnerWrapper>
              <LoadingSpinner />
            </SpinnerWrapper>
          )}
          {account && (
            <>
              <FTSInfoWrapper className="flex align-center just-between">
                <div className="flex align-center fts-info">
                  <img src={coinImg} alt="fts" />
                  <a
                    className="highlight"
                    href={`${BASE_SCAN_URL}/token/${constants.CONTRACT_FTS_TOKEN_ADDRESS}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {constants.CONTRACT_FTS_TOKEN_ADDRESS}
                  </a>
                  <CopyToClipboard
                    text={constants.CONTRACT_FTS_TOKEN_ADDRESS}
                    onCopy={() => {}}
                  >
                    <Icon className="pointer copy-btn" type="copy" />
                  </CopyToClipboard>
                </div>
                <div className="flex flex-column distribution-wrapper">
                  <div className="flex align-center just-around info-wrapper">
                    <div className="info-item">
                      <p className="title">Daily Distribution</p>
                      <p className="value">{format(dailyDistribution)}</p>
                    </div>
                    {/* <div className="info-item">
                      <p className="title">Total Distributed</p>
                      <p className="value">{format(distributedFts)}</p>
                    </div> */}
                    <div className="info-item">
                      <p className="title">Remaining</p>
                      <p className="value">{format(remainAmount)}</p>
                    </div>
                  </div>
                  <Progress
                    percent={new BigNumber(distributedFts)
                      .dividedBy(new BigNumber(mintedAmount))
                      .multipliedBy(100)}
                    strokeColor="#F7C408"
                    strokeWidth={7}
                    showInfo={false}
                  />
                </div>
              </FTSInfoWrapper>
              <TableWrapper>
                <p className="header-title">Market Distribution</p>
                <Row className="table_header">
                  <Col xs={{ span: 24 }} lg={{ span: 6 }} className="market">
                    Market
                  </Col>
                  <Col
                    xs={{ span: 8 }}
                    lg={{ span: 6 }}
                    className="per-day right"
                  >
                    <span onClick={() => handleSort('perDay')}>
                      <img src={coinImg} alt="fts" /> Per Day{' '}
                      {sortInfo.field === 'perDay' && (
                        <Icon
                          type={
                            sortInfo.sort === 'desc' ? 'caret-down' : 'caret-up'
                          }
                        />
                      )}
                    </span>
                  </Col>
                  <Col
                    xs={{ span: 8 }}
                    lg={{ span: 6 }}
                    className="supply-apy right"
                  >
                    <span onClick={() => handleSort('supplyAPY')}>
                      Supply
                      <img src={coinImg} alt="fts" />
                      APY{' '}
                      {sortInfo.field === 'supplyAPY' && (
                        <Icon
                          type={
                            sortInfo.sort === 'desc' ? 'caret-down' : 'caret-up'
                          }
                        />
                      )}
                    </span>
                  </Col>
                  <Col
                    xs={{ span: 8 }}
                    lg={{ span: 6 }}
                    className="borrow-apy right"
                  >
                    <span onClick={() => handleSort('borrowAPY')}>
                      Borrow
                      <img src={coinImg} alt="fts" />
                      APY{' '}
                      {sortInfo.field === 'borrowAPY' && (
                        <Icon
                          type={
                            sortInfo.sort === 'desc' ? 'caret-down' : 'caret-up'
                          }
                        />
                      )}
                    </span>
                  </Col>
                  {/* <Col xs={{ span: 6 }} lg={{ span: 4 }} className="total-distributed right">
                    Total Distributed
                  </Col> */}
                </Row>
                <div className="table_content">
                  {(marketInfos || [])
                    .sort((a, b) => {
                      if (!sortInfo.field) {
                        return +new BigNumber(b.perDay)
                          .minus(new BigNumber(a.perDay))
                          .toString(10);
                      }
                      if (sortInfo.field === 'perDay') {
                        return sortInfo.sort === 'desc'
                          ? +new BigNumber(b.perDay)
                              .minus(new BigNumber(a.perDay))
                              .toString(10)
                          : +new BigNumber(a.perDay)
                              .minus(new BigNumber(b.perDay))
                              .toString(10);
                      }
                      if (sortInfo.field === 'supplyAPY') {
                        return sortInfo.sort === 'desc'
                          ? +new BigNumber(b.supplyAPY)
                              .minus(new BigNumber(a.supplyAPY))
                              .toString(10)
                          : +new BigNumber(a.supplyAPY)
                              .minus(new BigNumber(b.supplyAPY))
                              .toString(10);
                      }
                      if (sortInfo.field === 'borrowAPY') {
                        return sortInfo.sort === 'desc'
                          ? +new BigNumber(b.borrowAPY)
                              .minus(new BigNumber(a.borrowAPY))
                              .toString(10)
                          : +new BigNumber(a.borrowAPY)
                              .minus(new BigNumber(b.borrowAPY))
                              .toString(10);
                      }
                    })
                    .map((item, index) => (
                      <Row className="table_item pointer" key={index}>
                        <Col
                          xs={{ span: 24 }}
                          lg={{ span: 6 }}
                          className="flex align-center market"
                        >
                          <img
                            className="asset-img"
                            src={item.asset}
                            alt="asset"
                          />
                          <p>{item.underlyingSymbol}</p>
                        </Col>
                        <Col
                          xs={{ span: 24 }}
                          lg={{ span: 6 }}
                          className="per-day right"
                        >
                          <p className="mobile-label">Per day</p>
                          <p>{item.perDay}</p>
                        </Col>
                        <Col
                          xs={{ span: 24 }}
                          lg={{ span: 6 }}
                          className="supply-apy right"
                        >
                          <p className="mobile-label">Supply APY</p>
                          <p>{item.supplyAPY}%</p>
                        </Col>
                        <Col
                          xs={{ span: 24 }}
                          lg={{ span: 6 }}
                          className="borrow-apy right"
                        >
                          <p className="mobile-label">Borrow APY</p>
                          {item.underlyingSymbol !== 'FAI' ? (
                            <p>{item.borrowAPY}%</p>
                          ) : (
                            <p>-</p>
                          )}
                        </Col>
                        {/* <Col xs={{ span: 24 }} lg={{ span: 4 }} className="total-distributed right">
                            <p className="mobile-label">Total Distributed</p>
                            <p>{format(item.distributedFts.toString())}</p>
                          </Col> */}
                      </Row>
                    ))}
                </div>
              </TableWrapper>
            </>
          )}
        </FTSWrapper>
      </MainLayout>
    </FTSLayout>
  );
}

FTS.propTypes = {
  settings: PropTypes.object
};

FTS.defaultProps = {
  settings: {}
};

const mapStateToProps = ({ account }) => ({
  settings: account.setting
});

const mapDispatchToProps = dispatch => {
  const { getVoterAccounts } = accountActionCreators;

  return bindActionCreators(
    {
      getVoterAccounts
    },
    dispatch
  );
};

export default compose(
  withRouter,
  connectAccount(mapStateToProps, mapDispatchToProps)
)(FTS);
