import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { compose } from 'recompose';
import { NavLink, withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { Icon } from 'antd';
import BigNumber from 'bignumber.js';
import { Collapse } from 'react-collapse';
import { methods } from 'utilities/ContractService';
import { Label } from 'components/Basic/Label';
import { connectAccount, accountActionCreators } from 'core';
import logoImg from 'assets/img/logo.png';
import FTSIcon from 'assets/img/fts.svg';
import commaNumber from 'comma-number';
import { getBigNumber } from 'utilities/common';
import colorBuy from 'assets/img/menu/buy-color.svg';
import whiteBuy from 'assets/img/menu/buy-white.svg';
import menu from 'assets/img/menu.png';
import { useFaiToken } from '../../hooks/useContract';
import ConnectButton from '../../components/Basic/ConnectButton';
import { useMarkets } from '../../hooks/useMarkets';
import { getFaiVaultAddress } from '../../utilities/addressHelpers';
import { useWeb3React } from '@web3-react/core';
import useRefresh from '../../hooks/useRefresh';

const SidebarWrapper = styled.div`
  height: 100vh;
  min-width: 120px;
  background-color: #141414;
  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  margin-right: 30px;

  @media only screen and (max-width: 768px) {
    display: flex;
    height: 60px;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-right: 0px;
  }
`;

const Logo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px 0;
  border-bottom: 1px solid #3d3d3d;
  i {
    font-size: 18px;
  }

  @media only screen and (max-width: 768px) {
    img {
      width: 60px;
      margin-left: 20px;
    }
    border-bottom: none;
  }

  @media only screen and (max-width: 1280px) {
    i {
      font-size: 12px !important;
    }
    img {
      width: 80px !important;
    }
  }
`;

const MainMenu = styled.div`
  margin-top: 40px;

  @media only screen and (max-width: 768px) {
    margin: 0 20px;
  }

  a {
    padding: 7px;

    .buy-icon {
      display: block;
    }

    .buy-active-icon {
      display: none;
    }

    @media only screen and (max-width: 1440px) {
      span {
        font-size: 14px;
      }
    }

    @media only screen and (max-width: 1280px) {
      span {
        font-size: 12px;
      }
    }
    &:not(:last-child) {
      margin-bottom: 15px;
    }

    &:hover {
      svg {
        fill: var(--color-yellow);
      }
      span {
        color: var(--color-yellow);
      }

      .buy-icon {
        display: none;
      }

      .buy-active-icon {
        display: block;
      }
    }
    i {
      width: 20%;
      margin: 0 5%;
      svg {
        transition: var(--transition);
        fill: var(--color-text-main);
      }
    }
    .img-wrapper {
      width: 20%;
      margin: 0 5%;
      display: flex;
      justify-content: center;
    }
    span {
      flex: 1;
      transition: var(--transition);
    }
  }

  .active {
    svg {
      fill: var(--color-yellow);
    }
    span {
      color: var(--color-yellow);
    }
  }

  .submenu {
    margin-left: 30%;
  }

  @media only screen and (max-width: 768px) {
    display: none;
  }
`;

const FaucetMenu = styled.div`
  width: 100%;
  margin-top: auto;
  margin-bottom: 20px;
  a {
    padding: 7px 0px;
    span {
      transition: var(--transition);
    }

    svg {
      fill: var(--color-text-main);
      margin-left: 34px;
      margin-right: 26px;
    }
    &:not(:last-child) {
      margin-bottom: 20px;
    }

    &:hover {
      svg {
        fill: var(--color-yellow);
      }
      span {
        color: var(--color-yellow);
      }
    }

    @media only screen and (max-width: 1440px) {
      span {
        font-size: 14px;
      }
    }

    @media only screen and (max-width: 1280px) {
      span {
        font-size: 12px;
      }
    }
  }
  .active {
    svg {
      fill: var(--color-yellow);
    }
    span {
      color: var(--color-yellow);
    }
  }

  @media only screen and (max-width: 768px) {
    display: none;
  }
`;

const TotalValue = styled.div`
  width: 100%;
  margin-bottom: 20px;

  > div {
    span:first-child {
      word-break: break-all;
      text-align: center;
    }
  }

  @media only screen and (max-width: 768px) {
    display: none;
  }
`;

const MobileMenu = styled.div`
  width: 100%;
  display: none;

  @media only screen and (max-width: 768px) {
    display: flex;
    justify-content: flex-end;
  }

  .menu-icon {
    margin-right: 24px;
    cursor: pointer;
  }

  .collapse-content {
    position: absolute;
    width: 100%;
    top: 42px;
    left: 0;
    z-index: 100;

    .ReactCollapse--content {
      position: relative;
      background-color: #141414;
      text-align: center;
    }

    .mobile-menu {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 20px 0px;

      a {
        color: var(--color-white);
        font-size: 18px;
        margin-bottom: 12px;
        &:hover {
          color: var(--color-yellow);
        }
      }
    }
  }
`;

const ChainButton = styled.div`
  position: absolute;
  right: 225px;
  top: 25px;
  display: flex;
  justify-content: center;
  z-index: 100;

  @media only screen and (max-width: 768px) {
    top: 14px;
    right: 200px;
  }

  .chain-btn {
    width: 128px;
    height: 40px;
    border-radius: 20px;
    border: 1px solid var(--color-yellow);
    display: flex;
    justify-content: center;

    &:hover {
      background-color: var(--color-yellow-hover);
    }

    @media only screen and (max-width: 768px) {
      width: 40px;
      background-color: var(--color-bg-primary);
      border-radius: 20px;

      .binance-label {
        display: none;
      }
    }

    svg {
      width: 25px;
      height: 25px;
      margin-right: 10px;
      @media only screen and (max-width: 768px) {
        margin-right: 0;
      }
    }

    .MuiButton-label {
      font-size: 13px;
      font-weight: 500;
      color: var(--color-yellow);
      text-transform: capitalize;

      @media only screen and (max-width: 768px) {
        font-size: 12px;
      }
    }
  }
`;

const format = commaNumber.bindWith(',', '.');

function Sidebar({ history, setSetting }) {
  const [totalFaiMinted, setTotalFaiMinted] = useState('0');
  const [isCollapse, setIsCollapse] = useState(false);
  const [isVaultShown, setIsVaultShown] = useState(false);
  const faiContract = useFaiToken();
  const { fastRefresh } = useRefresh();
  const { markets, ftsDaily } = useMarkets();
  const { account } = useWeb3React();
  const [tvl, setTVL] = useState(new BigNumber(0));

  useEffect(() => {
    document.addEventListener('mouseup', e => {
      const container = document.getElementById('collapse-content');
      const menuContainer = document.getElementById('menu-icon');
      if (!container.contains(e.target) && !menuContainer.contains(e.target)) {
        setIsCollapse(false);
      }
    });
  }, []);

  const isVaultPath = useMemo(() => {
    const defaultPath = history.location.pathname.split('/')[1];
    return ['vault', 'fts-vault', 'ffts-vault'].includes(defaultPath);
  }, [history]);

  const getTotalFaiMinted = async () => {
    // total fai minted
    let tvm = await methods.call(faiContract.methods.totalSupply, []);
    tvm = new BigNumber(tvm).div(1e18);

    setTotalFaiMinted(tvm);
  };

  useEffect(() => {
    getTotalFaiMinted();
  }, [fastRefresh]);

  const updateMarketInfo = async () => {
    if (!account || !markets) {
      return;
    }

    // Total Fai Staked
    let faiVaultStaked = await methods.call(faiContract.methods.balanceOf, [
      getFaiVaultAddress()
    ]);
    faiVaultStaked = new BigNumber(faiVaultStaked).div(1e18).toNumber();

    // FAI APY
    let faiAPY = '0';
    if (ftsDaily && faiVaultStaked) {
      const ftsMarket = markets.find(ele => ele.underlyingSymbol === 'FTS');
      faiAPY = new BigNumber(ftsDaily)
        .div(1e18)
        .times(ftsMarket ? ftsMarket.underlyingPriceUSD : 2)
        .times(3 * 365 * 100)
        .div(7)
        .div(faiVaultStaked)
        .dp(2, 1)
        .toString(10);
    }

    const totalLiquidity = (markets || []).reduce((accumulator, market) => {
      return new BigNumber(accumulator).plus(
        new BigNumber(market.totalSupplyUsd)
      );
    }, faiVaultStaked);
    setTVL(totalLiquidity);
    setSetting({
      faiAPY
    });
  };

  useEffect(() => {
    updateMarketInfo();
  }, [markets, account]);

  return (
    <SidebarWrapper>
      <Logo>
        <NavLink to="/" activeClassName="active">
          <img src={logoImg} alt="logo" className="logo-text" />
        </NavLink>
      </Logo>
      <MainMenu>
        <NavLink
          className="flex flex-start align-center"
          to="/dashboard"
          activeClassName="active"
        >
          <Icon type="home" />
          <Label primary>Dashboard</Label>
        </NavLink>
        <NavLink
          className="flex flex-start align-center"
          to="/vote"
          activeClassName="active"
        >
          <Icon type="appstore" />
          <Label primary>Vote</Label>
        </NavLink>
        <NavLink
          className="flex flex-start align-center"
          to="/fts"
          activeClassName="active"
        >
          <div className="img-wrapper">
            <img
              className="fts-icon"
              src={FTSIcon}
              alt="fts"
              width={15}
              height={15}
            />
          </div>
          {/* <img className="fts-active-icon" src={FTSActiveIcon} alt="fts" /> */}
          <Label primary>FTS</Label>
        </NavLink>
        <NavLink
          className="flex flex-start align-center"
          to="/market"
          activeClassName="active"
        >
          <Icon type="area-chart" />
          <Label primary>Market</Label>
        </NavLink>
        <NavLink
          className="flex flex-start align-center"
          to="#"
          onClick={() => {
            console.log('isVaultShown :>> ', isVaultShown);
            setIsVaultShown(!isVaultShown);
          }}
          activeClassName="nonactive"
        >
          <i aria-label="icon: area-chart" class="anticon anticon-area-chart">
            <svg
              width="14"
              height="14"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M14.9264 11.8833C15.5269 11.8833 16.0137 11.3965 16.0137 10.796C16.0137 10.1955 15.5269 9.70874 14.9264 9.70874C14.3259 9.70874 13.8391 10.1955 13.8391 10.796C13.8391 11.3965 14.3259 11.8833 14.9264 11.8833Z" />
              <path d="M4.4469 15.122H19.5068V6.4469H4.4469V15.122ZM10.9706 10.1251H12.613C12.9138 9.13039 13.816 8.39012 14.9264 8.39012C16.2681 8.39012 17.3323 9.47739 17.3323 10.796C17.3323 12.1378 16.245 13.2019 14.9264 13.2019C13.8391 13.2019 12.9138 12.4616 12.613 11.4437H10.9706C10.6004 11.4437 10.2997 11.143 10.2997 10.7729C10.2997 10.4259 10.6004 10.1251 10.9706 10.1251Z" />
              <path d="M21.7508 3H2.22608C1.55521 3 1 3.55521 1 4.22608V17.3891C1 18.0599 1.55521 18.6151 2.22608 18.6151H4.0999V19.7718C4.0999 20.0494 4.33123 20.2808 4.60883 20.2808H6.13565C6.41325 20.2808 6.64458 20.0494 6.64458 19.7718V18.6151H17.3554V19.7718C17.3554 20.0494 17.5868 20.2808 17.8644 20.2808H19.3912C19.6688 20.2808 19.9001 20.0494 19.9001 19.7718V18.6151H21.7739C22.4448 18.6151 23 18.0599 23 17.3891V4.20294C22.9769 3.53207 22.4217 3 21.7508 3ZM20.7329 15.7466C20.7329 16.0936 20.4553 16.3481 20.1314 16.3481H3.84543C3.49842 16.3481 3.24395 16.0705 3.24395 15.7466V5.84543C3.24395 5.49842 3.52156 5.24395 3.84543 5.24395H20.1314C20.4784 5.24395 20.7329 5.52156 20.7329 5.84543V15.7466Z" />
            </svg>
          </i>
          {/* <img className="vault-icon" src={vaultIcon} alt="fts" /> */}
          <Label primary>Vault</Label>
        </NavLink>
        {(isVaultShown || isVaultPath) && (
          <>
            <NavLink
              className="flex flex-start align-center submenu"
              to="/vault"
              activeClassName="active"
            >
              <Label primary>FAI Vault(Expired)</Label>
            </NavLink>
            <NavLink
              className="flex flex-start align-center submenu"
              to="/ffts-vault"
              activeClassName="active"
            >
              <Label primary>fFTS Vault(Expired)</Label>
            </NavLink>
            <NavLink
              className="flex flex-start align-center submenu"
              to="/fts-vault"
              activeClassName="active"
            >
              <Label primary>FTS Vault</Label>
            </NavLink>
          </>
        )}
        {/* <NavLink
          className="flex flex-start align-center"
          to="/ffts-vault"
          activeClassName="active"
        >
          <i aria-label="icon: area-chart" class="anticon anticon-area-chart">
            <svg
              width="14"
              height="14"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M14.9264 11.8833C15.5269 11.8833 16.0137 11.3965 16.0137 10.796C16.0137 10.1955 15.5269 9.70874 14.9264 9.70874C14.3259 9.70874 13.8391 10.1955 13.8391 10.796C13.8391 11.3965 14.3259 11.8833 14.9264 11.8833Z" />
              <path d="M4.4469 15.122H19.5068V6.4469H4.4469V15.122ZM10.9706 10.1251H12.613C12.9138 9.13039 13.816 8.39012 14.9264 8.39012C16.2681 8.39012 17.3323 9.47739 17.3323 10.796C17.3323 12.1378 16.245 13.2019 14.9264 13.2019C13.8391 13.2019 12.9138 12.4616 12.613 11.4437H10.9706C10.6004 11.4437 10.2997 11.143 10.2997 10.7729C10.2997 10.4259 10.6004 10.1251 10.9706 10.1251Z" />
              <path d="M21.7508 3H2.22608C1.55521 3 1 3.55521 1 4.22608V17.3891C1 18.0599 1.55521 18.6151 2.22608 18.6151H4.0999V19.7718C4.0999 20.0494 4.33123 20.2808 4.60883 20.2808H6.13565C6.41325 20.2808 6.64458 20.0494 6.64458 19.7718V18.6151H17.3554V19.7718C17.3554 20.0494 17.5868 20.2808 17.8644 20.2808H19.3912C19.6688 20.2808 19.9001 20.0494 19.9001 19.7718V18.6151H21.7739C22.4448 18.6151 23 18.0599 23 17.3891V4.20294C22.9769 3.53207 22.4217 3 21.7508 3ZM20.7329 15.7466C20.7329 16.0936 20.4553 16.3481 20.1314 16.3481H3.84543C3.49842 16.3481 3.24395 16.0705 3.24395 15.7466V5.84543C3.24395 5.49842 3.52156 5.24395 3.84543 5.24395H20.1314C20.4784 5.24395 20.7329 5.52156 20.7329 5.84543V15.7466Z" />
            </svg>
          </i>
          <Label primary>fFTS Vault</Label>
        </NavLink>
        <NavLink
          className="flex flex-start align-center"
          to="/fts-vault"
          activeClassName="active"
        >
          <i aria-label="icon: area-chart" class="anticon anticon-area-chart">
            <svg
              width="14"
              height="14"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M14.9264 11.8833C15.5269 11.8833 16.0137 11.3965 16.0137 10.796C16.0137 10.1955 15.5269 9.70874 14.9264 9.70874C14.3259 9.70874 13.8391 10.1955 13.8391 10.796C13.8391 11.3965 14.3259 11.8833 14.9264 11.8833Z" />
              <path d="M4.4469 15.122H19.5068V6.4469H4.4469V15.122ZM10.9706 10.1251H12.613C12.9138 9.13039 13.816 8.39012 14.9264 8.39012C16.2681 8.39012 17.3323 9.47739 17.3323 10.796C17.3323 12.1378 16.245 13.2019 14.9264 13.2019C13.8391 13.2019 12.9138 12.4616 12.613 11.4437H10.9706C10.6004 11.4437 10.2997 11.143 10.2997 10.7729C10.2997 10.4259 10.6004 10.1251 10.9706 10.1251Z" />
              <path d="M21.7508 3H2.22608C1.55521 3 1 3.55521 1 4.22608V17.3891C1 18.0599 1.55521 18.6151 2.22608 18.6151H4.0999V19.7718C4.0999 20.0494 4.33123 20.2808 4.60883 20.2808H6.13565C6.41325 20.2808 6.64458 20.0494 6.64458 19.7718V18.6151H17.3554V19.7718C17.3554 20.0494 17.5868 20.2808 17.8644 20.2808H19.3912C19.6688 20.2808 19.9001 20.0494 19.9001 19.7718V18.6151H21.7739C22.4448 18.6151 23 18.0599 23 17.3891V4.20294C22.9769 3.53207 22.4217 3 21.7508 3ZM20.7329 15.7466C20.7329 16.0936 20.4553 16.3481 20.1314 16.3481H3.84543C3.49842 16.3481 3.24395 16.0705 3.24395 15.7466V5.84543C3.24395 5.49842 3.52156 5.24395 3.84543 5.24395H20.1314C20.4784 5.24395 20.7329 5.52156 20.7329 5.84543V15.7466Z" />
            </svg>
          </i>
          <Label primary>FTS Vault</Label>
        </NavLink> */}
        <NavLink
          className="flex flex-start align-center"
          to="/liquidator"
          activeClassName="active"
        >
          <i aria-label="icon: area-chart" class="anticon anticon-area-chart">
            <svg
              width="14"
              height="14"
              viewBox="0 0 25 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_1431:833)">
                <path d="M18.1248 22.9165H6.87484C5.4165 22.9165 4.1665 21.7707 4.1665 20.3123V4.68733C4.1665 3.22899 5.4165 2.08316 6.87484 2.08316H15.2082C15.5207 2.08316 15.8332 2.18733 15.9373 2.39566L20.5207 7.60399C20.729 7.81233 20.8332 8.12483 20.8332 8.33316V20.3123C20.8332 21.7707 19.5832 22.9165 18.1248 22.9165ZM14.5832 4.16649V7.49983C14.5832 7.91649 14.8957 8.33316 15.3123 8.33316H18.4373L14.5832 4.16649Z" />
                <circle cx="19" cy="19" r="7" fill="#1B1B1B" />
                <path d="M19 14C16.243 14 14 16.243 14 19C14 21.757 16.243 24 19 24C21.757 24 24 21.757 24 19C24 16.243 21.757 14 19 14ZM20.501 18C20.501 18.276 20.277 18.5 20.001 18.5C19.725 18.5 19.501 18.276 19.501 18C19.501 18 19.5555 17.4675 18.928 17.5C18.693 17.512 18.501 17.6915 18.501 17.927C18.501 18.3585 19.0265 18.412 19.712 18.7965C20.243 19.094 20.501 19.5295 20.501 20.073C20.501 20.71 20.0785 21.2445 19.501 21.4275C19.501 21.4275 19.5555 22 19.001 22C18.449 22 18.501 21.4275 18.501 21.4275C17.384 21.007 17.501 20 17.501 20C17.501 19.724 17.725 19.5 18.001 19.5C18.277 19.5 18.501 19.724 18.501 20C18.501 20 18.418 20.5215 19.074 20.5C19.309 20.492 19.501 20.3085 19.501 20.073C19.501 19.91 19.4085 19.767 19.2645 19.6905C18.416 19.241 17.4975 19.0265 17.501 17.927C17.503 17.29 17.9235 16.7555 18.501 16.5725C18.501 16.5725 18.442 16 19.001 16C19.5515 16 19.501 16.5725 19.501 16.5725C20.5815 16.9625 20.501 18 20.501 18Z" />
              </g>
              <defs>
                <clipPath id="clip0_1431:833">
                  <rect width="25" height="25" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </i>
          {/* <img className="vault-icon" src={vaultIcon} alt="fts" /> */}
          <Label primary>Liquidator</Label>
        </NavLink>

        <a
          className="flex flex-start align-center"
          href="https://exchange.jetswap.finance/#/swap?inputCurrency=bnb&outputCurrency=0x4437743ac02957068995c48E08465E0EE1769fBE"
          target="blank"
          activeClassName="active"
        >
          <div className="img-wrapper">
            <img
              className="buy-icon"
              src={whiteBuy}
              alt="buy fts"
              width={14}
              height={14}
            />
            <img
              className="buy-active-icon"
              src={colorBuy}
              alt="buy fts"
              width={14}
              height={14}
            />
          </div>
          <Label primary>Buy FTS</Label>
        </a>
      </MainMenu>
      <FaucetMenu>
        {/* <a
          className="flex just-center"
          href="https://docs.fortress.loans/"
          target="_blank"
        >
          <Label primary>DOCS</Label>
        </a> */}
        {/* <NavLink
          className="flex just-center"
          to="/faq"
          activeClassName="active"
        >
          <Label primary>FAQ</Label>
        </NavLink> */}
        {process.env.REACT_APP_CHAIN_ID === '97' && (
          <NavLink
            className="flex just-center"
            to="/faucet"
            activeClassName="active"
          >
            <Label primary>Faucet</Label>
          </NavLink>
        )}
      </FaucetMenu>
      {account && (
        <TotalValue>
          <div className="flex flex-column align-center just-center">
            <Label primary>
              ${format(new BigNumber(tvl).dp(2, 1).toString(10))}
            </Label>
            <Label className="center">Total Value Locked</Label>
          </div>
        </TotalValue>
      )}
      {account && (
        <TotalValue>
          <div className="flex flex-column align-center just-center">
            <Label primary>
              {format(
                getBigNumber(totalFaiMinted)
                  .dp(0, 1)
                  .toString(10)
              )}
            </Label>
            <Label className="center">Total FAI Minted</Label>
          </div>
        </TotalValue>
      )}
      <ConnectButton />
      <MobileMenu>
        <img
          id="menu-icon"
          className="menu-icon"
          src={menu}
          alt="menu"
          onClick={() => setIsCollapse(!isCollapse)}
        />
        <div className="collapse-content" id="collapse-content">
          <Collapse isOpened={isCollapse}>
            <div className="mobile-menu">
              <NavLink to="/dashboard">Dashboard</NavLink>
              <NavLink to="/vote">Vote</NavLink>
              <NavLink to="/fts">FTS</NavLink>
              <NavLink to="/market">Market</NavLink>
              <NavLink to="/vault">FAI Vault(Expired)</NavLink>
              <NavLink to="/ffts-vault">fFTS Vault(Expired)</NavLink>
              <NavLink to="/fts-vault">FTS Vault</NavLink>
              <NavLink to="/liquidator">Liquidator</NavLink>
              <a
                href="https://exchange.jetswap.finance/#/swap?inputCurrency=bnb&outputCurrency=0x4437743ac02957068995c48E08465E0EE1769fBE"
                rel="noreferrer"
                target="_blank"
              >
                Buy FTS
              </a>
              {/* <NavLink to="/faq">FAQ</NavLink> */}
              {process.env.REACT_APP_CHAIN_ID === '97' && (
                <NavLink to="/faucet">Faucet</NavLink>
              )}
            </div>
          </Collapse>
        </div>
      </MobileMenu>
    </SidebarWrapper>
  );
}

Sidebar.propTypes = {
  history: PropTypes.object,
  setSetting: PropTypes.func.isRequired,
  getGovernanceFortress: PropTypes.func.isRequired
};

Sidebar.defaultProps = {
  history: {}
};

const mapDispatchToProps = dispatch => {
  const { setSetting, getGovernanceFortress } = accountActionCreators;

  return bindActionCreators(
    {
      setSetting,
      getGovernanceFortress
    },
    dispatch
  );
};

export default compose(
  withRouter,
  connectAccount(undefined, mapDispatchToProps)
)(Sidebar);
