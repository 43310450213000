const contracts = {
  faiToken: {
    56: '0x10a450A21B79c3Af78fb4484FF46D3E647475db4',
    97: '0x3B13b1af99b6d75D532C4E234fb2c3aE62744b73'
  },
  comptroller: {
    56: '0x67340Bd16ee5649A37015138B3393Eb5ad17c195',
    97: '0xB24DaCE5343A97fc0E82584Ecd52c7e54ABBda09'
  },
  faiUnitroller: {
    56: '0x9b2626943530E8634134514Dd14131103fA548B4',
    97: '0x1221622cC891Ee3f8A0F779D51f5fe72AF53c290'
  },
  faiVault: {
    56: '0x066807c7B22c6c0a7fa370A2cA812e5Fc22DBef6',
    97: '0x0a5b2eE609514153B307FBdceB52C68199BaD712'
  },
  oracle: {
    56: '0x057206f1bF8CeC033022636ee0389dA20676474F',
    97: '0x0856e9c894c2dFdb278689d236BC174Bc55A91C0'
  },
  vote: {
    56: '0xE79ecdB7fEDD413E697F083982BAC29e93d86b2E',
    97: '0x46772D07EeD738017178BfAAc56AE3Ca671f6a77'
  },
  maximillion: {
    56: '0x4E07a544ead53dce172C62cCF0AB03AF1b25a795',
    97: '0xfcafb139a9208D4Fe1D28D5dF986430e915fB009'
  },
  rewardPool: {
    56: '0xa45e5A2258c8124ce11165eEBf85991d47dc1703',
    97: '0x7421fD69261844536E0814C3B5cB44C560035908'
  },
  ftsRewardPool: {
    56: '0xF3DbaC68E622A0BA2457A4D45FAa6e443B839c04',
    97: '0xa45e5A2258c8124ce11165eEBf85991d47dc1703'
  }
};

export default contracts;
