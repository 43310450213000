import React  from 'react';

const FAQ_CONTENT = [
  {
    title: "<span>What is Fortress</span>",
    content: "<span>Fortress is a decentralized finance protocol that operates on the Binance Smart Chain (BSC) and allows you to deposit cryptos to generate interest, as well as to take loans secured by collateral.</span>"
  },
  {
    title: "<span>What is FTS</span>",
    content: "<span>FTS is the governance & farming reward token of Fortress and allows its holders to vote and make decisions on the fate of the platform.</span>"
  },
  {
    title: "<span>What is FAI</span>",
    content: "<span>FAI is the stablecoin of the Fortress ecosystem and is backed by a basket of stablecoins and crypto assets without centralized control. Inside Fortress 1 FAI is always equal to 1 USD.</span>"
  },
  {
    title: "<span>What is Collateral</span>",
    content: "<span>In order to borrow crypto from the Fortress protocol, users need to first supply another type of crypto as collateral. This is provided using the same mint function used for supplying assets. Supplied collateral assets earn interest while in the protocol, but users cannot redeem or transfer assets while they are being used as collateral.</span>"
  },
  {
    title: "<span>What is Collateral Factor</span>",
    content: "<span>The maximum amount users can borrow is limited by the collateral factors of the assets they have supplied. For example, if a user supplies 100 USDT as collateral, and the posted collateral factor for USDT is 60%, then the user can borrow at most 60 USDT worth of other assets at any given time. Each asset on Fortress can have a different collateral factor. Collateral factors for each asset can be fetched using the Comptroller contract.</span>"
  },
  {
    title: "<span>How is FTS minted and what is the total supply</span>",
    content: "<span>The total amount of FTS is 10 million. Right now 0.25 FTS are mined per block, for a total of 7123 per day.</span>"
  },
  {
    title: "<span>When is FTS rewarded</span>",
    content: "<span>Fortress distributes rewards in FTS to those who deposit assets (supply), to those who take collateralized loans (borrow) and also to those who put FAI in the Vault.</span>"
  },
  {
    title: "<span>How are interest rates set</span>",
    content: "<span>Interest rates are a function of the liquidity available in each market, and fluctuate in real-time based on supply and demand. When liquidity is plentiful, interest rates are low. As liquidity becomes scarce, interest rates increase, incentivizing new supply and the repayment of borrowing.</span>"
  },
  {
    title: "<span>Why is the supply rate lower than the borrow rate</span>",
    content: 
      `<div>
        <p>In each market there is excess liquidity (assets supplied &rarr; assets borrowed), which allows you to quickly withdraw or borrow funds from the protocol.</p>
        <p>The interest paid by borrowers is earned by the suppliers of the asset. Because there are more suppliers, the interest rate they earn is proportionately lower; this measured by an asset's Utilization Rate.</p>
        <p>Second, a portion of the interest paid by borrowers is set aside as Reserves, which acts as insurance and is controlled by FTS token-holders.</p>
        <p>On dashboard for each market, you can view the accumulated Reserves and Reserve Factor (the portion of interest set aside).</p>
      </div>`
  },
  {
    title: "<span>Is the Fortress protocol safe? Has it been audited</span>",
    content:
    `<div>
      <span>The security of the Fortress protocol is the highest priority; The Fortress development team, alongside third-party auditors are making sure that the protocol is and stays safe.</span>
      <span><a href='https://fortress.loans/' target='_blank'>Here</a> you can find the latest audit from EtherAuthority and Hash0x.</span>
    </div>`
  },
  {
    title: "<span>Why can't I withdraw my assets</span>",
    content:
      `<span>It may be the case that the liquidity of a currency is scarce at a given moment and not enough to withdraw it. Or you don't have enough gas to pay the fees.</span>`
  },
]

export default FAQ_CONTENT;
