import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import NumberFormat from 'react-number-format';
import BigNumber from 'bignumber.js';
import { methods } from 'utilities/ContractService';
import commaNumber from 'comma-number';
import faiImg from 'assets/img/coins/fai.svg';
import { TabSection, TabContent } from 'components/Basic/BorrowModal';
import LoadingSpinner from 'components/Basic/LoadingSpinner';
import { useFaiToken, useFaiUnitroller } from '../../../hooks/useContract';
import { useMarketsUser } from '../../../hooks/useMarketsUser';
import { useWeb3React } from '@web3-react/core';
import { getFaiUnitrollerAddress } from '../../../utilities/addressHelpers';

const format = commaNumber.bindWith(',', '.');

function RepayFaiTab() {
  const [isLoading, setIsLoading] = useState(false);
  const [amount, setAmount] = useState(new BigNumber(0));
  const faiContract = useFaiToken();
  const appContract = useFaiUnitroller();
  const { userFaiMinted, userFaiBalance, userFaiEnabled } = useMarketsUser();
  const { account } = useWeb3React();

  /**
   * Max amount
   */
  const handleMaxAmount = () => {
    setAmount(BigNumber.minimum(userFaiMinted, userFaiBalance));
  };

  /**
   * Approve FAI token
   */
  const onFaiApprove = async () => {
    if (account) {
      setIsLoading(true);
      methods
        .send(
          faiContract.methods.approve,
          [
            getFaiUnitrollerAddress(),
            new BigNumber(2)
              .pow(256)
              .minus(1)
              .toString(10)
          ],
          account
        )
        .then(res => {
          setIsLoading(false);
        })
        .catch(err => {
          setIsLoading(false);
        });
    }
  };

  /**
   * Repay FAI
   */
  const handleRepayFAI = () => {
    if (account) {
      setIsLoading(true);
      methods
        .send(
          appContract.methods.repayFAI,
          [
            amount
              .times(1e18)
              .integerValue()
              .toString(10)
          ],
          account
        )
        .then(res => {
          setAmount(new BigNumber(0));
          setIsLoading(false);
        })
        .catch(err => {
          setIsLoading(false);
        });
    }
  };

  return (
    <TabSection>
      <div className="flex flex-column align-center just-center body-content repay-fai-content">
        {userFaiEnabled ? (
          <div className="flex align-center input-wrapper">
            <NumberFormat
              autoFocus
              value={amount.isZero() ? '0' : amount.toString(10)}
              onValueChange={({ value }) => {
                setAmount(new BigNumber(value));
              }}
              isAllowed={({ value }) => {
                return new BigNumber(value || 0).isLessThanOrEqualTo(
                  BigNumber.minimum(userFaiBalance, userFaiMinted)
                );
              }}
              thousandSeparator
              allowNegative={false}
              placeholder="0"
            />
            <span className="pointer max" onClick={() => handleMaxAmount()}>
              MAX
            </span>
          </div>
        ) : (
          <p className="center warning-label">
            To repay FAI to the Fortress Protocol, you need to approve it first.
          </p>
        )}
      </div>
      <TabContent className="flex flex-column align-center just-content fai-content-section">
        <div className="flex flex-column just-center align-center apy-content">
          <div className="description">
            <div className="flex align-center">
              <img className="asset-img" src={faiImg} alt="asset" />
              <div className="fai-balance">
                <span>Repay FAI</span>
                <span>Balance</span>
              </div>
            </div>
            <span>{format(userFaiMinted.dp(2, 1).toString(10))} FAI</span>
          </div>
        </div>
        {(userFaiBalance.isZero() || amount.isGreaterThan(userFaiBalance)) && (
          <p className="center warning-label">Your balance is not enough.</p>
        )}
        {!userFaiEnabled ? (
          <Button
            className="button fai-auto"
            disabled={isLoading || userFaiBalance.isZero()}
            onClick={() => {
              onFaiApprove();
            }}
          >
            {isLoading ? <LoadingSpinner size={5} color="#C4C4C4" /> : 'Enable'}
          </Button>
        ) : (
          <Button
            className="button fai-auto"
            disabled={
              isLoading ||
              amount.isNaN() ||
              amount.isZero() ||
              amount.isGreaterThan(userFaiMinted) ||
              amount.isGreaterThan(userFaiBalance)
            }
            onClick={handleRepayFAI}
          >
            {isLoading ? (
              <LoadingSpinner size={5} color="#C4C4C4" />
            ) : (
              'Repay FAI'
            )}
          </Button>
        )}
        <div className="description">
          <span>FAI Balance</span>
          <span>{format(userFaiBalance.dp(2, 1).toString(10))} FAI</span>
        </div>
      </TabContent>
    </TabSection>
  );
}

export default RepayFaiTab;
