import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import BigNumber from 'bignumber.js';
import commaNumber from 'comma-number';
import moment from 'moment';
import { Card } from 'components/Basic/Card';
import NumberFormat from 'react-number-format';
import Button from '@material-ui/core/Button';
import LoadingSpinner from 'components/Basic/LoadingSpinner';

const StakingWrapper = styled.div`
  width: 100%;
  height: 520px;
  border-radius: 5px;
  background-color: var(--color-bg-primary);
  padding: 20px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  .stake-section {
    margin: 15px 0;
    padding: 30px 0;
    border-radius: 20px;
    background-color: var(--color-bg-main);
    height: 230px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    .stake-info {
      font-weight: 500;
      font-size: 18px;
      color: var(--color-white);
    }

    .stake-warning {
      font-size: 14px;
      color: var(--color-text-secondary);
      margin: 12px 0;
    }

    .stake-input {
      position: relative;
      margin: 10px 25px;
      background-color: var(--color-bg-primary);
      border-radius: 20px;
      padding: 10px 15px;
      display: flex;
      align-items: center;

      input {
        width: 85%;
        border: none;
        height: 100%;
        font-size: 24px;
        color: var(--color-text-main);
        text-align: left;
        background: transparent;
        &:focus {
          outline: none;
        }
      }

      span {
        position: absolute;
        right: 25px;
        width: 12%;
        text-align: center;
        font-weight: 400;
        font-size: 14px;
        color: var(--color-yellow);
        cursor: pointer;
      }
    }

    .button {
      width: 248px;
      height: 41px;
      border-radius: 5px;
      background-color: var(--color-yellow);

      &:hover {
        background-color: var(--color-yellow-hover);
      }

      .MuiButton-label {
        font-size: 16px;
        font-weight: 500;
        color: var(--color-btn-color);
        text-transform: capitalize;
      }
    }
  }
`;

const Alert = styled.div`
  color: rgb(247 196 8);
  margin-bottom: 5px;
`;

const BtnWrapper = styled.div`
  display: flex;
  flex-direction: row;
  & button:first-child {
    margin-right: 10px;
  }
  @media only screen and (max-width: 768px) {
    flex-direction: column;
    & button:first-child {
      margin-right: 0;
      margin-bottom: 10px;
    }
  }
`;

const format = commaNumber.bindWith(',', '.');

function Staking({
  isEnabled,
  fFtsBalance,
  stakedAmount,
  isStakeLoading,
  isWithdrawLoading,
  handleStakefFts,
  handleWithdraw,
  handleApprove,
  canWithdraw,
  isClaimLoading,
  isClaimAvailable,
  handleClaim,
  availableWithdrawTime,
  isFFts = true
}) {
  const [stakeAmount, setStakeAmount] = useState(new BigNumber(0));
  const [withdrawAmount, setWithdrawAmount] = useState(new BigNumber(0));
  const decimals = isFFts ? 8 : 18;

  return (
    <Card>
      <StakingWrapper>
        <div className="stake-section">
          <div className="stake-info">
            {isFFts ? 'fFTS' : 'FTS'} Balance:&nbsp;
            {format(
              fFtsBalance
                .div(new BigNumber(10).pow(decimals))
                .dp(4, 1)
                .toString(10)
            )}
            &nbsp;{isFFts ? 'fFTS' : 'FTS'}
          </div>
          {!isEnabled ? (
            <p className="stake-warning">
              To stake {isFFts ? 'fFTS' : 'FTS'}, you need to approve it first.
            </p>
          ) : (
            <div className="stake-input">
              <NumberFormat
                autoFocus
                value={stakeAmount.isZero() ? '' : stakeAmount.toString(10)}
                onValueChange={({ value }) => {
                  setStakeAmount(new BigNumber(value));
                }}
                isAllowed={({ value }) => {
                  return new BigNumber(value || 0).lte(fFtsBalance);
                }}
                thousandSeparator
                allowNegative={false}
                placeholder="0"
              />
              <span
                onClick={() =>
                  setStakeAmount(
                    fFtsBalance.div(new BigNumber(10).pow(decimals))
                  )
                }
              >
                MAX
              </span>
            </div>
          )}
          <Button
            className="button"
            disabled={isStakeLoading || (isEnabled && !stakeAmount.gt(0))}
            onClick={() => {
              if (isEnabled) {
                handleStakefFts(
                  stakeAmount
                    .times(new BigNumber(10).pow(decimals))
                    .toString(10)
                );
              } else {
                handleApprove();
              }
            }}
          >
            {isStakeLoading && <LoadingSpinner size={5} color="#C4C4C4" />}
            {!isStakeLoading && (isEnabled ? 'Deposit To Vault' : 'Approve')}
          </Button>
        </div>
        <div className="stake-section">
          <div className="stake-info">
            Staked:&nbsp;
            {format(
              stakedAmount
                .div(new BigNumber(10).pow(decimals))
                .dp(4, 1)
                .toString(10)
            )}
            &nbsp;{isFFts ? 'fFTS' : 'FTS'}
          </div>
          <div className="stake-input">
            <NumberFormat
              autoFocus
              value={withdrawAmount.isZero() ? '' : withdrawAmount.toString(10)}
              onValueChange={({ value }) => {
                setWithdrawAmount(new BigNumber(value));
              }}
              isAllowed={({ value }) => {
                return new BigNumber(value || 0).lte(stakedAmount);
              }}
              thousandSeparator
              allowNegative={false}
              placeholder="0"
            />
            <span
              onClick={() =>
                setWithdrawAmount(
                  stakedAmount.div(new BigNumber(10).pow(decimals))
                )
              }
            >
              MAX
            </span>
          </div>
          {!canWithdraw && new BigNumber(stakedAmount).gt(0) && (
            <Alert>
              {isFFts ? 'fFTS' : 'FTS'} withdraw eligible in:&nbsp;
              {moment.unix(availableWithdrawTime).fromNow()}
            </Alert>
          )}
          <BtnWrapper>
            <Button
              className="button"
              onClick={() => handleClaim()}
              disabled={isClaimLoading || !isClaimAvailable}
            >
              {isClaimLoading ? (
                <LoadingSpinner size={5} color="#C4C4C4" />
              ) : (
                'Claim Reward'
              )}
            </Button>
            <Button
              className="button"
              onClick={() =>
                handleWithdraw(
                  withdrawAmount
                    .times(new BigNumber(10).pow(decimals))
                    .toString(10)
                )
              }
              disabled={
                !canWithdraw ||
                isWithdrawLoading ||
                withdrawAmount.isZero() ||
                withdrawAmount.isNaN() ||
                withdrawAmount.isGreaterThan(stakedAmount)
              }
            >
              {isWithdrawLoading ? (
                <LoadingSpinner size={5} color="#C4C4C4" />
              ) : (
                'Withdraw To Wallet'
              )}
            </Button>
          </BtnWrapper>
        </div>
      </StakingWrapper>
    </Card>
  );
}

Staking.propTypes = {
  isEnabled: PropTypes.bool.isRequired,
  fFtsBalance: PropTypes.object.isRequired,
  stakedAmount: PropTypes.object.isRequired,
  isStakeLoading: PropTypes.bool.isRequired,
  isWithdrawLoading: PropTypes.bool.isRequired,
  isClaimLoading: PropTypes.bool.isRequired,
  handleStakefFts: PropTypes.func.isRequired,
  handleWithdraw: PropTypes.func.isRequired,
  handleApprove: PropTypes.func.isRequired,
  canWithdraw: PropTypes.bool.isRequired,
  isClaimAvailable: PropTypes.bool.isRequired,
  handleClaim: PropTypes.func.isRequired,
  availableWithdrawTime: PropTypes.number.isRequired,
  isFFts: PropTypes.bool.isRequired
};

Staking.defaultProps = {};

export default Staking;
