export default {
  TEST: {
    TOKEN: {
      fts: '0x00d1d87E5A64DA9F87464aCB9f15aEE980bf7eA5',
      fai: '0x3B13b1af99b6d75D532C4E234fb2c3aE62744b73',
      usdc: '0x813b24C0f3BafEB03DB596B97D487ac362756961',
      usdt: '0x35DacF4A946ED9Cca62Df5908242171877C5752b',
      busd: '0x23836A49c851d8B566014d3b2B8331B5ba3a79EB',
      btcb: '0xBaCf91Cf4C0cb80AC1C6dBFD094E15F79315398D',
      eth: '0x912c7fD8701216819d11A0ced3d0F8fDbD3fa61a',
      ltc: '0x44675a4E99AA8e44FFe91c73d1938D3E146b3e75',
      xrp: '0x4d1Efb092F8BC0cB3599b93c8604Bf0E9493a5E7',
      ada: '0x93bebCd04FF7Ae9737Eda7CC297e212eccba1518',
      dai: '0x3695320d4Df35349490A5BF2043B3e18A301Cf66',
      beth: '0x3CAeef379265a6b445B8Ec997059D15F77400E8b',
      dot: '0x74275FA904c59f180710d5F5c658eA08eA9a4976',
      'cake-fts-bnb-lp': '0x02a77c272018d711361b68e029e93bbd50a381d4',
      shib: '0xdDc672082d0fa1ACA1155281D67Ce047Fbbc1Cb4',
    },
    FBEP: {
      usdc: '0xF984655CB544Cc25deE85A4d6b1374410F9672c7',
      usdt: '0x1C09B065B631ADF32Da59bb0dC1a4c90E6e49a97',
      busd: '0x94f4B9f9f79746fe26DCB84438Af52298d0181Ea',
      bnb: '0x4dcb6271b6Cb208943958d3f5D12Ec3dC4CeCD46',
      btcb: '0x7eeFDCe5C22F4Fbf879dc174716AfAb3ABb4A51B',
      eth: '0x92ea93842DFD470D1DE8453DbFe56F5B682AfF78',
      ltc: '0xd82E37B61c4a4A0ed90849b372642E5d5b6e173c',
      xrp: '0x7b4feEc329B1429e3BE3E2e3396589B58c79F8e6',
      ada: '0xF1Ff71741D78EddF3a135ED150F0ecd1C741de88',
      dai: '0x9d1AdEaad0b3B080dC231B10EDC5daB1B33162Bb',
      beth: '0x64b9A8Cf4875c5dE81C09F5fBb168B2D32943f0a',
      dot: '0x16F8D7FBaCC586b84B5591bE5094aA115BAD7a14',
      fts: '0x08e1A0E53D207eF7E28179DC66C7f4ed9252fCD5',
      'cake-fts-bnb-lp': '0x9ba17fddd6d923c8a8712ce8fab0763628b8ef8a',
      shib: '0x1716fb7a8BA1528B83F46D466E53019d540B45F0',
    }
  },
  MAIN: {
    TOKEN: {
      fts: '0x4437743ac02957068995c48E08465E0EE1769fBE',
      fai: '0x10a450A21B79c3Af78fb4484FF46D3E647475db4',
      usdc: '0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d',
      usdt: '0x55d398326f99059fF775485246999027B3197955',
      busd: '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
      btcb: '0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c',
      eth: '0x2170Ed0880ac9A755fd29B2688956BD959F933F8',
      ltc: '0x4338665CBB7B2485A8855A139b75D5e34AB0DB94',
      xrp: '0x1D2F0da169ceB9fC7B3144628dB156f3F6c60dBE',
      ada: '0x3EE2200Efb3400fAbB9AacF31297cBdD1d435D47',
      dai: '0x1AF3F329e8BE154074D8769D1FFa4eE058B1DBc3',
      beth: '0x250632378E573c6Be1AC2f97Fcdf00515d0Aa91B',
      dot: '0x7083609fCE4d1d8Dc0C979AAb8c869Ea2C873402',
      'cake-fts-bnb-lp': '0xc69f2139a6Ce6912703AC10e5e74ee26Af1b4a7e',
      shib: '0x2859e4544C4bB03966803b044A93563Bd2D0DD4D',
    },
    FBEP: {
      usdc: '0x3ef88D7FDe18Fe966474FE3878b802F678b029bC',
      usdt: '0x554530ecDE5A4Ba780682F479BC9F64F4bBFf3a1',
      busd: '0x8BB0d002bAc7F1845cB2F14fe3D6Aae1D1601e29',
      bnb: '0xE24146585E882B6b59ca9bFaaaFfED201E4E5491',
      btcb: '0x47BAA29244c342f1e6cDe11C968632E7403aE258',
      eth: '0x5F3EF8B418a8cd7E3950123D980810A0A1865981',
      ltc: '0xE75b16cc66F8820Fb97f52f0C25F41982ba4daF3',
      xrp: '0xa7FB72808de4FFcaCf9a815Bd1CcbE70F03b54ca',
      ada: '0x4C0933453359733b4867DfF1145A9a0749931A00',
      dai: '0x5F30fDDdCf14a0997a52fdb7D7F23b93F0f21998',
      beth: '0x8ed1f4c1326E5d3C1b6E99Ac9E5EC6651E11e3Da',
      dot: '0x8fc4f7A57BB19E701108b17d785a28118604a3D1',
      fts: '0x854C266b06445794FA543b1d8f6137c35924C9EB',
      'cake-fts-bnb-lp': '0xFF6296Fd1Cf18fDFCa02824801ebe1481b974391',
      shib: '0x073C0AC03e7C839C718A65E0C4D0724Cc0bd2B5f',      
    }
  }
};
