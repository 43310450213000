import { useMemo } from 'react';
import { useWeb3React } from '@web3-react/core';

const admin = '0xA334f03A1f1CC4D2E0cd74Ee52960a42fc67D900'; // Fortress Protocol Deployer

const useCheckAdmin = () => {
  const { account } = useWeb3React();

  return useMemo(() => {
    return account.toLowerCase() === admin.toLowerCase();
  }, [account]);
};

export default useCheckAdmin;
