import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { bindActionCreators } from 'redux';
import { connectAccount, accountActionCreators } from 'core';
import styled from 'styled-components';
import CircleProgressBar from 'components/Basic/CircleProgressBar';
import BigNumber from 'bignumber.js';
import commaNumber from 'comma-number';
import AnimatedNumber from 'animated-number-react';
import { Card } from 'components/Basic/Card';
import { getBigNumber } from 'utilities/common';
import Toggle from 'components/Basic/Toggle';
import { Label } from 'components/Basic/Label';
import { useMarketsUser } from '../../hooks/useMarketsUser';
import { useWeb3React } from '@web3-react/core';

const CardWrapper = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 5px;
  background-color: var(--color-bg-primary);
  padding: 13px 35px;

  @media only screen and (max-width: 768px) {
    flex-direction: column;
  }

  @media only screen and (max-width: 1280px) {
    .label {
      font-size: 16px;
    }
    .value {
      font-size: 20px;
    }
  }
`;

const BalancerWrapper = styled.div`
  flex: 1.2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-left: 5%;
  @media only screen and (max-width: 768px) {
    align-items: center;

    &:last-child {
      margin-top: 20px;
    }
  }

  .label {
    font-size: 16px;
    font-weight: 500;
    color: var(--color-text-main);

    &.green {
      color: var(--color-text-green);
    }

    &.red {
      color: var(--color-text-red);
    }
  }

  .value {
    font-size: 24px;
    font-weight: 500;
    color: var(--color-text-main);
    margin-top: 15px;
  }

  @media only screen and (max-width: 1680px) {
    flex: 1;
  }

  @media only screen and (max-width: 768px) {
    .label {
      font-size: 16px;
    }
    .value {
      font-size: 20px;
    }
  }
`;

const CircleWrapper = styled.div`
  flex: 1;
  .apy-toggle {
    display: flex;
    align-items: center;
    justify-content: center;

    .toggel-label {
      margin-left: 10px;
      .emoji {
        color: transparent;
        text-shadow: 0 0 0 grey;
      }
    }
  }
`;

const format = commaNumber.bindWith(',', '.');
const abortController = new AbortController();

function WalletBalance({ setSetting }) {
  const [netAPY, setNetAPY] = useState(new BigNumber(0));
  const [withFTS, setWithFTS] = useState(true);
  const {
    userTotalSupplyBalance,
    userTotalBorrowBalance,
    userMarketInfo
  } = useMarketsUser();
  const { account } = useWeb3React();

  const updateNetAPY = useCallback(async () => {
    let totalSum = new BigNumber(0);
    let totalSupplied = new BigNumber(0);
    let totalBorrowed = new BigNumber(0);
    userMarketInfo.forEach(asset => {
      const {
        supplyBalance,
        borrowBalance,
        underlyingPriceUSD,
        supplyApy,
        borrowApy,
        ftsSupplyApy,
        ftsBorrowApy
      } = asset;
      const supplyBalanceUSD = getBigNumber(supplyBalance).times(
        getBigNumber(underlyingPriceUSD)
      );
      const borrowBalanceUSD = getBigNumber(borrowBalance).times(
        getBigNumber(underlyingPriceUSD)
      );
      totalSupplied = totalSupplied.plus(supplyBalanceUSD);
      totalBorrowed = totalBorrowed.plus(borrowBalanceUSD);

      const supplyApyWithFTS = withFTS
        ? getBigNumber(supplyApy).plus(getBigNumber(ftsSupplyApy))
        : getBigNumber(supplyApy);
      const borrowApyWithFTS = withFTS
        ? getBigNumber(ftsBorrowApy).minus(getBigNumber(borrowApy))
        : getBigNumber(borrowApy).times(-1);

      // const supplyApyWithFTS = getBigNumber(supplyApy);
      // const borrowApyWithFTS = getBigNumber(borrowApy).times(-1);
      totalSum = totalSum.plus(
        supplyBalanceUSD
          .times(supplyApyWithFTS.div(100))
          .plus(borrowBalanceUSD.times(borrowApyWithFTS.div(100)))
      );
    });

    if (totalSum.isZero() || totalSum.isNaN()) {
      setNetAPY(new BigNumber(0));
    } else if (totalSum.isGreaterThan(0)) {
      setNetAPY(
        totalSupplied.isZero()
          ? new BigNumber(0)
          : totalSum.div(totalSupplied).times(100)
      );
    } else {
      setNetAPY(
        totalBorrowed.isZero()
          ? new BigNumber(0)
          : totalSum.div(totalBorrowed).times(100)
      );
    }
  }, [userMarketInfo, withFTS]);

  useEffect(() => {
    if (
      account &&
      userMarketInfo &&
      userMarketInfo.length > 0 &&
      userMarketInfo.filter(s => !s).length === 0
    ) {
      updateNetAPY();
    }
    return function cleanup() {
      abortController.abort();
    };
  }, [account, updateNetAPY]);

  useEffect(() => {
    setSetting({
      withFTS
    });
  }, [withFTS]);

  const formatValue = value => {
    return `$${format(
      getBigNumber(value)
        .dp(2, 1)
        .toString(10)
    )}`;
  };

  return (
    <Card>
      <CardWrapper className="flex just-between align-center">
        <BalancerWrapper>
          <p className="label green">Supply Balance</p>
          <p className="value">
            <AnimatedNumber
              value={userTotalSupplyBalance.dp(2, 1).toString(10)}
              formatValue={formatValue}
              duration={2000}
            />
          </p>
        </BalancerWrapper>
        <CircleWrapper>
          <CircleProgressBar
            percent={netAPY}
            width={100}
            label="Net APY"
            borrow={userTotalBorrowBalance.dp(2, 1).toNumber()}
            supply={userTotalSupplyBalance.dp(2, 1).toNumber()}
          />
          <div className="apy-toggle">
            <Toggle checked={withFTS} onChecked={() => setWithFTS(!withFTS)} />
            <Label size="14" primary className="toggel-label">
              {withFTS ? (
                '🔥 APY with FTS'
              ) : (
                <div>
                  <span className="emoji">🔥</span>APY without FTS
                </div>
              )}
            </Label>
          </div>
        </CircleWrapper>
        <BalancerWrapper className="balancer">
          <p className="label red">Borrow Balance</p>
          <p className="value">
            <AnimatedNumber
              value={userTotalBorrowBalance.dp(2, 1).toString(10)}
              formatValue={formatValue}
              duration={2000}
            />
          </p>
        </BalancerWrapper>
      </CardWrapper>
    </Card>
  );
}

WalletBalance.propTypes = {
  setSetting: PropTypes.func.isRequired
};

const mapDispatchToProps = dispatch => {
  const { setSetting } = accountActionCreators;

  return bindActionCreators(
    {
      setSetting
    },
    dispatch
  );
};

export default compose(connectAccount(undefined, mapDispatchToProps))(
  WalletBalance
);
