import React, { useState, useEffect } from 'react';
import BigNumber from 'bignumber.js';
import { useWeb3React } from '@web3-react/core';
import useRefresh from '../hooks/useRefresh';
import { fetchMarkets } from '../utilities/api';
import useWeb3 from '../hooks/useWeb3';
import {
  getTokenContract,
  getFbepContract
} from '../utilities/contractHelpers';
import { useComptroller, useFaiToken } from '../hooks/useContract';
import * as constants from '../utilities/constants';
import { getFaiUnitrollerAddress } from '../utilities/addressHelpers';

const MarketContext = React.createContext({
  markets: [],
  ftsDaily: 0,
  userMarketInfo: {},
  userFaiMinted: new BigNumber(0),
  userFaiEnabled: false,
  userTotalBorrowLimit: new BigNumber(0),
  userTotalSupplyBalance: new BigNumber(0),
  userTotalBorrowBalance: new BigNumber(0),
  userFtsBalance: new BigNumber(0),
  userFaiBalance: new BigNumber(0)
});

// This context provide a way for all the components to share the market data, thus avoid
// duplicated requests
const MarketContextProvider = ({ children }) => {
  const [markets, setMarkets] = useState([]);
  const [ftsDaily, setFtsDaily] = useState(0);
  const [userMarketInfo, setUserMarketInfo] = useState({});
  const [userTotalBorrowLimit, setUserTotalBorrowLimit] = useState(
    new BigNumber(0)
  );
  const [userTotalBorrowBalance, setUserTotalBorrowBalance] = useState(
    new BigNumber(0)
  );
  const [userTotalSupplyBalance, setUserTotalSupplyBalance] = useState(
    new BigNumber(0)
  );
  const [userFtsBalance, setUserFtsBalance] = useState(new BigNumber(0));
  const [userFaiBalance, setUserFaiBalance] = useState(new BigNumber(0));
  const [userFaiMinted, setUserFaiMinted] = useState(new BigNumber(0));

  const [userFaiEnabled, setUserFaiEnabled] = useState(false);
  const comptrollerContract = useComptroller();
  const faiTokenContract = useFaiToken();
  const { account } = useWeb3React();
  const web3 = useWeb3();

  const { fastRefresh } = useRefresh();

  useEffect(() => {
    let isMounted = true;
    const getMarkets = async () => {
      const res = await fetchMarkets();
      if (!res.data || !res.data.success) {
        return;
      }

      const data = Object.keys(constants.CONTRACT_FBEP_ADDRESS)
        .map(item =>
          res.data.data.markets.find(
            market =>
              market.underlyingSymbol.toLowerCase() === item.toLowerCase()
          )
        )
        .filter(item => !!item);

      if (!isMounted) {
        return;
      }

      setMarkets(data);
      setFtsDaily(res.data.data.ftsDaily);
    };
    getMarkets();
    return () => {
      isMounted = false;
    };
  }, [fastRefresh]);

  useEffect(() => {
    let isMounted = true;
    const updateMarketUserInfo = async () => {
      if (!markets || markets.length === 0 || !account) {
        return;
      }

      try {
        let ftsBalance = new BigNumber(0);
        const [
          assetsIn,
          faiMinted,
          faiBalance,
          faiAllowance
        ] = await Promise.all([
          comptrollerContract.methods.getAssetsIn(account).call(),
          comptrollerContract.methods.mintedFAIs(account).call(),
          faiTokenContract.methods.balanceOf(account).call(),
          faiTokenContract.methods
            .allowance(account, getFaiUnitrollerAddress())
            .call()
        ]);
        let totalBorrowLimit = new BigNumber(0);
        let totalBorrowBalance = new BigNumber(faiMinted).div(1e18);
        let totalSupplyBalance = new BigNumber(0);
        const assetList = await Promise.all(
          Object.values(constants.CONTRACT_TOKEN_ADDRESS).map(
            async (item, index) => {
              let market = markets.find(
                ele =>
                  ele.underlyingSymbol.toLowerCase() ===
                  item.symbol.toLowerCase()
              );
              if (!market) market = {};
              const asset = {
                key: index,
                id: item.id,
                img: item.asset,
                name: market.underlyingSymbol || '',
                symbol: market.underlyingSymbol || '',
                decimals: item.decimals,
                tokenAddress: market.underlyingAddress,
                fsymbol: market.symbol,
                ftokenAddress: constants.CONTRACT_FBEP_ADDRESS[item.id].address,
                supplyApy: new BigNumber(market.supplyApy || 0),
                borrowApy: new BigNumber(market.borrowApy || 0),
                ftsSupplyApy: new BigNumber(market.ftsSupplyApy || 0),
                ftsBorrowApy: new BigNumber(market.ftsBorrowApy || 0),
                collateralFactor: new BigNumber(
                  market.collateralFactor || 0
                ).div(1e18),
                underlyingPriceUSD: new BigNumber(
                  market.underlyingPriceUSD || 0
                ),
                liquidity: new BigNumber(market.liquidity || 0),
                borrowCaps: new BigNumber(market.borrowCaps || 0),
                totalBorrows: new BigNumber(market.totalFBorrows || 0),
                walletBalance: new BigNumber(0),
                supplyBalance: new BigNumber(0),
                borrowBalance: new BigNumber(0),
                isEnabled: false,
                collateral: false,
                percentOfLimit: '0'
              };
              const fBepContract = getFbepContract(web3, item.id);
              asset.collateral = assetsIn
                .map(item => item.toLowerCase())
                .includes(asset.ftokenAddress.toLowerCase());

              let borrowBalance;
              let supplyBalance;
              let totalBalance;

              // wallet balance
              if (item.id !== 'bnb') {
                const tokenContract = getTokenContract(web3, item.id);
                let [
                  walletBalance,
                  allowBalance,
                  supplyAmount,
                  borrowAmount,
                  balance
                ] = await Promise.all([
                  tokenContract.methods.balanceOf(account).call(),
                  tokenContract.methods
                    .allowance(account, asset.ftokenAddress)
                    .call(),
                  fBepContract.methods.balanceOfUnderlying(account).call(),
                  fBepContract.methods.borrowBalanceCurrent(account).call(),
                  fBepContract.methods.balanceOf(account).call()
                ]);
                supplyBalance = supplyAmount;
                borrowBalance = borrowAmount;
                totalBalance = balance;

                asset.walletBalance = new BigNumber(walletBalance).div(
                  new BigNumber(10).pow(item.decimals)
                );

                if (asset.id === 'fts') {
                  ftsBalance = asset.walletBalance;
                }

                // allowance
                asset.isEnabled = new BigNumber(allowBalance)
                  .div(new BigNumber(10).pow(item.decimals))
                  .isGreaterThan(asset.walletBalance);
              } else {
                let [
                  supplyAmount,
                  borrowAmount,
                  balance,
                  walletBalance
                ] = await Promise.all([
                  fBepContract.methods.balanceOfUnderlying(account).call(),
                  fBepContract.methods.borrowBalanceCurrent(account).call(),
                  fBepContract.methods.balanceOf(account).call(),
                  web3.eth.getBalance(account)
                ]);
                supplyBalance = supplyAmount;
                borrowBalance = borrowAmount;
                totalBalance = balance;
                asset.isEnabled = true;
                asset.walletBalance = new BigNumber(walletBalance).div(
                  new BigNumber(10).pow(item.decimals)
                );
              }

              // supply balance
              asset.supplyBalance = new BigNumber(supplyBalance).div(
                new BigNumber(10).pow(item.decimals)
              );

              // borrow balance
              asset.borrowBalance = new BigNumber(borrowBalance).div(
                new BigNumber(10).pow(item.decimals)
              );

              // hypotheticalLiquidity
              // return data type: (uint(err), liquidity, shortfall);
              asset.hypotheticalLiquidity = account
                ? await comptrollerContract.methods
                    .getHypotheticalAccountLiquidity(
                      account,
                      asset.ftokenAddress,
                      totalBalance,
                      0
                    )
                    .call()
                : ['0', '0', '0'];

              const supplyBalanceUSD = asset.supplyBalance.times(
                asset.underlyingPriceUSD
              );
              const borrowBalanceUSD = asset.borrowBalance.times(
                asset.underlyingPriceUSD
              );
              totalSupplyBalance = totalSupplyBalance.plus(supplyBalanceUSD);
              totalBorrowBalance = totalBorrowBalance.plus(borrowBalanceUSD);
              if (asset.collateral) {
                totalBorrowLimit = totalBorrowLimit.plus(
                  supplyBalanceUSD.times(asset.collateralFactor)
                );
              }

              return asset;
            }
          )
        );

        // percent of limit
        const tempAssetList = assetList.map(item => {
          return {
            ...item,
            percentOfLimit: new BigNumber(totalBorrowLimit).isZero()
              ? '0'
              : item.borrowBalance
                  .times(item.underlyingPriceUSD)
                  .div(totalBorrowLimit)
                  .times(100)
                  .dp(0, 1)
                  .toString(10)
          };
        });

        if (!isMounted) {
          return;
        }

        setUserMarketInfo(tempAssetList);
        setUserTotalBorrowLimit(totalBorrowLimit);
        setUserTotalSupplyBalance(totalSupplyBalance);
        setUserTotalBorrowBalance(totalBorrowBalance);
        setUserFtsBalance(ftsBalance);
        setUserFaiMinted(new BigNumber(faiMinted).div(1e18));
        setUserFaiBalance(new BigNumber(faiBalance).div(1e18));
        setUserFaiEnabled(new BigNumber(faiAllowance).gt(0));
      } catch (error) {
        console.log('error :>> ', error);
      }
    };
    updateMarketUserInfo();
    return () => {
      isMounted = false;
    };
  }, [markets, account, web3, fastRefresh]);

  return (
    <MarketContext.Provider
      value={{
        markets,
        ftsDaily,
        userFaiMinted,
        userMarketInfo,
        userTotalBorrowLimit,
        userTotalSupplyBalance,
        userTotalBorrowBalance,
        userFtsBalance,
        userFaiBalance,
        userFaiEnabled
      }}
    >
      {children}
    </MarketContext.Provider>
  );
};

export { MarketContext, MarketContextProvider };
