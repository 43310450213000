/* eslint-disable no-useless-escape */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import BigNumber from 'bignumber.js';
import * as constants from 'utilities/constants';
import MainLayout from 'containers/Layout/MainLayout';
import EarnedInfo from 'components/FtsVault/EarnedInfo';
import UserInfo from 'components/FtsVault/UserInfo';
import Staking from 'components/FtsVault/Staking';
import { connectAccount, accountActionCreators } from 'core';
import { methods } from 'utilities/ContractService';
import LoadingSpinner from 'components/Basic/LoadingSpinner';
import { Row, Column } from 'components/Basic/Style';
import { FTS_VAULT_REWARD } from '../../config';
import { useFbep, useRewardPool } from '../../hooks/useContract';
import { useMarkets } from '../../hooks/useMarkets';
import { useWeb3React } from '@web3-react/core';
import { useBlock } from '../../hooks/useBlock';
import { getRewardPoolAddress } from '../../utilities/addressHelpers';

const MarketWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const VaultWrapper = styled.div`
  padding-top: 30px;
  height: 100%;
  position: relative;
  width: 100%;
  max-width: 1200px;
`;

const SpinnerWrapper = styled.div`
  height: 80vh;
  width: 100%;

  @media only screen and (max-width: 1440px) {
    height: 70vh;
  }
`;

const Note = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
  font-size: 14px;
  color: #f7c408;
  @media only screen and (max-width: 1440px) {
    padding: 0 20px;
    margin-top: 0;
    margin-bottom: 15px;
  }
`;

function Vault({ settings }) {
  const [pendingRewards, setPendingRewards] = useState({});
  const [stakedAmount, setStakedAmount] = useState(new BigNumber(0));
  const [totalAmount, setTotalAmount] = useState(new BigNumber(0));
  const [apr, setApr] = useState('0');
  const [fFtsBalance, setFFtsBalance] = useState(new BigNumber(0));
  const [isEnabled, setIsEnabled] = useState('0');
  const [isStakeLoading, setIsStakeLoading] = useState(false);
  const [isWithdrawLoading, setIsWithdrawLoading] = useState(false);
  const [isClaimLoading, setIsClaimLoading] = useState(false);
  const [canWithdraw, setCanWithdraw] = useState(false);
  const [endBlock, setEndBlock] = useState(undefined);
  const [isClaimAvailable, setIsClaimAvailable] = useState(false);
  const [availableWithdrawTime, setAvailableWithdrawTime] = useState(0);
  const fFTSContract = useFbep('fts');
  const rewardPoolContract = useRewardPool();
  const currentBlockNum = useBlock();
  const { markets } = useMarkets();
  const { account } = useWeb3React();

  const updateTotalInfo = async () => {
    const [
      btcReward,
      bnbReward,
      busdReward,
      balance,
      _totalSupply,
      _canWithdraw,
      startingBlock,
      DURATION,
      stakedTime,
      delayToWithdraw
    ] = await Promise.all([
      methods.call(rewardPoolContract.methods.earned, [account]),
      methods.call(rewardPoolContract.methods.earned2, [account]),
      methods.call(rewardPoolContract.methods.earned3, [account]),
      methods.call(rewardPoolContract.methods.balanceOf, [account]),
      methods.call(rewardPoolContract.methods.totalSupply, []),
      methods.call(rewardPoolContract.methods.canWithdraw, [account]),
      methods.call(rewardPoolContract.methods.startingBlock, []),
      methods.call(rewardPoolContract.methods.DURATION, []),
      methods.call(rewardPoolContract.methods._stakedTime, [account]),
      methods.call(rewardPoolContract.methods.delayToWithdraw, [])
    ]);

    setPendingRewards({
      btc: new BigNumber(btcReward)
        .div(1e18)
        .dp(8, 1)
        .toString(10),
      bnb: new BigNumber(bnbReward)
        .div(1e18)
        .dp(8, 1)
        .toString(10),
      busd: new BigNumber(busdReward)
        .div(1e18)
        .dp(8, 1)
        .toString(10)
    });
    if (
      new BigNumber(btcReward).gt(0) ||
      new BigNumber(bnbReward).gt(0) ||
      new BigNumber(busdReward).gt(0)
    ) {
      setIsClaimAvailable(true);
    } else {
      setIsClaimAvailable(false);
    }
    setStakedAmount(new BigNumber(balance));
    setTotalAmount(new BigNumber(_totalSupply));
    setCanWithdraw(_canWithdraw);
    setAvailableWithdrawTime(
      parseInt(stakedTime, 10) + parseInt(delayToWithdraw, 10)
    );

    const [allowBalance, _fFtsBalance] = await Promise.all([
      methods.call(fFTSContract.methods.allowance, [
        account,
        getRewardPoolAddress()
      ]),
      methods.call(fFTSContract.methods.balanceOf, [account])
    ]);
    setFFtsBalance(new BigNumber(_fFtsBalance));
    if (new BigNumber(allowBalance).lt(new BigNumber(10000000001e8))) {
      setIsEnabled(false);
    } else {
      setIsEnabled(true);
    }

    const endBlockNum =
      parseInt(startingBlock, 10) + parseInt(DURATION / 3, 10);
    setEndBlock(endBlockNum);

    if (currentBlockNum > endBlockNum) {
      setApr(0);
    } else {
      const info = markets.find(
        item => item.underlyingSymbol.toLowerCase() === 'fts'
      );
      const totalReward = FTS_VAULT_REWARD;
      const totalSupply = new BigNumber(_totalSupply).div(1e8).toNumber();
      setApr(
        ((((totalReward * 12) / totalSupply) * 50) / info.underlyingPriceUSD) *
          100
      );
    }
  };

  useEffect(() => {
    updateTotalInfo();
  }, [markets]);

  const handleStakefFts = amount => {
    setIsStakeLoading(true);
    methods
      .send(rewardPoolContract.methods.stake, [amount], account)
      .then(() => {
        updateTotalInfo();
        setIsStakeLoading(false);
      })
      .catch(err => {
        console.log(err);
        setIsStakeLoading(false);
      });
  };
  const handleWithdraw = amount => {
    setIsWithdrawLoading(true);
    methods
      .send(rewardPoolContract.methods.withdraw, [amount], account)
      .then(() => {
        updateTotalInfo();
        setIsWithdrawLoading(false);
      })
      .catch(err => {
        console.log(err);
        setIsWithdrawLoading(false);
      });
  };
  const handleApprove = () => {
    setIsStakeLoading(true);
    methods
      .send(
        fFTSContract.methods.approve,
        [
          getRewardPoolAddress(),
          new BigNumber(100000000000000000001e8).toString(10)
        ],
        account
      )
      .then(() => {
        updateTotalInfo();
        setIsStakeLoading(false);
      })
      .catch(err => {
        console.log(err);
        setIsStakeLoading(false);
      });
  };
  const handleClaim = () => {
    setIsClaimLoading(true);
    methods
      .send(rewardPoolContract.methods.getReward, [], account)
      .then(() => {
        updateTotalInfo();
        setIsClaimLoading(false);
      })
      .catch(err => {
        console.log(err);
        setIsClaimLoading(false);
      });
  };

  return (
    <MainLayout title="fFTS Vault">
      <MarketWrapper>
        <VaultWrapper className="flex">
          {!account ? (
            <SpinnerWrapper>
              <LoadingSpinner />
            </SpinnerWrapper>
          ) : (
            <Row>
              <Column xs="12">
                <EarnedInfo pendingRewards={pendingRewards} />
              </Column>
              <Column xs="12">
                <Row>
                  <Column xs="12" sm="12" md="5">
                    <UserInfo
                      stakedAmount={stakedAmount}
                      apr={apr}
                      totalAmount={totalAmount}
                      endBlock={endBlock}
                    />
                  </Column>
                  <Column xs="12" sm="12" md="7">
                    <Staking
                      isEnabled={isEnabled}
                      fFtsBalance={fFtsBalance}
                      stakedAmount={stakedAmount}
                      isStakeLoading={isStakeLoading}
                      isWithdrawLoading={isWithdrawLoading}
                      handleStakefFts={handleStakefFts}
                      handleWithdraw={handleWithdraw}
                      handleApprove={handleApprove}
                      canWithdraw={canWithdraw}
                      isClaimLoading={isClaimLoading}
                      isClaimAvailable={isClaimAvailable}
                      handleClaim={handleClaim}
                      availableWithdrawTime={availableWithdrawTime}
                    />
                  </Column>
                </Row>
              </Column>
              <Column xs="12">
                <Note>
                  There is a 7 day minimum waiting period to withdraw your fFTS
                  from the triple staking pool. Each subsequent deposit will
                  reset the 7 day waiting period.
                </Note>
              </Column>
            </Row>
          )}
        </VaultWrapper>
      </MarketWrapper>
    </MainLayout>
  );
}

Vault.propTypes = {
  settings: PropTypes.object
};

Vault.defaultProps = {
  settings: {}
};

const mapStateToProps = ({ account }) => ({
  settings: account.setting
});

const mapDispatchToProps = dispatch => {
  const { setSetting } = accountActionCreators;

  return bindActionCreators(
    {
      setSetting
    },
    dispatch
  );
};

export default compose(
  withRouter,
  connectAccount(mapStateToProps, mapDispatchToProps)
)(Vault);
