import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import PulseLoader from 'react-spinners/PulseLoader';

const LoadingSpinnerWrapper = styled.div`
  width: 100%;
  height: 100%;

  .loading-spinner {
    color: var(--color-yellow);
  }
`;

function LoadingSpinner({ size, color }) {
  return (
    <LoadingSpinnerWrapper className="flex align-center just-center">
      <PulseLoader size={size} color={color} loading />
    </LoadingSpinnerWrapper>
  );
}

LoadingSpinner.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string
};

LoadingSpinner.defaultProps = {
  size: 15,
  color: '#F7C408'
};

export default LoadingSpinner;
