import { useContext } from 'react';
import { MarketContext } from '../context/MarketContext';

export const useMarketsUser = () => {
  const {
    userMarketInfo,
    userTotalBorrowLimit,
    userTotalSupplyBalance,
    userTotalBorrowBalance,
    userFtsBalance,
    userFaiBalance,
    userFaiMinted,
    userFaiEnabled
  } = useContext(MarketContext);
  return {
    userMarketInfo,
    userTotalBorrowLimit,
    userTotalSupplyBalance,
    userTotalBorrowBalance,
    userFtsBalance,
    userFaiBalance,
    userFaiMinted,
    userFaiEnabled
  };
};
