/* eslint-disable no-useless-escape */
import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import BigNumber from 'bignumber.js';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connectAccount, accountActionCreators } from 'core';
import { methods } from 'utilities/ContractService';
import MainLayout from 'containers/Layout/MainLayout';
import CoinInfo from 'components/Vote/CoinInfo';
import VotingWallet from 'components/Vote/VotingWallet';
import VotingPower from 'components/Vote/VotingPower';
import Proposals from 'components/Vote/Proposals';
import { promisify } from 'utilities';
import LoadingSpinner from 'components/Basic/LoadingSpinner';
import {
  useComptroller,
  useFaiUnitroller,
  useToken
} from '../../hooks/useContract';
import useRefresh from '../../hooks/useRefresh';
import { useWeb3React } from '@web3-react/core';

const VoteWrapper = styled.div`
  height: 100%;
`;

const VoteBody = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const SpinnerWrapper = styled.div`
  height: 85vh;
  width: 100%;

  @media only screen and (max-width: 1440px) {
    height: 70vh;
  }
`;

const CardRow = styled.div`
  display: flex;
  flex-direction: row;
  margin: 15px 0;

  @media only screen and (max-width: 480px) {
    font-size: 14px;
    flex-direction: column;
  }
`;

const LeftCard = styled.div`
  flex: 2;
  height: 100%;

  @media only screen and (max-width: 480px) {
    margin-bottom: 15px;
  }
`;

const RightCard = styled.div`
  flex: 3;
  height: 100%;

  @media only screen and (max-width: 480px) {
    margin-top: 15px;
  }
`;

let timeStamp = 0;

function Vote({ settings, history, getProposals, setSetting }) {
  const [balance, setBalance] = useState(0);
  const [votingWeight, setVotingWeight] = useState(0);
  const [proposals, setProposals] = useState({});
  const [current, setCurrent] = useState(1);
  const [isLoadingProposal, setIsLoadingPropoasl] = useState(false);
  const [earnedBalance, setEarnedBalance] = useState('0.00000000');
  const [faiMint, setFaiMint] = useState('0.00000000');
  const [delegateAddress, setDelegateAddress] = useState('');
  const [delegateStatus, setDelegateStatus] = useState('');
  const compContract = useComptroller();
  const ftsTokenContract = useToken('fts');
  const faiUnitrollerContract = useFaiUnitroller();
  const { fastRefresh } = useRefresh();
  const { account } = useWeb3React();

  const loadInitialData = useCallback(async () => {
    setIsLoadingPropoasl(true);
    await promisify(getProposals, {
      offset: 0,
      limit: 5
    })
      .then(res => {
        setIsLoadingPropoasl(false);
        setProposals(res.data);
      })
      .catch(() => {
        setIsLoadingPropoasl(false);
      });
  }, [getProposals]);

  useEffect(() => {
    loadInitialData();
  }, [loadInitialData]);

  const handleChangePage = (pageNumber, offset, limit) => {
    setCurrent(pageNumber);
    setIsLoadingPropoasl(true);
    promisify(getProposals, {
      offset,
      limit
    })
      .then(res => {
        setProposals(res.data);
        setIsLoadingPropoasl(false);
      })
      .catch(() => {
        setIsLoadingPropoasl(false);
      });
  };

  const updateBalance = async () => {
    if (account) {
      await methods
        .call(ftsTokenContract.methods.getCurrentVotes, [account])
        .then(res => {
          const weight = new BigNumber(res)
            .div(new BigNumber(10).pow(18))
            .toString(10);
          setVotingWeight(weight);
        });
      let temp = await methods.call(ftsTokenContract.methods.balanceOf, [
        account
      ]);
      temp = new BigNumber(temp)
        .dividedBy(new BigNumber(10).pow(18))
        .dp(4, 1)
        .toString(10);
      setBalance(temp);
    }
  };

  const getVoteInfo = async () => {
    const myAddress = account;
    if (!myAddress) return;
    const fortressInitialIndex = await methods.call(
      compContract.methods.fortressInitialIndex,
      []
    );
    const fortressFAIState = await methods.call(
      faiUnitrollerContract.methods.fortressFAIState,
      []
    );
    const faiMintIndex = fortressFAIState.index;
    let faiMinterIndex = await methods.call(
      faiUnitrollerContract.methods.fortressFAIMinterIndex,
      [myAddress]
    );
    if (+faiMinterIndex === 0 && +faiMintIndex > 0) {
      faiMinterIndex = fortressInitialIndex;
    }
    const deltaIndex = new BigNumber(faiMintIndex).minus(
      new BigNumber(faiMinterIndex)
    );
    const faiMinterAmount = await methods.call(
      compContract.methods.mintedFAIs,
      [myAddress]
    );
    const faiMinterDelta = new BigNumber(faiMinterAmount)
      .times(deltaIndex)
      .div(1e54)
      .dp(8, 1)
      .toString(10);
    setFaiMint(
      faiMinterDelta && faiMinterDelta !== '0'
        ? `${faiMinterDelta}`
        : '0.00000000'
    );
  };

  const updateDelegate = async () => {
    if (account && timeStamp % 3 === 0) {
      methods
        .call(ftsTokenContract.methods.delegates, [account])
        .then(res => {
          setDelegateAddress(res);
          if (res !== '0x0000000000000000000000000000000000000000') {
            setDelegateStatus(res === account ? 'self' : 'delegate');
          } else {
            setDelegateStatus('');
          }
        })
        .catch(() => {});
    }
    timeStamp = Date.now();
  };

  useEffect(() => {
    getVoteInfo();
    updateBalance();
    updateDelegate();
  }, [fastRefresh, account]);

  return (
    <MainLayout title="Vote">
      <VoteWrapper className="flex">
        {!account && (
          <SpinnerWrapper>
            <LoadingSpinner />
          </SpinnerWrapper>
        )}
        {account && (
          <VoteBody>
            <CardRow>
              <LeftCard>
                <CoinInfo
                  balance={balance !== '0' ? `${balance}` : '0.00000000'}
                  address={account ? account : ''}
                />
              </LeftCard>
              <RightCard>
                <VotingPower
                  power={
                    votingWeight !== '0'
                      ? `${new BigNumber(votingWeight).dp(8, 1).toString(10)}`
                      : '0.00000000'
                  }
                />
              </RightCard>
            </CardRow>
            <CardRow>
              <LeftCard>
                <VotingWallet
                  balance={balance !== '0' ? `${balance}` : '0.00000000'}
                  delegateAddress={delegateAddress}
                  delegateStatus={delegateStatus}
                />
              </LeftCard>
              <RightCard>
                <Proposals
                  address={account ? account : ''}
                  isLoadingProposal={isLoadingProposal}
                  pageNumber={current}
                  proposals={proposals.result}
                  total={proposals.total || 0}
                  votingWeight={votingWeight}
                  onChangePage={handleChangePage}
                />
              </RightCard>
            </CardRow>
          </VoteBody>
        )}
      </VoteWrapper>
    </MainLayout>
  );
}

Vote.propTypes = {
  history: PropTypes.object,
  settings: PropTypes.object,
  getProposals: PropTypes.func.isRequired,
  setSetting: PropTypes.func.isRequired
};

Vote.defaultProps = {
  history: {},
  settings: {}
};

const mapStateToProps = ({ account }) => ({
  settings: account.setting
});

const mapDispatchToProps = dispatch => {
  const { getProposals, setSetting } = accountActionCreators;

  return bindActionCreators(
    {
      getProposals,
      setSetting
    },
    dispatch
  );
};

export default compose(
  withRouter,
  connectAccount(mapStateToProps, mapDispatchToProps)
)(Vote);
