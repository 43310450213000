import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import commaNumber from 'comma-number';
import { methods } from 'utilities/ContractService';
import { Card } from 'components/Basic/Card';
import faiImg from 'assets/img/coins/fai.svg';
import ftsImg from 'assets/img/fts.svg';
import LoadingSpinner from 'components/Basic/LoadingSpinner';
import { useFaiVault } from '../../hooks/useContract';
import { useMarketsUser } from '../../hooks/useMarketsUser';
import { useWeb3React } from '@web3-react/core';

const UserInfoWrapper = styled.div`
  width: 100%;
  height: 520px;
  border-radius: 5px;
  background-color: var(--color-bg-primary);
  display: flex;
  flex-direction: column;
  padding: 20px 40px;

  @media (max-width: 992px) {
    padding: 20px;
    height: 100%;
  }

  .total-item {
    margin: 20px 0;
    @media (max-width: 992px) {
      margin: 10px;
    }

    .prop {
      font-weight: 400;
      font-size: 20px;
      color: var(--color-text-secondary);
    }

    .value {
      font-weight: 400;
      font-size: 24px;
      color: var(--color-white);
      margin-top: 10px;

      img {
        width: 24px;
        margin-right: 10px;
      }

      .claim-btn {
        font-size: 18px;
        font-weight: bold;
        color: var(--color-orange);
        margin-left: 10px;
      }
      .disable-btn {
        color: var(--color-text-secondary);
      }
    }
  }
`;

const format = commaNumber.bindWith(',', '.');

function UserInfo({ availableFai, faiStaked, faiReward }) {
  const [isLoading, setIsLoading] = useState(false);
  const faiVaultContract = useFaiVault();
  const { userFtsBalance } = useMarketsUser();
  const { account } = useWeb3React();

  const handleClaimReward = () => {
    if (isLoading || faiReward === '0') return;
    setIsLoading(true);
    methods
      .send(faiVaultContract.methods.claim, [], account)
      .then(res => {
        setIsLoading(false);
      })
      .catch(err => {
        setIsLoading(false);
      });
  };

  return (
    <Card>
      <UserInfoWrapper>
        <div className="total-item">
          <div className="prop">Available FAI to stake</div>
          <div className="flex align-center value">
            <img src={faiImg} alt="fai" />
            {format(availableFai.dp(4, 1).toString(10))} FAI
          </div>
        </div>
        <div className="total-item">
          <div className="prop">FAI Staked</div>
          <div className="flex align-center value">
            <img src={faiImg} alt="fai" />
            {format(faiStaked.dp(4, 1).toString(10))} FAI
          </div>
        </div>
        <div className="total-item">
          <div className="prop">Available FAI rewards</div>
          <div className="flex align-center just-between value">
            <p>
              <img src={ftsImg} alt="fts" />
              {format(faiReward)} FTS
            </p>
            <p
              className={`pointer claim-btn ${
                isLoading || faiReward === '0' ? 'disable-btn' : ''
              }`}
              onClick={handleClaimReward}
            >
              {isLoading ? (
                <LoadingSpinner size={5} color="#C4C4C4" />
              ) : (
                'Claim'
              )}
            </p>
          </div>
        </div>
        <div className="total-item">
          <div className="prop">Fortress Balance</div>
          <div className="flex align-center just-between value">
            <p>
              <img src={ftsImg} alt="fts" />
              {format(userFtsBalance.toFormat(2))} FTS
            </p>
          </div>
        </div>
      </UserInfoWrapper>
    </Card>
  );
}

UserInfo.propTypes = {
  availableFai: PropTypes.object.isRequired,
  faiStaked: PropTypes.object.isRequired,
  faiReward: PropTypes.string.isRequired
};

export default UserInfo;
