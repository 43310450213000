import usdc from 'assets/img/coins/usdc.png';
import usdt from 'assets/img/coins/usdt.png';
import busd from 'assets/img/coins/busd.png';
import bnb from 'assets/img/coins/bnb.png';
import btcb from 'assets/img/coins/btcb.png';
import eth from 'assets/img/coins/eth.png';
import ltc from 'assets/img/coins/ltc.png';
import xrp from 'assets/img/coins/xrp.png';
import ada from 'assets/img/coins/ada.png';
import dai from 'assets/img/coins/dai.png';
import beth from 'assets/img/coins/beth.png';
import dot from 'assets/img/coins/dot.png';
import fts from 'assets/img/fts.svg';
import cakeLpFtsBnb from 'assets/img/coins/cake-lp-fts-bnb-v2.png';
import shib from 'assets/img/coins/shib.png';

import fusdc from 'assets/img/coins/fusdc.svg';
import fusdt from 'assets/img/coins/fusdt.svg';
import fbusd from 'assets/img/coins/fbusd.svg';
import fbnb from 'assets/img/coins/fbnb.svg';
import fbtc from 'assets/img/coins/fbtc.svg';
import feth from 'assets/img/coins/feth.svg';
import fltc from 'assets/img/coins/fltc.svg';
import fxrp from 'assets/img/coins/fxrp.svg';
import fada from 'assets/img/coins/fada.svg';
import fdai from 'assets/img/coins/fdai.svg';
import fbeth from 'assets/img/coins/fbeth.svg';
import fdot from 'assets/img/coins/fdot.svg';
import ffts from 'assets/img/coins/ffts.svg';
import fCakeLpFtsBnb from 'assets/img/coins/fcake-lp-fts-bnb-v2.svg';
import fshib from 'assets/img/coins/fshib.png';

import contracts from './contracts';

export const CONTRACT_FTS_TOKEN_ADDRESS =
  process.env.REACT_APP_CHAIN_ID === '97'
    ? contracts.TEST.TOKEN.fts
    : contracts.MAIN.TOKEN.fts;

// Contract ABI
export const CONTRACT_TOKEN_ADDRESS =
  process.env.REACT_APP_CHAIN_ID === '97'
    ? {
        fts: {
          id: 'fts',
          symbol: 'FTS',
          decimals: 18,
          address: contracts.TEST.TOKEN.fts,
          asset: fts,
          fasset: ffts
        },
        usdc: {
          id: 'usdc',
          symbol: 'USDC',
          decimals: 18,
          address: contracts.TEST.TOKEN.usdc,
          asset: usdc,
          fasset: fusdc
        },
        usdt: {
          id: 'usdt',
          symbol: 'USDT',
          decimals: 18,
          address: contracts.TEST.TOKEN.usdt,
          asset: usdt,
          fasset: fusdt
        },
        busd: {
          id: 'busd',
          symbol: 'BUSD',
          decimals: 18,
          address: contracts.TEST.TOKEN.busd,
          asset: busd,
          fasset: fbusd
        },
        bnb: {
          id: 'bnb',
          symbol: 'BNB',
          decimals: 18,
          asset: bnb,
          fasset: fbnb
        },
        btcb: {
          id: 'btcb',
          symbol: 'BTCB',
          decimals: 18,
          address: contracts.TEST.TOKEN.btcb,
          asset: btcb,
          fasset: fbtc
        },
        eth: {
          id: 'eth',
          symbol: 'ETH',
          decimals: 18,
          address: contracts.TEST.TOKEN.eth,
          asset: eth,
          fasset: feth
        },
        ltc: {
          id: 'ltc',
          symbol: 'LTC',
          decimals: 18,
          address: contracts.TEST.TOKEN.ltc,
          asset: ltc,
          fasset: fltc
        },
        xrp: {
          id: 'xrp',
          symbol: 'XRP',
          decimals: 18,
          address: contracts.TEST.TOKEN.xrp,
          asset: xrp,
          fasset: fxrp
        },
        ada: {
          id: 'ada',
          symbol: 'ADA',
          decimals: 18,
          address: contracts.TEST.TOKEN.ada,
          asset: ada,
          fasset: fada
        },
        dai: {
          id: 'dai',
          symbol: 'DAI',
          decimals: 18,
          address: contracts.TEST.TOKEN.dai,
          asset: dai,
          fasset: fdai
        },
        beth: {
          id: 'beth',
          symbol: 'BETH',
          decimals: 18,
          address: contracts.TEST.TOKEN.beth,
          asset: beth,
          fasset: fbeth
        },
        dot: {
          id: 'dot',
          symbol: 'DOT',
          decimals: 18,
          address: contracts.TEST.TOKEN.dot,
          asset: dot,
          fasset: fdot
        },
        'cake-lp-fts-bnb-v2': {
          id: 'cake-lp-fts-bnb-v2',
          symbol: 'Cake-LP-FTS-BNB-v2',
          decimals: 18,
          address: contracts.TEST.TOKEN['cake-fts-bnb-lp'],
          asset: cakeLpFtsBnb,
          fasset: fCakeLpFtsBnb
        },
        shib: {
          id: 'shib',
          symbol: 'SHIB',
          decimals: 18,
          address: contracts.TEST.TOKEN.shib,
          asset: shib,
          fasset: fshib
        }
      }
    : {
        fts: {
          id: 'fts',
          symbol: 'FTS',
          decimals: 18,
          address: contracts.MAIN.TOKEN.fts,
          asset: fts,
          fasset: ffts
        },
        usdc: {
          id: 'usdc',
          symbol: 'USDC',
          decimals: 18,
          address: contracts.MAIN.TOKEN.usdc,
          asset: usdc,
          fasset: fusdc
        },
        usdt: {
          id: 'usdt',
          symbol: 'USDT',
          decimals: 18,
          address: contracts.MAIN.TOKEN.usdt,
          asset: usdt,
          fasset: fusdt
        },
        busd: {
          id: 'busd',
          symbol: 'BUSD',
          decimals: 18,
          address: contracts.MAIN.TOKEN.busd,
          asset: busd,
          fasset: fbusd
        },
        bnb: {
          id: 'bnb',
          symbol: 'BNB',
          decimals: 18,
          asset: bnb,
          fasset: fbnb
        },
        btcb: {
          id: 'btcb',
          symbol: 'BTCB',
          decimals: 18,
          address: contracts.MAIN.TOKEN.btcb,
          asset: btcb,
          fasset: fbtc
        },
        eth: {
          id: 'eth',
          symbol: 'ETH',
          decimals: 18,
          address: contracts.MAIN.TOKEN.eth,
          asset: eth,
          fasset: feth
        },
        ltc: {
          id: 'ltc',
          symbol: 'LTC',
          decimals: 18,
          address: contracts.MAIN.TOKEN.ltc,
          asset: ltc,
          fasset: fltc
        },
        xrp: {
          id: 'xrp',
          symbol: 'XRP',
          decimals: 18,
          address: contracts.MAIN.TOKEN.xrp,
          asset: xrp,
          fasset: fxrp
        },
        ada: {
          id: 'ada',
          symbol: 'ADA',
          decimals: 18,
          address: contracts.MAIN.TOKEN.ada,
          asset: ada,
          fasset: fada
        },
        dai: {
          id: 'dai',
          symbol: 'DAI',
          decimals: 18,
          address: contracts.MAIN.TOKEN.dai,
          asset: dai,
          fasset: fdai
        },
        beth: {
          id: 'beth',
          symbol: 'BETH',
          decimals: 18,
          address: contracts.MAIN.TOKEN.beth,
          asset: beth,
          fasset: fbeth
        },
        dot: {
          id: 'dot',
          symbol: 'DOT',
          decimals: 18,
          address: contracts.MAIN.TOKEN.dot,
          asset: dot,
          fasset: fdot
        },
        'cake-lp-fts-bnb-v2': {
          id: 'cake-lp-fts-bnb-v2',
          symbol: 'Cake-LP-FTS-BNB-v2',
          decimals: 18,
          address: contracts.MAIN.TOKEN['cake-fts-bnb-lp'],
          asset: cakeLpFtsBnb,
          fasset: fCakeLpFtsBnb
        },
        shib: {
          id: 'shib',
          symbol: 'SHIB',
          decimals: 18,
          address: contracts.MAIN.TOKEN.shib,
          asset: shib,
          fasset: fshib
        }
      };

export const CONTRACT_FBEP_ADDRESS =
  process.env.REACT_APP_CHAIN_ID === '97'
    ? {
        fts: {
          id: 'fts',
          symbol: 'fFTS',
          address: contracts.TEST.FBEP.fts
        },
        usdc: {
          id: 'usdc',
          symbol: 'fUSDC',
          address: contracts.TEST.FBEP.usdc
        },
        usdt: {
          id: 'usdt',
          symbol: 'fUSDT',
          address: contracts.TEST.FBEP.usdt
        },
        busd: {
          id: 'busd',
          symbol: 'fBUSD',
          address: contracts.TEST.FBEP.busd
        },
        bnb: {
          id: 'bnb',
          symbol: 'fBNB',
          address: contracts.TEST.FBEP.bnb
        },
        btcb: {
          id: 'btcb',
          symbol: 'fBTCB',
          address: contracts.TEST.FBEP.btcb
        },
        eth: {
          id: 'eth',
          symbol: 'fETH',
          address: contracts.TEST.FBEP.eth
        },
        ltc: {
          id: 'ltc',
          symbol: 'fLTC',
          address: contracts.TEST.FBEP.ltc
        },
        xrp: {
          id: 'xrp',
          symbol: 'fXRP',
          address: contracts.TEST.FBEP.xrp
        },
        ada: {
          id: 'ada',
          symbol: 'fADA',
          address: contracts.TEST.FBEP.ada
        },
        dai: {
          id: 'dai',
          symbol: 'fDAI',
          address: contracts.TEST.FBEP.dai
        },
        beth: {
          id: 'beth',
          symbol: 'fBETH',
          address: contracts.TEST.FBEP.beth
        },
        dot: {
          id: 'dot',
          symbol: 'fDOT',
          address: contracts.TEST.FBEP.dot
        },
        'cake-lp-fts-bnb-v2': {
          id: 'cake-lp-fts-bnb-v2',
          symbol: 'fCake-LP-FTS-BNB-v2',
          address: contracts.TEST.FBEP['cake-fts-bnb-lp']
        },
        shib: {
          id: 'shib',
          symbol: 'FSHIB',
          address: contracts.TEST.FBEP.shib,
        }
      }
    : {
        fts: {
          id: 'fts',
          symbol: 'fFTS',
          address: contracts.MAIN.FBEP.fts
        },
        usdc: {
          id: 'usdc',
          symbol: 'fUSDC',
          address: contracts.MAIN.FBEP.usdc
        },
        usdt: {
          id: 'usdt',
          symbol: 'fUSDT',
          address: contracts.MAIN.FBEP.usdt
        },
        busd: {
          id: 'busd',
          symbol: 'fBUSD',
          address: contracts.MAIN.FBEP.busd
        },
        bnb: {
          id: 'bnb',
          symbol: 'fBNB',
          address: contracts.MAIN.FBEP.bnb
        },
        btcb: {
          id: 'btcb',
          symbol: 'fBTCB',
          address: contracts.MAIN.FBEP.btcb
        },
        eth: {
          id: 'eth',
          symbol: 'fETH',
          address: contracts.MAIN.FBEP.eth
        },
        ltc: {
          id: 'ltc',
          symbol: 'fLTC',
          address: contracts.MAIN.FBEP.ltc
        },
        xrp: {
          id: 'xrp',
          symbol: 'fXRP',
          address: contracts.MAIN.FBEP.xrp
        },
        ada: {
          id: 'ada',
          symbol: 'fADA',
          address: contracts.MAIN.FBEP.ada
        },
        dai: {
          id: 'dai',
          symbol: 'fDAI',
          address: contracts.MAIN.FBEP.dai
        },
        beth: {
          id: 'beth',
          symbol: 'fBETH',
          address: contracts.MAIN.FBEP.beth
        },
        dot: {
          id: 'dot',
          symbol: 'fDOT',
          address: contracts.MAIN.FBEP.dot
        },
        'cake-lp-fts-bnb-v2': {
          id: 'cake-lp-fts-bnb-v2',
          symbol: 'fCake-LP-FTS-BNB-v2',
          address: contracts.MAIN.FBEP['cake-fts-bnb-lp']
        },
        shib: {
          id: 'shib',
          symbol: 'FSHIB',
          address: contracts.MAIN.FBEP.shib,
        }
      };
